// src/pages/groups.tsx
'use client'

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Play, Pause, SkipBack } from 'lucide-react';
import Papa from 'papaparse';

interface AssetGroup {
    name: string;
    value: number;
    cost: number;
    clicks: number;
    conversions: number;
    conversionValue: number;
    status: string;
    adStrength: string;
}

interface Campaign {
    name: string;
    value: number;
    cost: number;
    clicks: number;
    conversions: number;
    conversionValue: number;
    children: AssetGroup[];
}

interface DailyData {
    date: string;
    data: Campaign[];
}

interface RawDataRow {
    'asset_group.name': string;
    'asset_group.status': string;
    'asset_group.campaign': string;
    'asset_group.ad_strength': string;
    'segments.date': string;
    'metrics.impressions': string;
    'metrics.clicks': string;
    'metrics.cost_micros': string;
    'metrics.conversions': string;
    'metrics.conversions_value': string;
}

const GroupsPage = () => {
    const [processedData, setProcessedData] = useState<DailyData[]>([]);
    const [currentDay, setCurrentDay] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                const response = await fetch('/sample-data/sample_ag.csv');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const csvText = await response.text();
                console.log('CSV Text received:', csvText.substring(0, 200)); // Debug first 200 chars

                const parsed = Papa.parse<RawDataRow>(csvText, {
                    header: true,
                    skipEmptyLines: true,
                });

                if (parsed.data && parsed.data.length > 0) {
                    const groupedByDate = _.groupBy(parsed.data, 'segments.date');

                    const processed = Object.entries(groupedByDate).map(([date, dateData]) => {
                        const groupedByCampaign = _.groupBy(dateData, 'asset_group.campaign');

                        return {
                            date,
                            data: Object.entries(groupedByCampaign).map(([campaignId, campaignData]) => ({
                                name: `Campaign ${campaignId.split('/').pop()}`,
                                value: _.sumBy(campaignData, row => parseInt(row['metrics.impressions']) || 0),
                                cost: _.sumBy(campaignData, row => parseInt(row['metrics.cost_micros']) || 0) / 1000000,
                                clicks: _.sumBy(campaignData, row => parseInt(row['metrics.clicks']) || 0),
                                conversions: _.sumBy(campaignData, row => parseFloat(row['metrics.conversions']) || 0),
                                conversionValue: _.sumBy(campaignData, row => parseFloat(row['metrics.conversions_value']) || 0),
                                children: (campaignData as RawDataRow[]).map(row => ({
                                    name: row['asset_group.name'],
                                    value: parseInt(row['metrics.impressions']) || 0,
                                    cost: parseInt(row['metrics.cost_micros']) / 1000000 || 0,
                                    clicks: parseInt(row['metrics.clicks']) || 0,
                                    conversions: parseFloat(row['metrics.conversions']) || 0,
                                    conversionValue: parseFloat(row['metrics.conversions_value']) || 0,
                                    status: row['asset_group.status'],
                                    adStrength: row['asset_group.ad_strength']
                                }))
                            }))
                        };
                    }).sort((a, b) => a.date.localeCompare(b.date));

                    setProcessedData(processed);
                }
            } catch (err) {
                setError(err instanceof Error ? err.message : 'Unknown error');
            }
        };

        loadData();
    }, []);

    useEffect(() => {
        let intervalId: number | undefined;
        if (isPlaying && processedData.length > 0) {
            intervalId = window.setInterval(() => {
                setCurrentDay(prev => {
                    if (prev >= processedData.length - 1) {
                        setIsPlaying(false);
                        return 0;
                    }
                    return prev + 1;
                });
            }, 500);
        }
        return () => {
            if (intervalId !== undefined) {
                clearInterval(intervalId);
            }
        };
    }, [isPlaying, processedData.length]);

    const getAdStrengthColor = (strength: string): string => {
        const colors: Record<string, string> = {
            'EXCELLENT': 'rgb(0, 200, 0)',
            'GOOD': 'rgb(150, 200, 0)',
            'AVERAGE': 'rgb(200, 200, 0)',
            'POOR': 'rgb(200, 100, 0)',
            'PENDING': 'rgb(150, 150, 150)'
        };
        return colors[strength] || 'rgb(100, 100, 100)';
    };

    const calculateArc = (value: number, maxValue: number, level: number): string => {
        // Inner radius for campaigns, outer for asset groups
        const innerRadius = level === 0 ? 50 : 100;
        const outerRadius = level === 0 ? 100 : 150;

        const normalizedValue = value / maxValue;
        const angle = normalizedValue * 2 * Math.PI;

        const startAngle = 0;
        const endAngle = angle;

        const startX = 200 + innerRadius * Math.cos(startAngle);
        const startY = 200 + innerRadius * Math.sin(startAngle);
        const endX = 200 + innerRadius * Math.cos(endAngle);
        const endY = 200 + innerRadius * Math.sin(endAngle);

        const largeArcFlag = endAngle - startAngle > Math.PI ? 1 : 0;

        const outerStartX = 200 + outerRadius * Math.cos(startAngle);
        const outerStartY = 200 + outerRadius * Math.sin(startAngle);
        const outerEndX = 200 + outerRadius * Math.cos(endAngle);
        const outerEndY = 200 + outerRadius * Math.sin(endAngle);

        return `
      M ${startX} ${startY}
      A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 1 ${endX} ${endY}
      L ${outerEndX} ${outerEndY}
      A ${outerRadius} ${outerRadius} 0 ${largeArcFlag} 0 ${outerStartX} ${outerStartY}
      Z
    `;
    };

    // Update the calculateRotation function to handle the hierarchy properly
    const calculateRotation = (index: number, total: number, parentStart: number = 0): string => {
        const sectorAngle = 360 / total;
        const rotationAngle = parentStart + (sectorAngle * index);
        return `rotate(${rotationAngle} 200 200)`;
    };

    if (error) {
        return (
            <Card className="w-full max-w-4xl">
                <CardContent>
                    <div className="text-red-500">Error loading data: {error}</div>
                </CardContent>
            </Card>
        );
    }

    if (!processedData.length) {
        return (
            <Card className="w-full max-w-4xl">
                <CardContent>
                    <div>Loading...</div>
                </CardContent>
            </Card>
        );
    }

    const currentData = processedData[currentDay];
    const maxValue = Math.max(
        ...currentData.data.flatMap(campaign =>
            [campaign.value, ...campaign.children.map(group => group.value)]
        )
    );

    return (
        <Card className="w-full max-w-4xl">
            <CardHeader>
                <CardTitle className="flex justify-between items-center">
                    <span>Performance Max Campaign Insights</span>
                    <span className="text-sm font-normal">Date: {currentData.date}</span>
                </CardTitle>
            </CardHeader>
            <CardContent>
                <div className="flex flex-col items-center">
                    <svg viewBox="0 0 400 400" className="w-full max-w-lg">
                        {currentData.data.map((campaign, i) => {
                            const campaignAngle = (360 / currentData.data.length) * i;
                            return (
                                <g key={campaign.name}>
                                    {/* Campaign segment */}
                                    <path
                                        d={calculateArc(campaign.value, maxValue, 0)}
                                        transform={calculateRotation(i, currentData.data.length)}
                                        fill={`hsl(${(i * 360) / currentData.data.length}, 70%, 50%)`}
                                        opacity="0.8"
                                        className="transition-all duration-500 ease-in-out"
                                    >
                                        <title>{`${campaign.name}\nImpressions: ${campaign.value.toLocaleString()}`}</title>
                                    </path>

                                    {/* Asset group segments */}
                                    {campaign.children.map((group, j) => (
                                        <path
                                            key={group.name}
                                            d={calculateArc(group.value, maxValue, 1)}
                                            transform={calculateRotation(j, campaign.children.length, campaignAngle)}
                                            fill={getAdStrengthColor(group.adStrength)}
                                            opacity={group.status === 'PAUSED' ? 0.3 : 0.8}
                                            className="transition-all duration-500 ease-in-out"
                                        >
                                            <title>{`${group.name}\nStatus: ${group.status}\nStrength: ${group.adStrength}\nImpressions: ${group.value.toLocaleString()}`}</title>
                                        </path>
                                    ))}
                                </g>
                            );
                        })}
                    </svg>

                    <div className="flex gap-4 mt-4">
                        <button
                            onClick={() => {
                                setCurrentDay(0);
                                setIsPlaying(false);
                            }}
                            className="p-2 rounded-full bg-gray-100 hover:bg-gray-200"
                        >
                            <SkipBack className="h-5 w-5" />
                        </button>
                        <button
                            onClick={() => setIsPlaying(!isPlaying)}
                            className="p-2 rounded-full bg-gray-100 hover:bg-gray-200"
                        >
                            {isPlaying ? <Pause className="h-5 w-5" /> : <Play className="h-5 w-5" />}
                        </button>
                    </div>

                    <div className="w-full mt-4">
                        <input
                            type="range"
                            min="0"
                            max={processedData.length - 1}
                            value={currentDay}
                            onChange={(e) => setCurrentDay(parseInt(e.target.value))}
                            className="w-full"
                        />
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default GroupsPage;