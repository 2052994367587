import React, { useRef, useEffect, useState, useMemo } from 'react';
import { screens } from '@/lib/cpd-content';
import { Chart } from '@/components/cpd-images';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";

const FinalContent = () => (
  <div className="absolute inset-0 flex items-center justify-center bg-background">
    <div className="max-w-4xl p-8 space-y-8">
      <h2 className="text-4xl font-bold text-center mb-12">Ready to Level Up Your Career?</h2>
      <div className="grid gap-6 grid-cols-1 lg:grid-cols-3">
        <Card className="bg-gradient-to-br from-orange-50 to-orange-100 dark:from-orange-900/20 dark:to-orange-800/20 border-orange-200 flex flex-col">
          <CardHeader>
            <CardTitle className="text-orange-700 dark:text-orange-400">Build The Agent Workshop</CardTitle>
          </CardHeader>
          <CardContent className="flex-1 flex flex-col">
            <div>
              <p className="mb-4">
                <span className="text-orange-600 font-semibold">Master Data & Automation</span>
              </p>
              <ul className="list-none space-y-2 mb-6">
                <li className="flex items-start">
                  <span className="text-orange-500 mr-2">✓</span>
                  Live workshop sessions
                </li>
                <li className="flex items-start">
                  <span className="text-orange-500 mr-2">✓</span>
                  Hands-on practice
                </li>
                <li className="flex items-start">
                  <span className="text-orange-500 mr-2">✓</span>
                  Real-world projects
                </li>
              </ul>
            </div>
            <div className="mt-auto">
              <Button
                className="w-full bg-orange-600 hover:bg-orange-700 text-white"
                onClick={() => window.open('https://8020agent.com/build', '_blank')}
              >
                Join Workshop <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </CardContent>
        </Card>

        <Card className="bg-gradient-to-br from-blue-50 to-blue-100 dark:from-blue-900/20 dark:to-blue-800/20 border-blue-200 flex flex-col">
          <CardHeader>
            <CardTitle className="text-blue-700 dark:text-blue-400">Mega Bundle</CardTitle>
          </CardHeader>
          <CardContent className="flex-1 flex flex-col">
            <div>
              <p className="mb-4">
                <span className="text-blue-600 font-semibold">Complete Learning Path</span>
              </p>
              <ul className="list-none space-y-2 mb-6">
                <li className="flex items-start">
                  <span className="text-blue-500 mr-2">✓</span>
                  My 2024 courses & scripts
                </li>
                <li className="flex items-start">
                  <span className="text-blue-500 mr-2">✓</span>
                  Support from me (Mike)
                </li>
                <li className="flex items-start">
                  <span className="text-blue-500 mr-2">✓</span>
                  Regular free updates
                </li>
              </ul>
            </div>
            <div className="mt-auto">
              <Button
                className="w-full bg-blue-600 hover:bg-blue-700 text-white"
                onClick={() => window.open('https://8020agent.com/buy', '_blank')}
              >
                View Bundle <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </CardContent>
        </Card>

        <Card className="bg-gradient-to-br from-green-50 to-green-100 dark:from-green-900/20 dark:to-green-800/20 border-green-200 flex flex-col">
          <CardHeader>
            <CardTitle className="text-green-700 dark:text-green-400">Free Resources</CardTitle>
          </CardHeader>
          <CardContent className="flex-1 flex flex-col">
            <div>
              <p className="mb-4">
                <span className="text-green-600 font-semibold">Start Learning Now</span>
              </p>
              <ul className="list-none space-y-2 mb-6">
                <li className="flex items-start">
                  <span className="text-green-500 mr-2">✓</span>
                  Sample scripts
                </li>
                <li className="flex items-start">
                  <span className="text-green-500 mr-2">✓</span>
                  Video tutorials
                </li>
                <li className="flex items-start">
                  <span className="text-green-500 mr-2">✓</span>
                  Community access
                </li>
              </ul>
            </div>
            <div className="mt-auto">
              <Button
                className="w-full bg-green-600 hover:bg-green-700 text-white"
                onClick={() => window.open('https://mikerhodes.circle.so/c/start/', '_blank')}
              >
                Get Started <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  </div>
);

export default function CPDPage() {
  const [activeSection, setActiveSection] = useState<string>('introduction');
  const [showFinal, setShowFinal] = useState(false);
  const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    const observers: IntersectionObserver[] = [];

    screens.forEach(section => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setActiveSection(section.id);
              // Show final screen when last section is visible
              if (section.id === 'continuous-growth') {
                setShowFinal(true);
              } else {
                setShowFinal(false);
              }
            }
          });
        },
        { threshold: 0.5 }
      );

      if (sectionRefs.current[section.id]) {
        observer.observe(sectionRefs.current[section.id]!);
      }
      observers.push(observer);
    });

    return () => {
      observers.forEach(observer => observer.disconnect());
    };
  }, []);

  const activeStep = useMemo(() => {
    const section = screens.find(s => s.id === activeSection);
    return section?.step || 1;
  }, [activeSection]);

  return (
    <div className="flex min-h-screen bg-background">
      <div className="w-[70%] sticky top-0 h-screen flex items-center justify-center relative">
        <div className="w-full max-w-5xl p-12">
          <Chart step={activeStep} />
        </div>
        {showFinal && <FinalContent />}
        {activeSection === 'introduction' && (
          <div className="absolute bottom-24 left-1/2 -translate-x-1/2 flex flex-col items-center animate-bounce">
            <p className="text-muted-foreground mb-2">Scroll to continue</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-muted-foreground"
            >
              <path d="M12 5v14M19 12l-7 7-7-7" />
            </svg>
          </div>
        )}
      </div>

      <div className="w-[30%] border-l">
        {screens.map(section => (
          <div
            key={section.id}
            ref={el => {
              sectionRefs.current[section.id] = el;
            }}
            className="min-h-screen flex items-center"
            id={section.id}
          >
            <div className="p-8">
              <div className="text-sm text-muted-foreground mb-2">Step {section.step}</div>
              <h2 className="text-3xl font-bold mb-6 text-foreground">{section.title}</h2>
              <p className="text-xl leading-relaxed text-muted-foreground">{section.content}</p>
              {section.keyPoints && (
                <ul className="mt-6 space-y-2">
                  {section.keyPoints.map((point, i) => (
                    <li key={i} className="text-muted-foreground flex items-center gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary" />
                      {point}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}