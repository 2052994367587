import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { useUser } from "@clerk/clerk-react";
import {
  CalendarDays, Users, MapPin, Clock, Star,
  Zap, Target, BookOpen, Brain, Video,
  Calendar, GraduationCap, Code, CheckCircle2
} from 'lucide-react';

export default function MasteryWorkshop() {
  const { user } = useUser();

  return (
    <div className="min-h-screen">
      {/* Hero Section with Background */}
      <div className="relative bg-gradient-to-r from-purple-600 to-blue-700 text-white py-8">
        <div className="container mx-auto px-4 max-w-5xl relative z-10">
          <div className="text-center mb-8">
            <Badge variant="secondary" className="mb-3 bg-white/10 backdrop-blur-sm">Starting Early 2025</Badge>
            <h1 className="text-5xl font-bold mb-4">Scripts & Sheets Mastery 2025</h1>
            <p className="text-2xl mb-6 text-white/90">Master Google Ads Scripts & Sheets in 4 Weeks</p>

            <div className="flex justify-center gap-6 flex-wrap mb-6">
              <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full">
                <Video className="h-5 w-5" />
                <span className="font-semibold">Live Weekly Sessions</span>
              </div>
              <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full">
                <Star className="h-5 w-5" />
                <span className="font-semibold">Includes PMax Script ($199 value)</span>
              </div>
            </div>

            <a
              href="https://mikerhodes.circle.so/checkout/2025-scripts-and-sheets-mastery"
              className="inline-block"
            >
              <Button size="lg" className="bg-white text-purple-600 hover:bg-white/90">
                Join the 2025 Cohort
              </Button>
            </a>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-16 max-w-5xl">
        {/* Course Overview */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Brain className="h-6 w-6 text-purple-600" />
                The QTS System™
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <p className="text-lg text-muted-foreground">Master the three essential components of script creation with my simple, proven system (even if you've never written a line of code before):</p>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <span className="text-purple-600 font-bold">Q</span>
                  <div>
                    <h4 className="font-semibold">Query</h4>
                    <p className="text-muted-foreground">Learn to extract the right data</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="text-purple-600 font-bold">T</span>
                  <div>
                    <h4 className="font-semibold">Template</h4>
                    <p className="text-muted-foreground">Use proven templates to build scripts fast</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="text-purple-600 font-bold">S</span>
                  <div>
                    <h4 className="font-semibold">Sheet</h4>
                    <p className="text-muted-foreground">Master advanced sheet techniques</p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Calendar className="h-6 w-6 text-purple-600" />
                4-Week Journey
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <span className="bg-purple-100 dark:bg-purple-900 w-8 h-8 flex items-center justify-center rounded-full text-purple-600 font-bold flex-shrink-0">0</span>
                  <div>
                    <h4 className="font-semibold">Week 0: Pre-Training</h4>
                    <p className="text-muted-foreground">Get started with the QTS System™</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="bg-purple-100 dark:bg-purple-900 w-8 h-8 flex items-center justify-center rounded-full text-purple-600 font-bold flex-shrink-0">1</span>
                  <div>
                    <h4 className="font-semibold">Week 1: Script Fundamentals</h4>
                    <p className="text-muted-foreground">Install, edit & write your first scripts</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="bg-purple-100 dark:bg-purple-900 w-8 h-8 flex items-center justify-center rounded-full text-purple-600 font-bold flex-shrink-0">2</span>
                  <div>
                    <h4 className="font-semibold">Week 2: Google Sheets Mastery</h4>
                    <p className="text-muted-foreground">Advanced formulae & charting</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="bg-purple-100 dark:bg-purple-900 w-8 h-8 flex items-center justify-center rounded-full text-purple-600 font-bold flex-shrink-0">3</span>
                  <div>
                    <h4 className="font-semibold">Week 3: Intermediate Scripts</h4>
                    <p className="text-muted-foreground">Level up your script skills</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="bg-purple-100 dark:bg-purple-900 w-8 h-8 flex items-center justify-center rounded-full text-purple-600 font-bold flex-shrink-0">4</span>
                  <div>
                    <h4 className="font-semibold">Week 4: AI-Powered Scripts</h4>
                    <p className="text-muted-foreground">How to write scripts with AI in 2025</p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* What You'll Get */}
        <Card className="mb-16">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <BookOpen className="h-6 w-6 text-purple-600" />
              What's Included ($1,500+ Value)
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <div>
                  <h3 className="font-semibold text-lg mb-4">Core Training</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>4 Live Weekly Sessions</strong>
                        <p className="text-muted-foreground">Interactive learning with direct support</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Pre-Training QTS System™</strong>
                        <p className="text-muted-foreground">Get started before the first session</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Weekly Assignments</strong>
                        <p className="text-muted-foreground">Practice exercises to reinforce learning</p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div>
                  <h3 className="font-semibold text-lg mb-4">Premium Scripts ($400+ Value)</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>PMax Script ($199 value)</strong>
                        <p className="text-muted-foreground">My most popular performance script</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Negative Keywords Script ($109 value)</strong>
                        <p className="text-muted-foreground">Build better lists faster</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Asset Chart Script ($49 value)</strong>
                        <p className="text-muted-foreground">Visualize performance data</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>5+ Additional Scripts</strong>
                        <p className="text-muted-foreground">Collection of proven automation scripts</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="space-y-6">
                <div>
                  <h3 className="font-semibold text-lg mb-4">Resources & Tools</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>10+ Script Templates</strong>
                        <p className="text-muted-foreground">Ready-to-use code for common tasks</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>AI Prompt Collection</strong>
                        <p className="text-muted-foreground">My proven prompts for script writing</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Debugging Toolkit</strong>
                        <p className="text-muted-foreground">Troubleshoot scripts like a pro</p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div>
                  <h3 className="font-semibold text-lg mb-4">Exclusive Bonuses</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong className="flex items-center gap-2 text-lg text-purple-600">
                          $500 Own the Agent™ Voucher
                          <Badge variant="secondary" className="bg-purple-100 text-purple-600 dark:bg-purple-900 dark:text-purple-100">
                            MASSIVE VALUE
                          </Badge>
                        </strong>
                        <p className="text-muted-foreground font-medium">Makes this program effectively free!</p>
                        <p className="text-sm text-muted-foreground">Redeemable against the upcoming Own the Agent™ program</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>$99 Build the Agent Voucher</strong>
                        <p className="text-muted-foreground">Redeemable against the upcoming Build the Agent™ program</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Follow-up Q&A Session</strong>
                        <p className="text-muted-foreground">Group call one month after the workshop</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Private Community Access</strong>
                        <p className="text-muted-foreground">Connect with fellow students</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Lifetime Updates</strong>
                        <p className="text-muted-foreground">Access to all future content & improvements</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Prerequisites */}
        <Card className="mb-16">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <CheckCircle2 className="h-6 w-6 text-purple-600" />
              Before We Start
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="space-y-4">
              <h3 className="font-semibold text-lg">Required Skills</h3>
              <ul className="space-y-2">
                <li className="flex items-center gap-2">
                  <span className="text-purple-500">✓</span>
                  Basic Google Sheets knowledge (we'll teach the rest)
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-purple-500">✓</span>
                  Google Ads account access
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <h3 className="font-semibold text-lg">Optional Preparation</h3>
              <ul className="space-y-2">
                <li className="flex items-center gap-2">
                  <span className="text-purple-500">✓</span>
                  Basic JavaScript familiarity (recommended but not required)
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-purple-500">✓</span>
                  Get early access to the QTS System overview video
                </li>
              </ul>
            </div>
          </CardContent>
        </Card>

        {/* CTA Section */}
        <div className="text-center space-y-6 py-8">
          <h2 className="text-3xl font-bold">Ready to Master Scripts & Sheets?</h2>
          <p className="text-xl text-muted-foreground">Join the 2025 cohort and transform your Google Ads workflow</p>
          <div className="flex flex-col items-center gap-4">
            <div className="text-4xl font-bold text-purple-600">$399</div>
            <a
              href="https://mikerhodes.circle.so/checkout/2025-scripts-and-sheets-mastery"
              className="inline-block"
            >
              <Button size="lg" className="bg-purple-600 hover:bg-purple-700">
                Secure Your Spot
              </Button>
            </a>
            <p className="text-sm text-muted-foreground">Limited spots available • One-time payment</p>
          </div>
        </div>
      </div>
    </div>
  );
} 