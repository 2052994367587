// src/lib/4cs-scripts.ts

export const SCRIPT_EXAMPLES = {
    CHECK_ZERO_IMPRESSIONS: `
// script to alert if campaigns had zero impr yesterday. See 8020agent.com for more

const YOUR_EMAIL = '';   // enter your email address here between the single quotes

function main() {
  try {
    const campaigns = AdsApp.campaigns()
      .withCondition('Status = ENABLED')
      .withCondition('CampaignExperimentType = BASE') 
      .withCondition('ServingStatus = SERVING')
      .withCondition('Impressions = 0')
      .forDateRange('YESTERDAY')
      .get();
      
    if (campaigns.totalNumEntities() === 0) {
      Logger.log('All campaigns received impressions yesterday - no email sent');
      return;
    }
    
    const problemCampaigns = [];
    while (campaigns.hasNext()) {
      const campaign = campaigns.next();
      const budget = campaign.getBudget().getAmount();
      problemCampaigns.push({
        name: campaign.getName(),
        budget: budget
      });
    }
    
    const subject = '8020agent Alert: Campaigns With Zero Impressions';
        
    MailApp.sendEmail({
      to: YOUR_EMAIL,
      subject: subject,
      body: \`The following campaigns had zero impressions yesterday:\\n\\n\${
        problemCampaigns.map(campaign => 
          \`\${campaign.name} (Daily Budget: $\${campaign.budget})\`
        ).join('\\n')
      }\\n\\nThis is an automated alert sent by a google ads script.\`
    });
    
    Logger.log(\`Alert email sent to \${YOUR_EMAIL} for \${problemCampaigns.length} campaigns\`);
  } catch (error) {
    Logger.log(\`Error in campaign monitoring script: \${error.message}\`);
    MailApp.sendEmail({
      to: YOUR_EMAIL,
      subject: 'Error in Google Ads Monitoring Script',
      body: \`The campaign monitoring script encountered an error: \${error.message}\`
    });
  }
}`,

    EXPORT_TO_SHEETS: `
// Script to export campaign data to Google Sheets using GAQL. See 8020agent.com for more

const SPREADSHEET_URL = ''; // Add your Google Sheet URL here or leave blank to create new

function main() {
  let sheet;
  
  try {
    // Check if URL is provided
    if (!SPREADSHEET_URL) {
      // Create new spreadsheet
      const newSpreadsheet = SpreadsheetApp.create('Google Ads Campaign Data ' + new Date().toISOString().split('T')[0]);
      sheet = newSpreadsheet.getActiveSheet();
      Logger.log(\`Created new spreadsheet: \${newSpreadsheet.getUrl()}\`);
    } else {
      // Use existing spreadsheet
      try {
        sheet = SpreadsheetApp.openByUrl(SPREADSHEET_URL).getActiveSheet();
      } catch (error) {
        throw new Error(\`Could not open spreadsheet at \${SPREADSHEET_URL}. Error: \${error.message}\`);
      }
    }
    
    // Clear existing data
    sheet.clear();
    
    // Add headers
    sheet.getRange(1, 1, 1, 7).setValues([[
      'Date', 'Campaign', 'Impressions', 'Clicks', 'Cost', 'Conversions', 'Conv. Value'
    ]]);
    
    // Get campaign data using GAQL
    const query = AdsApp.report(
      \`SELECT 
        segments.date,
        campaign.name,
        metrics.impressions,
        metrics.clicks,
        metrics.cost_micros,
        metrics.conversions,
        metrics.conversions_value
      FROM campaign
      WHERE campaign.status = 'ENABLED'
      AND segments.date DURING LAST_30_DAYS\`
    );
    
    const rows = query.rows();
    const data = [];
    
    while (rows.hasNext()) {
      const row = rows.next();
      data.push([
        row['segments.date'],
        row['campaign.name'],
        row['metrics.impressions'],
        row['metrics.clicks'],
        row['metrics.cost_micros'] / 1000000, // Convert micros to actual currency
        row['metrics.conversions'],
        row['metrics.conversions_value']
      ]);
    }
    
    // Write data to sheet
    if (data.length > 0) {
      sheet.getRange(2, 1, data.length, data[0].length).setValues(data);
    }
    
    const message = \`Exported \${data.length} campaign rows to Google Sheets\`;
    if (!SPREADSHEET_URL) {
      Logger.log(message + \`\\nSpreadsheet URL: \${sheet.getParent().getUrl()}\`);
    } else {
      Logger.log(message);
    }
  } catch (error) {
    const errorMsg = \`Error in campaign export script: \${error.message}\`;
    Logger.log(errorMsg);
    throw new Error(errorMsg);
  }
}`,

    PAUSE_OVERSPEND: `
// Script to pause campaigns that spent more than 2x their daily budget yesterday. See 8020agent.com for more
// Set this to true ONLY if you understand this script will pause campaigns
const I_UNDERSTAND_THIS_MAKES_CHANGES = false;

function main() {
  Logger.log(I_UNDERSTAND_THIS_MAKES_CHANGES ? 
    '⚠️ LIVE MODE - WILL PAUSE CAMPAIGNS' :
    '⚠️ PREVIEW MODE - NO CAMPAIGNS WILL BE PAUSED');
  Logger.log('------------------------');
  
  processAllCampaigns(true); // Always process campaigns, but in appropriate mode
}

function processAllCampaigns(isPreview) {
  try {
    let hasOverspendingCampaigns = false;
    
    // Handle regular campaigns
    const campaigns = AdsApp.campaigns()
      .withCondition('Status = ENABLED')
      .forDateRange('YESTERDAY')
      .get();
      
    if (campaigns.hasNext()) {
      hasOverspendingCampaigns = processCampaignIterator(campaigns, isPreview) || hasOverspendingCampaigns;
    } else {
      Logger.log('ℹ️ No regular campaigns found');
    }
    
    // Handle Performance Max campaigns
    const pmaxCampaigns = AdsApp.performanceMaxCampaigns()
      .withCondition('Status = ENABLED')
      .forDateRange('YESTERDAY')
      .get();
      
    if (pmaxCampaigns.hasNext()) {
      hasOverspendingCampaigns = processCampaignIterator(pmaxCampaigns, isPreview) || hasOverspendingCampaigns;
    } else {
      Logger.log('ℹ️ No Performance Max campaigns found');
    }
    
    if (!hasOverspendingCampaigns) {
      Logger.log('✅ No campaigns found that exceeded 2x daily budget');
    }
    
  } catch (e) {
    Logger.log('❌ Error: ' + e.toString());
  }
}

function processCampaignIterator(campaignIterator, isPreview) {
  let foundOverspending = false;
  
  while (campaignIterator.hasNext()) {
    try {
      const campaign = campaignIterator.next();
      const stats = campaign.getStatsFor('YESTERDAY');
      const budget = campaign.getBudget().getAmount();
      const spend = stats.getCost();
      const type = campaign.getAdvertisingChannelType ? 
        campaign.getAdvertisingChannelType() : 
        'PERFORMANCE_MAX';
      
      if (spend > budget * 2) {
        foundOverspending = true;
        if (isPreview) {
          Logger.log('🔄 Campaign would be paused:');
          Logger.log(\`Name: \${campaign.getName()}\`);
          Logger.log(\`Type: \${type}\`);
          Logger.log(\`Daily Budget: \${budget}\`);
          Logger.log(\`Actual Spend: \${spend}\`);
          Logger.log(\`Overspend: \${(spend - budget).toFixed(2)} (\${((spend/budget - 1) * 100).toFixed(1)}%)\`);
          Logger.log('------------------------');
        } else {
          campaign.pause();
          Logger.log('🛑 Paused campaign:');
          Logger.log(\`Name: \${campaign.getName()}\`);
          Logger.log(\`Type: \${type}\`);
          Logger.log(\`Daily Budget: \${budget}\`);
          Logger.log(\`Actual Spend: \${spend}\`);
          Logger.log('------------------------');
        }
      }
    } catch (e) {
      Logger.log(\`❌ Error processing campaign: \${e.toString()}\`);
      continue;
    }
  }
  
  return foundOverspending;
}
  `,

    AI_INSIGHTS: `
// Script to use OpenAI to analyze campaign performance. See 8020agent.com for more

const OPENAI_API_KEY = ''; // Add your OpenAI API key here
const SYSTEM_PROMPT = \`
You are an expert Google Ads analyst. 
Provide a clear 2-sentence summary of campaign performance.
Focus on the most significant changes or patterns.
\`;
  
  function main() {
    try {
      // Validate API key
      if (!OPENAI_API_KEY) {
        throw new Error('OpenAI API key is missing. Please add your API key to the OPENAI_API_KEY constant.');
      }
  
      // Get campaign data with error handling
      const data = getCampaignData();
      if (!data.length) {
        Logger.log('⚠️ Warning: No campaign data found to analyze');
        return;
      }
  
      Logger.log(\`ℹ️ Retrieved data for \${data.length} campaigns\`);
      
      // Make API request with error handling
      const aiResponse = getAIAnalysis(data);
      Logger.log('✅ AI Analysis: ' + aiResponse);
  
    } catch (error) {
      logError('Main execution error', error);
    }
  }
  
  function getCampaignData() {
    try {
      const data = [];
      const campaigns = AdsApp.campaigns()
        .withCondition('Status = ENABLED')
        .forDateRange('YESTERDAY')
        .get();
      
      if (!campaigns.hasNext()) {
        Logger.log('ℹ️ No enabled campaigns found for yesterday');
        return data;
      }
      
      while (campaigns.hasNext()) {
        try {
          const campaign = campaigns.next();
          const stats = campaign.getStatsFor('YESTERDAY');
          
          data.push({
            name: campaign.getName(),
            impressions: stats.getImpressions(),
            clicks: stats.getClicks(),
            cost: stats.getCost(),
            conversions: stats.getConversions()
          });
        } catch (campaignError) {
          logError('Error processing campaign', campaignError);
          // Continue with next campaign
          continue;
        }
      }
      
      return data;
      
    } catch (error) {
      logError('Error getting campaign data', error);
      return [];
    }
  }
  
  function getAIAnalysis(data) {
    try {
      const prompt = {
        model: "gpt-4",  // Fixed typo in model name
        messages: [
          { role: "system", content: SYSTEM_PROMPT },
          { role: "user", content: JSON.stringify(data) }
        ]
      };
      
      // Validate data before sending
      if (!validatePromptData(prompt)) {
        throw new Error('Invalid prompt data structure');
      }
      
      const response = makeAPIRequest(prompt);
      
      // Validate response
      if (!response.choices || !response.choices[0] || !response.choices[0].message) {
        throw new Error('Invalid response structure from OpenAI API');
      }
      
      return response.choices[0].message.content;
      
    } catch (error) {
      logError('Error getting AI analysis', error);
      return 'Unable to generate AI analysis due to error';
    }
  }
  
  function makeAPIRequest(prompt) {
    try {
      const response = UrlFetchApp.fetch('https://api.openai.com/v1/chat/completions', {
        method: 'post',
        headers: {
          'Authorization': 'Bearer ' + OPENAI_API_KEY,
          'Content-Type': 'application/json'
        },
        payload: JSON.stringify(prompt),
        muteHttpExceptions: true // Prevent HTTP exceptions from throwing
      });
      
      const responseCode = response.getResponseCode();
      if (responseCode !== 200) {
        throw new Error(\`API request failed with status \${responseCode}: \${response.getContentText()}\`);
      }
      
      return JSON.parse(response.getContentText());
      
    } catch (error) {
      throw new Error(\`API request failed: \${error.message}\`);
    }
  }
  
  function validatePromptData(prompt) {
    return prompt &&
           prompt.messages &&
           Array.isArray(prompt.messages) &&
           prompt.messages.length >= 2 &&
           prompt.messages[0].role === 'system' &&
           prompt.messages[1].role === 'user';
  }
  
  function logError(context, error) {
    const timestamp = new Date().toISOString();
    Logger.log(\`❌ ERROR [\${timestamp}] \${context}:\\n\`);
    Logger.log(\`   Message: \${error.message}\`);
    Logger.log(\`   Stack: \${error.stack || 'No stack trace available'}\`);
    Logger.log('------------------------');
  }
`
} as const;

export const scriptInfo = {
    CHECK_ZERO_IMPRESSIONS: {
        title: 'Zero Impressions Alert',
        description: 'Sends an email alert if any enabled campaigns had zero impressions yesterday.',
        safety: 'Read-only - No changes made to your account',
        setupSteps: [
            'Add your email address to YOUR_EMAIL variable',
            'Set to run daily (recommended: early morning)',
            'Ensure you have email permissions enabled'
        ]
    },
    EXPORT_TO_SHEETS: {
        title: 'Campaign Data Export',
        description: 'Exports last 30 days of campaign performance data to Google Sheets for visualization. Try creating a line chart from your own data!',
        safety: 'Read-only - No changes made to your account',
        setupSteps: [
            'Create a new Google Sheet',
            'Copy the sheet URL to SPREADSHEET_URL variable',
            'Set to run daily to keep data fresh'
        ]
    },
    PAUSE_OVERSPEND: {
        title: 'Overspend Protection',
        description: 'This script pauses campaigns that spent more than 2x their daily budget yesterday. Use with caution!',
        safety: '⚠️ Makes changes to your account - Use with caution',
        setupSteps: [
            'Review I_UNDERSTAND_THIS_MAKES_CHANGES setting',
            'Test in preview mode first',
            'Set to run daily (recommended: every 4 hours)'
        ]
    },
    AI_INSIGHTS: {
        title: 'AI Performance Analysis',
        description: 'Think of this as a mini-Whisperer script. It uses OpenAI to analyze yesterday\'s campaign performance. It does not make changes to your account.',
        safety: 'Read-only - No changes made to your account',
        setupSteps: [
            'Add your OpenAI API key. Get one from https://platform.openai.com/api-keys',
            'Customize the SYSTEM_PROMPT if desired',
            'Use this as the basis to add AI to your own scripts!'
        ]
    }
} as const;