// src/pages/test.tsx
import { useState, useCallback, FormEvent, useEffect } from 'react'
import { Loader2, Plus } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import { useUser } from '@clerk/clerk-react'
import { useCampaignData } from '@/contexts/campaign-data'
import { generateAIResponse } from '../../services/ai-api'
import { Provider, Model, TokenUsage, UserDebugInfo, APIUsage } from '@/types/ai'
import { useAIUsage } from '@/hooks/use-ai-usage'
import { ModelControls } from '@/components/ai-test/model-controls'
import { PromptInput } from '@/components/ai-test/prompt-input'
import { UsageStats } from '@/components/ai-test/usage-stats'
import { ResponseDisplay } from '@/components/ai-test/response-display'
import { DebugPanel } from '@/components/ai-test/debug-panel'
import { MODELS, DEBUG, DataSource, RecordLimit, DATA_SOURCES } from '@/config/ai-models'
import { SHEET_TABS } from '@/lib/constants'
import { PromptControls } from '@/components/ai-test/prompt-controls'
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '@/components/ui/tooltip'

export default function Test() {
    const { user } = useUser()
    const { data: campaignData } = useCampaignData()
    const { stats, updateStats, syncToClerk } = useAIUsage()

    const [showResponse, setShowResponse] = useState(false)
    const [provider, setProvider] = useState<Provider>('gemini')
    const [modelId, setModelId] = useState<string>(MODELS.gemini[0].id)
    const [maxTokens, setMaxTokens] = useState<number>(1000)
    const [prompt, setPrompt] = useState<string>('')
    const [selectedPromptId, setSelectedPromptId] = useState<string | null>(null)
    const [error, setError] = useState<string>('')
    const [loading, setLoading] = useState(false)
    const [output, setOutput] = useState<string>('')
    const [timeTaken, setTimeTaken] = useState<number | null>(null)
    const [tokenUsage, setTokenUsage] = useState<TokenUsage | null>(null)
    const [apiUsage, setApiUsage] = useState<APIUsage | null>(null)
    const [dataSource, setDataSource] = useState<DataSource>('daily')
    const [recordLimit, setRecordLimit] = useState<RecordLimit>(100)

    const [debugInfo, setDebugInfo] = useState<UserDebugInfo>(() => ({
        id: user?.id || '',
        email: user?.primaryEmailAddress?.emailAddress || '',
        apiCalls: stats.apiCalls,
        totalCost: stats.totalCost,
        lastUpdated: stats.lastUpdated
    }))

    const getDataPreview = useCallback((source: DataSource) => {
        if (!campaignData) return null

        const data = campaignData[source]
        if (!data?.length) return null

        const limitedData = recordLimit === 'all' ? data : data.slice(0, recordLimit)
        return JSON.stringify(limitedData, null, 2) +
            (recordLimit !== 'all' && data.length > recordLimit ?
                `\n\n... and ${data.length - recordLimit} more records` : '')
    }, [campaignData, recordLimit])

    const generateText = useCallback(async (e: FormEvent) => {
        e.preventDefault()

        const start = Date.now()
        setLoading(true)
        setError('')
        setShowResponse(false)

        const selectedModel = MODELS[provider].find(m => m.id === modelId)
        if (!selectedModel) {
            setError('Model not found')
            setLoading(false)
            return
        }

        let finalPrompt = prompt
        const dataPreview = getDataPreview(dataSource)
        if (dataPreview) {
            finalPrompt = `${prompt}\n\nContext (${DATA_SOURCES[dataSource].label} - ${campaignData[dataSource]?.length} records):\n${dataPreview}`
        }

        try {
            const { text, usage } = await generateAIResponse(provider, selectedModel, finalPrompt, maxTokens, user)

            // Update local stats only
            updateStats(usage.costs.total)

            // Update UI state
            setOutput(text)
            setTimeTaken((Date.now() - start) / 1000)
            setTokenUsage({
                inputTokens: usage.inputTokens,
                outputTokens: usage.outputTokens,
                totalCost: usage.costs.total
            })
            setApiUsage(usage)
            setShowResponse(true)

        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'An error occurred'
            setError(errorMessage)
            console.error('Error in generateText:', err)
        } finally {
            setLoading(false)
        }
    }, [
        provider,
        modelId,
        maxTokens,
        prompt,
        dataSource,
        campaignData,
        getDataPreview,
        updateStats,
        user
    ])

    const handleNewPrompt = useCallback(() => {
        // Sync to Clerk before resetting
        syncToClerk()

        // Reset form state
        setShowResponse(false)
        setPrompt('')
        setOutput('')
        setError('')
        setTokenUsage(null)
        setApiUsage(null)
        setTimeTaken(null)
        setSelectedPromptId(null)
    }, [syncToClerk])

    // Add visibilitychange event listener to sync when tab is closed
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                syncToClerk()
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [syncToClerk])

    return (
        <div className="container mx-auto py-8 space-y-6">
            <Card>
                <CardHeader>
                    <CardTitle>AI API Test</CardTitle>
                </CardHeader>
                <CardContent className="space-y-6">
                    {!showResponse ? (
                        <>
                            <PromptControls
                                selectedPromptId={selectedPromptId}
                                setSelectedPromptId={setSelectedPromptId}
                                dataSource={dataSource}
                                setDataSource={setDataSource}
                                recordLimit={recordLimit}
                                setRecordLimit={setRecordLimit}
                                setPrompt={setPrompt}
                                campaignData={campaignData}
                            />

                            <form onSubmit={generateText} className="space-y-6">
                                {selectedPromptId === 'custom' && (
                                    <PromptInput
                                        prompt={prompt}
                                        setPrompt={setPrompt}
                                    />
                                )}

                                <ModelControls
                                    provider={provider}
                                    setProvider={setProvider}
                                    modelId={modelId}
                                    setModelId={setModelId}
                                    maxTokens={maxTokens}
                                    setMaxTokens={setMaxTokens}
                                />
                                <div className="flex items-center gap-2 justify-end">
                                    <Button
                                        type="submit"
                                        disabled={loading}
                                        size="sm"
                                    >
                                        {loading ? (
                                            <>
                                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                Testing API...
                                            </>
                                        ) : (
                                            "Get an 80% Insight Now!"
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </>
                    ) : (
                        <div className="space-y-6">
                            <UsageStats
                                tokenUsage={tokenUsage}
                                timeTaken={timeTaken}
                            />

                            <ResponseDisplay
                                output={output}
                                error={error}
                                selectedModel={MODELS[provider].find(m => m.id === modelId)}
                            />

                            <Button
                                onClick={handleNewPrompt}
                                size="sm"
                                variant="outline"
                                className="flex items-center"
                            >
                                <Plus className="mr-2 h-4 w-4" />
                                New Prompt
                            </Button>
                        </div>
                    )}
                </CardContent>
            </Card>

            {!showResponse && (
                <Card>
                    <CardHeader>
                        <CardTitle className="text-sm">Debug Info</CardTitle>
                    </CardHeader>
                    <CardContent className="text-sm space-y-4">
                        <div>
                            <div className="font-medium mb-1">Data Source:</div>
                            <div className="text-muted-foreground">
                                {DATA_SOURCES[dataSource].label} ({campaignData?.[dataSource]?.length || 0} records)
                                <div className="text-xs mt-1">
                                    Sorted by: {DATA_SOURCES[dataSource].defaultSort.column} ({DATA_SOURCES[dataSource].defaultSort.direction})
                                </div>
                            </div>
                        </div>

                        {campaignData?.[dataSource]?.length > 0 && (
                            <div>
                                <div className="font-medium mb-1">Sample Data (First Row):</div>
                                <pre className="text-xs bg-muted p-2 rounded overflow-auto max-h-[200px]">
                                    {JSON.stringify(campaignData[dataSource][0], null, 2)}
                                </pre>
                            </div>
                        )}
                    </CardContent>
                </Card>
            )}

            {DEBUG && showResponse && (
                <DebugPanel
                    debugInfo={debugInfo}
                    apiUsage={apiUsage}
                />
            )}
        </div>
    )
}