import { useState, useCallback } from 'react'
import { BarChart, LineChart, PieChart, Table, Filter, Database, ArrowRight, Play, Save, Calculator, Clock3 } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { Label } from '@/components/ui/label'

// Block type definitions
type BlockType =
  | 'data-source'
  | 'visualization'
  | 'filter'
  | 'aggregation'
  | 'metric'
  | 'comparison'
  | 'timeframe'

interface BlockOption {
  type: BlockType
  text: string
  icon: React.ComponentType<{ className?: string }>
  options?: string[]
  description: string
  isEnabled?: boolean
}

interface Block {
  id: string
  type: BlockType
  text: string
  selectedOption?: string
  isEnabled: boolean
}

// Available blocks configuration
const blockOptions: BlockOption[] = [
  {
    type: 'data-source',
    text: 'Data Source',
    icon: Database,
    options: ['Campaign Data', 'Ad Group Data', 'Keyword Data', 'Historical Performance'],
    description: 'Select the primary data source for analysis'
  },
  {
    type: 'visualization',
    text: 'Visualization',
    icon: BarChart,
    options: ['Bar Chart', 'Line Chart', 'Pie Chart', 'Table', 'Scatter Plot'],
    description: 'Choose how to visualize the data'
  },
  {
    type: 'filter',
    text: 'Filter',
    icon: Filter,
    options: ['Date Range', 'Performance Threshold', 'Status', 'Budget Range'],
    description: 'Add conditions to filter the data'
  },
  {
    type: 'metric',
    text: 'Metrics',
    icon: LineChart,
    options: ['impr', 'clicks', 'cost', 'conv', 'value', 'CTR', 'CvR', 'AOV', 'ROAS', 'CPA'],
    description: 'Select metrics to analyze'
  },
  {
    type: 'timeframe',
    text: 'Time Frame',
    icon: Clock3,
    options: ['Last 7 Days', 'Last 30 Days', 'Last Quarter', 'Year to Date', 'Custom'],
    description: 'Set the time period for analysis'
  }
]

interface BlockComponentProps {
  block: Block
  onOptionChange: (id: string, option: string) => void
  onToggle: (id: string) => void
}

const BlockComponent = ({ block, onOptionChange, onToggle }: BlockComponentProps) => {
  const blockOption = blockOptions.find(opt => opt.type === block.type)
  const BlockIcon = blockOption?.icon || Database

  return (
    <Card className="p-3 bg-white shadow-sm border-2 hover:border-primary">
      <div className="flex items-center gap-4 mb-2">
        <BlockIcon className="h-5 w-5 text-primary" />
        <span className="font-medium flex-grow">{block.text}</span>
        <Switch
          checked={block.isEnabled}
          onCheckedChange={() => onToggle(block.id)}
        />
      </div>

      {block.isEnabled && blockOption?.options && (
        <Select
          value={block.selectedOption}
          onValueChange={(value) => onOptionChange(block.id, value)}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select option" />
          </SelectTrigger>
          <SelectContent>
            {blockOption.options.map((option) => (
              <SelectItem key={option} value={option}>
                {option}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
    </Card>
  )
}

export default function PromptBuilder() {
  const [blocks, setBlocks] = useState<Block[]>(() =>
    blockOptions.map((block, index) => ({
      id: String(index + 1),
      type: block.type,
      text: block.text,
      isEnabled: false
    }))
  )

  const [rowCount, setRowCount] = useState<number>(10)

  const handleOptionChange = useCallback((id: string, option: string) => {
    setBlocks(prev => prev.map(block =>
      block.id === id ? { ...block, selectedOption: option } : block
    ))
  }, [])

  const handleToggle = useCallback((id: string) => {
    setBlocks(prev => prev.map(block =>
      block.id === id ? { ...block, isEnabled: !block.isEnabled } : block
    ))
  }, [])

  const generatePrompt = () => {
    const enabledBlocks = blocks.filter(block => block.isEnabled && block.selectedOption)

    if (enabledBlocks.length === 0) {
      return 'Enable and configure blocks to generate a prompt'
    }

    const dataSource = enabledBlocks.find(b => b.type === 'data-source')?.selectedOption
    const metrics = enabledBlocks.find(b => b.type === 'metric')?.selectedOption
    const timeframe = enabledBlocks.find(b => b.type === 'timeframe')?.selectedOption
    const visualization = enabledBlocks.find(b => b.type === 'visualization')?.selectedOption
    const filters = enabledBlocks.find(b => b.type === 'filter')?.selectedOption

    let prompt = `Analyze ${dataSource || 'Google Ads data'}`
    if (metrics) prompt += ` focusing on ${metrics}`
    if (timeframe) prompt += ` over ${timeframe}`
    if (filters) prompt += ` filtered by ${filters}`
    if (visualization) prompt += `. Present the results as a ${visualization}`
    prompt += `. Include ${rowCount} rows of data in the analysis.`

    return prompt
  }

  return (
    <div className="container mx-auto p-4 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="font-semibold text-lg">Analysis Builder</h2>
        <div className="space-x-2">
          <Button variant="outline">
            <Save className="h-4 w-4 mr-2" />
            Save Template
          </Button>
          <Button>
            <Play className="h-4 w-4 mr-2" />
            Run Analysis
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {blocks.map(block => (
          <BlockComponent
            key={block.id}
            block={block}
            onOptionChange={handleOptionChange}
            onToggle={handleToggle}
          />
        ))}
      </div>

      <div className="flex items-center gap-4 mb-4">
        <Label>Number of rows to analyze:</Label>
        <Input
          type="number"
          value={rowCount}
          onChange={(e) => setRowCount(Number(e.target.value))}
          className="w-32"
          min={1}
          max={1000}
        />
      </div>

      <Card className="p-4 bg-primary/5">
        <h3 className="font-medium mb-2">Generated Prompt:</h3>
        <p className="font-mono text-sm">{generatePrompt()}</p>
      </Card>
    </div>
  )
}