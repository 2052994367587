import { useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { ChatButton } from './chat-button'
import { ChatWindow } from './chat-window'
import { useAIUsage } from '@/hooks/use-ai-usage'
import { generateAIResponse } from '@/services/ai-api'
import { Model, APIUsage } from '@/types/ai'
import { getPageContext } from './page-context'

type Language = 'en' | 'it' | 'fr' | 'es' | 'nl' | 'de'

interface Message {
    role: 'user' | 'assistant'
    content: string
}

interface FredoProps {
    pageData?: any
    selectedCampaign?: string
    timeRange?: string
}

const GEMINI_MODEL: Model = {
    id: 'gemini-2.0-flash-exp',
    name: 'Gemini Flash',
    inputPrice: 0.00,  // Free for now
    outputPrice: 0.00
}

const getSystemPrompt = (language: Language) => `
You are an AI assistant specialized in analyzing Google Ads data and providing actionable insights. 
Your role is to help users improve their ad performance by offering clear, concise recommendations.
You MUST respond in ${language === 'en' ? 'English' :
        language === 'it' ? 'Italian' :
            language === 'fr' ? 'French' :
                language === 'es' ? 'Spanish' :
                    language === 'nl' ? 'Dutch' :
                        'German'}.

Instructions:
1. Focus solely on Google Ads-related queries. If a user asks about topics unrelated to Google Ads, politely redirect the conversation back to Google Ads
 - exceptions to this are Mike Rhodes, AI, Automation, Scripts, Workshops, Courses, Events, etc

2. Analyze the provided metrics using the following process with your internal scratch pad
a. Review the metrics and identify key performance indicators (KPIs).
b. Write down each KPI and its current value on your personal scratch pad - don't show this to the user
c. Compare current performance to historical data or industry benchmarks, if available.
d. For each KPI, consider and note down both positive and negative aspects of its performance.
e. Identify significant trends, changes, or anomalies in the data.
f. Determine the top 2-3 areas that require attention or improvement.
g. For each area, formulate a clear, actionable recommendation.
h. Prioritize recommendations based on their potential impact on overall performance.
When ready turn your notes into a response to the user

3. Format & output your response as follows:
   - Wrap each section in appropriate tags
   - Use dashes (-) for bullet points
   - Use indentation for sub-points
   - Use ALL CAPS for emphasis, but sparingly
   - DO NOT USE MARKDOWN. reply in plain text only

4. Structure your response using this template:

Key Insights:
- [List 2-3 most important insights from the data]

Recommendations:
- [Recommendation 1]
  - [Specific action to implement recommendation 1]
- [Recommendation 2]
  - [Specific action to implement recommendation 2]

5. Ensure your recommendations are clear, crisp, and directly relevant to improving Google Ads performance. Focus on actionable insights that the user can implement immediately.

6. Keep your response concise and to the point, highlighting only the most important information and actions.
Make it clear to the user at the start of your response that your analysis is for the data on this particular page if that's appropriate. 
If no data was provided (eg for 'scary script challenge' or buy or index pages, just be friendly & answer the question

Remember to maintain a professional tone and focus on providing value through your analysis and recommendations. 
You are Italian, so you'll often end a conversation with Ciao! but not always. Keep the conversation focused on Google Ads & the data provided. 
Don't be drawn into other topics (exceptions are Scripts, AI , Automation, Mike Rhodes - especially events & courses)
Remember, plain text responses only, no markdown
`

export function Fredo({ pageData, selectedCampaign, timeRange }: FredoProps) {
    const location = useLocation()
    const [isOpen, setIsOpen] = useState(false)
    const [messages, setMessages] = useState<Message[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [language, setLanguage] = useState<Language>('en')
    const { stats, updateStats } = useAIUsage()

    // Get page-specific context
    const pageContext = getPageContext(location.pathname, pageData, selectedCampaign, timeRange)

    const handleSendMessage = useCallback(async (content: string) => {
        try {
            setIsLoading(true)
            setMessages(prev => [...prev, { role: 'user', content }])

            // Construct prompt with context
            const contextString = `${getSystemPrompt(language)}

PAGE CONTEXT
===========
Page: ${pageContext.pageDescription}
${pageContext.additionalContext ? `Context: ${pageContext.additionalContext}` : ''}

INTERNAL CONTEXT (For your understanding)
=======================================
${pageContext.internalContext || 'No additional context available.'}

RELEVANT METRICS
==============
${pageContext.relevantMetrics.join(', ')}

CURRENT DATA
===========
${JSON.stringify(pageData, null, 2)}

USER QUESTION
============
${content}
`
            const response = await generateAIResponse(
                'gemini',
                GEMINI_MODEL,
                contextString,
                1000,
                false
            )

            if ('text' in response) {
                setMessages(prev => [...prev, {
                    role: 'assistant',
                    content: response.text
                }])
                updateStats(response.usage.costs.total)
            }
        } catch (error) {
            console.error('Failed to get AI response:', error)
            setMessages(prev => [...prev, {
                role: 'assistant',
                content: 'Sorry, I encountered an error. Please try again.'
            }])
        } finally {
            setIsLoading(false)
        }
    }, [pageContext, pageData, updateStats, language])

    return (
        <>
            <ChatButton
                onClick={() => setIsOpen(true)}
                isOpen={isOpen}
            />
            <ChatWindow
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onSendMessage={handleSendMessage}
                messages={messages}
                isLoading={isLoading}
                suggestedQuestions={pageContext.suggestedQuestions}
                language={language}
                onLanguageChange={setLanguage}
            />
        </>
    )
} 