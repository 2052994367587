// src/components/ai-test/usage-stats.tsx
import { TokenUsage } from '@/types/ai'

interface UsageStatsProps {
    tokenUsage: TokenUsage | null
    timeTaken: number | null
}

export function UsageStats({ tokenUsage, timeTaken }: UsageStatsProps) {
    if (!tokenUsage) return null

    return (
        <div className="flex justify-between items-center text-sm text-muted-foreground">
            <div>Cost: ${tokenUsage.totalCost.toFixed(3)}</div>
            {timeTaken && <div>Time: {timeTaken.toFixed(2)}s</div>}
        </div>
    )
} 