// src/pages/4Cs.tsx
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { screens } from '@/lib/4cs-content';

const Chart = ({ stage = 'check', width = 800, height = 600 }: {
  stage: string;
  width?: number;
  height?: number;
}) => {
  // Placeholder for now - will add actual chart content later
  return (
    <div className="w-full h-full flex items-center justify-center bg-muted rounded-lg">
      <p className="text-2xl text-muted-foreground">Visualization for stage: {stage}</p>
    </div>
  );
};

export default function FourCsPage() {
  const [activeSection, setActiveSection] = useState<string>('check');
  const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    const observers: IntersectionObserver[] = [];

    screens.forEach(section => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setActiveSection(section.id);
            }
          });
        },
        {
          threshold: [0.1, 0.5, 0.9],
          rootMargin: '-20% 0px -20% 0px'
        }
      );

      if (sectionRefs.current[section.id]) {
        observer.observe(sectionRefs.current[section.id]!);
      }
      observers.push(observer);
    });

    return () => {
      observers.forEach(observer => observer.disconnect());
    };
  }, []);

  const activeStage = useMemo(() => {
    const section = screens.find(s => s.id === activeSection);
    return section?.stage || 'check';
  }, [activeSection]);

  return (
    <div className="flex min-h-screen bg-background">
      <div className="w-[70%] sticky top-0 h-screen flex items-center justify-center relative">
        <div className="w-full max-w-5xl p-12">
          <Chart key={activeStage} stage={activeStage} width={800} height={600} />
        </div>
        {activeSection === 'check' && (
          <div className="absolute bottom-24 left-1/2 -translate-x-1/2 flex flex-col items-center animate-bounce">
            <p className="text-muted-foreground mb-2">Scroll to continue</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-muted-foreground"
            >
              <path d="M12 5v14M19 12l-7 7-7-7" />
            </svg>
          </div>
        )}
      </div>

      <div className="w-[30%] border-l">
        {screens.map(section => (
          <div
            key={section.id}
            ref={el => {
              sectionRefs.current[section.id] = el;
            }}
            className="min-h-screen flex items-center"
            id={section.id}
          >
            <div className="p-8">
              <h2 className="text-3xl font-bold mb-6 text-foreground">{section.title}</h2>
              <p className="text-xl leading-relaxed text-muted-foreground">{section.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
} 