// src/pages/trends.tsx
'use client'

import React, { useState, useMemo, useEffect } from 'react'
import { Line, LineChart, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Bar, BarChart, CartesianGrid, ComposedChart } from 'recharts'
import { LineChart as LineChartIcon, BarChart as BarChartIcon, TrendingUp } from 'lucide-react'
import { AlertCircle } from 'lucide-react'
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useCampaignData } from "@/contexts/campaign-data"
import { useVersionCheck } from '@/hooks/use-version-check';
import { VersionGatePopover } from '@/components/version-gate-popover'

import PMaxSection from '@/components/pmax-trends'
import { MetricKey, METRIC_CONFIGS, useMetricFormatter, calculateDerivedMetrics, getMetricColor } from '@/lib/metrics';
import { useAccounts } from '@/contexts/account-context';
import { useUser } from '@clerk/clerk-react';
import { DEBUG, PMAX_CHANNELS } from '@/lib/constants';
import { useNavigate } from 'react-router-dom';
import { hasVotedOnFeature } from '@/lib/user-votes';
import { debounce } from 'lodash';
import { trackEvent, ANALYTICS_EVENTS } from '@/lib/analytics';
import { Button } from "@/components/ui/button"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"
import { cn } from '@/lib/utils'
import { Fredo } from '@/components/fredo-chat'

// Add MinimalCampaign interface since it's not in metrics.ts
interface MinimalCampaign {
  Campaign: string;
  Cost: number;
}

// Update transformMetrics to handle both data formats
const transformMetrics = (row: any) => ({
  Date: new Date(row.date || row.Date).toISOString(),
  Campaign: (row.campaign || row.Campaign || '').replace(/^["'](.+)["']$/, '$1'),
  Impressions: Number(row.Impressions || row.impr || 0),
  Clicks: Number(row.Clicks || row.clicks || 0),
  Cost: Number(row.Cost || row.cost || 0),
  Conversions: Number(row.Conversions || row.conv || 0),
  ConvValue: Number(row.ConvValue || row.value || 0),
});

// Add moving average calculation function after transformMetrics
const calculateMovingAverage = (data: any[], metricKey: string, window: number = 7) => {
  return data.map((item, index) => {
    const start = Math.max(0, index - window + 1);
    const windowSlice = data.slice(start, index + 1);
    const sum = windowSlice.reduce((acc, curr) => acc + (curr[metricKey] || 0), 0);
    return {
      ...item,
      [`${metricKey}_MA`]: sum / windowSlice.length
    };
  });
};

export default function Trends() {
  const { data, loadingStatus, error, useSampleData } = useCampaignData();
  const { activeAccount } = useAccounts();
  const { isSignedIn, user } = useUser();
  const navigate = useNavigate();
  const dailyData = data?.daily || [];

  const [showAllCampaigns, setShowAllCampaigns] = useState(false);
  const [showVersionGate, setShowVersionGate] = useState(false);
  const {
    needsUpdate: needs180Update,
    currentVersion,
    minVersion,
    deleteAccount
  } = useVersionCheck('180-day-trends', 'feature')

  const [selectedCampaign, setSelectedCampaign] = useState<string>('')
  const [selectedMetrics, setSelectedMetrics] = useState<MetricKey[]>(['Cost', 'ConvValue'])
  const [timeRange, setTimeRange] = useState<'7' | '30' | '90' | '180'>('30')
  const [chartType, setChartType] = useState<'line' | 'bar'>('line')
  const [showMovingAverage, setShowMovingAverage] = useState(false);
  const [maWindow, setMaWindow] = useState(7);

  // Get currency from active account
  const accountCurrency = activeAccount?.currency || '$';
  const formatMetric = useMetricFormatter(accountCurrency);

  // Format currency for campaign list
  const formatCurrency = (value: number) =>
    `${accountCurrency}${Math.round(value).toLocaleString()}`;

  // Define available metrics (we'll use this for cards and charts)
  const availableMetrics = useMemo(() =>
    Object.entries(METRIC_CONFIGS)
      .map(([key, config]) => ({
        key: key as keyof typeof METRIC_CONFIGS,
        ...config,
      }))
      .filter(metric => !['ImprShare', 'LostToBudget', 'LostToRank', 'CPC'].includes(metric.key as string)),
    []
  );

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short'
    })
  }

  // Get the latest date from the data
  const latestDate = useMemo(() => {
    if (!dailyData?.length) return new Date();
    return new Date(Math.max(...dailyData.map(row =>
      new Date(row.date || row.Date).getTime()
    )));
  }, [dailyData]);

  // Create Map of PMax campaign data for O(1) lookup
  const pmaxCampaigns = useMemo(() => {
    if (!data?.pmax_perf?.length) {
      if (DEBUG) console.log('No PMax data available')
      return new Map()
    }

    // Group PMax rows by campaign
    const pmaxMap = new Map()
    data.pmax_perf.forEach(row => {
      if (!pmaxMap.has(row.Campaign)) {
        pmaxMap.set(row.Campaign, [])
      }
      pmaxMap.get(row.Campaign).push(row)
    })

    if (DEBUG) {
      console.log('PMax campaigns mapped:', {
        totalPmaxCampaigns: pmaxMap.size,
        campaigns: Array.from(pmaxMap.keys()),
        sampleData: data.pmax_perf[0]
      })
    }

    return pmaxMap
  }, [data?.pmax_perf])

  // Simple check - does this campaign exist in pmax_perf data?
  const isPMaxCampaign = (campaign: string) => {
    if (!data?.pmax_perf?.length || !campaign) {
      if (DEBUG) console.log('No pmax data or campaign');
      return false;
    }

    const isMatch = data.pmax_perf.some(row => {
      const pmaxCampaign = row.campaign || row.Campaign;
      return pmaxCampaign === campaign;
    });

    if (DEBUG) {
      console.log('PMax campaign check:', {
        lookingFor: campaign,
        availableCampaigns: [...new Set(data.pmax_perf.map(row =>
          row.campaign || row.Campaign
        ))],
        isMatch,
        useSampleData
      });
    }

    return isMatch;
  };

  // Get pmax data for selected campaign
  const getPMaxRows = (campaign: string) => {
    if (!data?.pmax_perf?.length || !campaign) return []
    return data.pmax_perf.filter(row => row.Campaign === campaign)
  }

  // Update dateRangeData memo
  const dateRangeData = useMemo(() => {
    if (!selectedCampaign && !showAllCampaigns) return [];


    const daysToSubtract = parseInt(timeRange, 10);
    const startDate = new Date(latestDate);
    startDate.setDate(startDate.getDate() - daysToSubtract);

    // Filter data based on date range first
    const filteredByDate = dailyData.filter((row) => {
      const rowDate = new Date(row.date || row.Date);
      return rowDate >= startDate && rowDate <= latestDate;
    });

    if (showAllCampaigns) {
      // Group by date and sum metrics
      const dateGroups = new Map<string, any>();

      filteredByDate.forEach((row) => {
        const date = row.date || row.Date;

        if (!dateGroups.has(date)) {
          dateGroups.set(date, {
            date,
            Impressions: 0,
            Clicks: 0,
            Cost: 0,
            Conversions: 0,
            ConvValue: 0
          });
        }

        const group = dateGroups.get(date);
        // Sum metrics - check lowercase first since that's our raw data format
        group.Impressions += Number(row.impr || row.Impressions || 0);
        group.Clicks += Number(row.clicks || row.Clicks || 0);
        group.Cost += Number(row.cost || row.Cost || 0);
        group.Conversions += Number(row.conv || 0);
        group.ConvValue += Number(row.value || 0);
      });

      // Convert map to array and sort by date
      return Array.from(dateGroups.values()).sort((a, b) =>
        a.date.localeCompare(b.date)
      );
    }

    // Single campaign mode - filter by campaign and sort by date
    return filteredByDate
      .filter(row => (row.campaign || row.Campaign) === selectedCampaign)
      .sort((a, b) => {
        const dateA = a.date || a.Date;
        const dateB = b.date || b.Date;
        return dateA.localeCompare(dateB);
      });
  }, [dailyData, selectedCampaign, timeRange, latestDate, showAllCampaigns]);

  // Transform filtered data with metrics
  const filteredData = useMemo(() => {
    return dateRangeData.map((row) => {
      // For all campaigns mode, row already has summed metrics
      if (showAllCampaigns) {
        const { date, Impressions, Clicks, Cost, Conversions, ConvValue } = row;
        return {
          Date: new Date(date).toISOString(),
          Campaign: 'All Campaigns',
          Impressions,
          Clicks,
          Cost,
          Conversions,
          ConvValue,
          CTR: Impressions ? (Clicks / Impressions) * 100 : 0,
          CPC: Clicks ? Cost / Clicks : 0,
          CPA: Conversions ? Cost / Conversions : 0,
          ROAS: Cost ? ConvValue / Cost : 0,
          AOV: Conversions ? ConvValue / Conversions : 0,
        };
      }

      // Single campaign mode - transform as before
      const metrics = transformMetrics(row);
      const { Impressions, Clicks, Cost, Conversions, ConvValue } = metrics;

      return {
        ...metrics,
        CTR: Impressions ? (Clicks / Impressions) * 100 : 0,
        CPC: Clicks ? Cost / Clicks : 0,
        CPA: Conversions ? Cost / Conversions : 0,
        ROAS: Cost ? ConvValue / Cost : 0,
        AOV: Conversions ? ConvValue / Conversions : 0,
      };
    });
  }, [dateRangeData, showAllCampaigns]);

  // Add moving average to filtered data
  const filteredDataWithMA = useMemo(() => {
    if (!showMovingAverage) return filteredData;

    let dataWithMA = [...filteredData];
    selectedMetrics.forEach(metric => {
      dataWithMA = calculateMovingAverage(dataWithMA, metric, maWindow);
    });
    return dataWithMA;
  }, [filteredData, selectedMetrics, showMovingAverage, maWindow]);

  // Update debouncedSetTimeRange to use new analytics
  const debouncedSetTimeRange = useMemo(() =>
    debounce((value: string) => {
      // Track time range changes
      trackEvent(ANALYTICS_EVENTS.TRENDS.TIME_RANGE(value));
      setTimeRange(value as '7' | '30' | '90' | '180');
    }, 100)
    , [navigate]);

  // Process campaigns with raw data properties
  const campaigns = useMemo(() => {
    if (!dailyData?.length) return [] as MinimalCampaign[];

    const campaignMap = new Map<string, MinimalCampaign>();

    const daysToSubtract = parseInt(timeRange);
    const startDate = new Date(latestDate);
    startDate.setDate(startDate.getDate() - daysToSubtract);

    dailyData.forEach(row => {
      const rowDate = new Date(row.date || row.Date);
      const campaignName = row.campaign || row.Campaign;

      // Only include data within the selected date range
      if (rowDate >= startDate && rowDate <= latestDate) {
        if (!campaignMap.has(campaignName)) {
          campaignMap.set(campaignName, {
            Campaign: campaignName,
            Cost: 0
          });
        }
        campaignMap.get(campaignName)!.Cost += Number(row.cost || row.Cost || 0);
      }
    });

    const sortedCampaigns = Array.from(campaignMap.values())
      .filter(campaign => campaign.Cost > 0)
      .sort((a, b) => b.Cost - a.Cost);

    return sortedCampaigns;
  }, [dailyData, timeRange, latestDate]);

  // Calculate date range summary
  const dateRangeSummary = useMemo(() => {
    if (!filteredData.length) return 'No dates available'

    const dates = filteredData.map((d) => new Date(d.Date))
    const startDate = new Date(Math.min(...dates.map(d => d.getTime())))
    const endDate = new Date(Math.max(...dates.map(d => d.getTime())))

    return `${startDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    })} to ${endDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    })}`
  }, [filteredData]);

  // Update totals calculation
  const totals = useMemo(() => {
    if (!filteredData?.length) {
      if (DEBUG) console.log('No filtered data for totals');
      return {
        Impressions: 0,
        Clicks: 0,
        Cost: 0,
        Conversions: 0,
        ConvValue: 0,
        CTR: 0,
        CvR: 0,
        ROAS: 0,
        CPA: 0,
        AOV: 0,
      };
    }

    const sums = filteredData.reduce((acc, row) => ({
      Impressions: acc.Impressions + row.Impressions,
      Clicks: acc.Clicks + row.Clicks,
      Cost: acc.Cost + row.Cost,
      Conversions: acc.Conversions + row.Conversions,
      ConvValue: acc.ConvValue + row.ConvValue,
    }), {
      Impressions: 0,
      Clicks: 0,
      Cost: 0,
      Conversions: 0,
      ConvValue: 0,
    });

    return {
      ...sums,
      CTR: sums.Impressions ? (sums.Clicks / sums.Impressions) * 100 : 0,
      CvR: sums.Clicks ? (sums.Conversions / sums.Clicks) * 100 : 0,
      ROAS: sums.Cost ? sums.ConvValue / sums.Cost : 0,
      CPA: sums.Conversions ? sums.Cost / sums.Conversions : 0,
      AOV: sums.Conversions ? sums.ConvValue / sums.Conversions : 0,
    };
  }, [filteredData]);

  // Update toggleMetric to track metric selection
  const toggleMetric = (metricKey: MetricKey) => {
    trackEvent(ANALYTICS_EVENTS.TRENDS.METRIC_TOGGLE(metricKey));
    setSelectedMetrics(current => {
      if (current.includes(metricKey)) {
        return current.filter(m => m !== metricKey)
      }
      if (current.length < 2) {
        return [...current, metricKey]
      }
      return [current[1], metricKey]
    })
  }

  useEffect(() => {
    if (campaigns.length > 0 && !showAllCampaigns) {
      // Always select highest spend campaign when campaigns list changes
      const highestSpendCampaign = campaigns[0];
      if (DEBUG) {
        console.log('Auto-selecting highest cost campaign:', {
          campaign: highestSpendCampaign.Campaign,
          cost: highestSpendCampaign.Cost,
          totalCampaigns: campaigns.length
        });
      }
      setSelectedCampaign(highestSpendCampaign.Campaign);
    } else if (showAllCampaigns) {
      setSelectedCampaign('');
    }
  }, [campaigns, showAllCampaigns]);

  const campaignsAvailable = useMemo(() => {
    if (!dailyData?.length) return 0
    return new Set(dailyData.map(row => row.campaign)).size
  }, [dailyData])

  const hasFilteredData = useMemo(() => {
    const has = selectedCampaign && filteredData.length > 0
    return has
  }, [selectedCampaign, filteredData])

  const isPmax = selectedCampaign ? isPMaxCampaign(selectedCampaign) : false
  const pmaxRows = selectedCampaign ? getPMaxRows(selectedCampaign) : []

  // Early returns for loading/error/no data states
  if (loadingStatus === 'initial' || loadingStatus === 'refresh') {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg">
          {loadingStatus === 'initial'
            ? "Loading your campaign data (this may take a minute or two)..."
            : "Loading..."}
        </div>
      </div>
    );
  }

  if (!dailyData?.length) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-muted-foreground">
          No campaign data available. {useSampleData ?
            'Sample data may be incomplete.' :
            'Please check your data source in Settings.'}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-red-500">Error loading data: {error}</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-6 space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Select
            value={selectedCampaign}
            onValueChange={setSelectedCampaign}
            disabled={showAllCampaigns}
          >
            <SelectTrigger className="h-10 w-[500px]">
              <SelectValue placeholder="Select a campaign" />
            </SelectTrigger>
            <SelectContent>
              {campaigns.map(({ Campaign, Cost }) => (
                <SelectItem
                  key={Campaign}
                  value={Campaign}
                  className="flex items-center justify-between py-2 pr-2"
                >
                  <span className="truncate mr-4">
                    {Campaign}
                  </span>
                  <span className="text-sm font-medium text-muted-foreground">
                    {formatCurrency(Cost)}
                  </span>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <div className="flex items-center space-x-2">
            <Switch
              id="all-campaigns"
              checked={showAllCampaigns}
              onCheckedChange={(checked) => {
                trackEvent(ANALYTICS_EVENTS.TRENDS.VIEW_MODE(checked ? 'all' : 'single'));
                setShowAllCampaigns(checked);
              }}
            />
            <Label htmlFor="all-campaigns">Show All Combined</Label>
          </div>
        </div>
        <div className="flex flex-col items-end gap-1">
          <span className="text-xs text-muted-foreground -mb-1">
            {dateRangeSummary}
          </span>
          <Tabs
            defaultValue="30"
            value={timeRange}
            onValueChange={debouncedSetTimeRange}
          >
            <TabsList className="bg-transparent gap-2">
              <TabsTrigger
                value="7"
                className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                         text-muted-foreground hover:bg-primary/10 transition-colors"
              >
                7 Days
              </TabsTrigger>
              <TabsTrigger
                value="30"
                className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                         text-muted-foreground hover:bg-primary/10 transition-colors"
              >
                30 Days
              </TabsTrigger>
              <TabsTrigger
                value="90"
                className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                         text-muted-foreground hover:bg-primary/10 transition-colors"
              >
                90 Days
              </TabsTrigger>
              <TabsTrigger
                value="180"
                className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
             text-muted-foreground hover:bg-primary/10 transition-colors"
                onClick={(e) => {
                  if (needs180Update) {
                    e.preventDefault();
                    setShowVersionGate(true);
                  }
                }}
              >
                180 Days
              </TabsTrigger>
              <VersionGatePopover
                isOpen={showVersionGate}
                onClose={() => setShowVersionGate(false)}
                label="180 Day History"
                currentVersion={currentVersion}
                minVersion={minVersion}
                onDelete={deleteAccount}
              />
            </TabsList>
          </Tabs>
        </div>
      </div>

      {/* Metric Cards */}
      <div className="grid grid-cols-10 gap-2">
        {availableMetrics.map(metric => (
          <Card
            key={metric.key}
            onClick={() => toggleMetric(metric.key)}
            className={`cursor-pointer transition-colors hover:bg-accent ${selectedMetrics.includes(metric.key) ? 'ring-2 ring-primary' : ''
              }`}
          >
            <CardHeader className="pb-1 pt-2 px-3 space-y-0">
              <CardTitle className="text-xs font-medium">
                {useSampleData && '(Sample) '}{metric.label}
              </CardTitle>
            </CardHeader>
            <CardContent className="pb-2 px-3">
              <div className="text-lg font-bold">
                {formatMetric(metric.key, totals[metric.key] || 0)}
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Main Chart */}
      <div className="space-y-2 mb-8">
        <div className="flex items-center justify-between">
          <CardTitle>
            {useSampleData && '(Sample) '}Campaign Performance Metrics
          </CardTitle>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <Button
                variant={showMovingAverage ? "default" : "outline"}
                size="sm"
                onClick={() => setShowMovingAverage(!showMovingAverage)}
                className="flex items-center gap-2"
              >
                <TrendingUp className="h-4 w-4" />
                {maWindow}d MA
              </Button>
              {showMovingAverage && (
                <Select
                  value={maWindow.toString()}
                  onValueChange={(value) => setMaWindow(Number(value))}
                >
                  <SelectTrigger className="w-[135px] h-8">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="3">3 Days</SelectItem>
                    <SelectItem value="7">7 Days</SelectItem>
                    <SelectItem value="14">14 Days</SelectItem>
                    <SelectItem value="30">30 Days</SelectItem>
                  </SelectContent>
                </Select>
              )}
            </div>
            <Tabs value={chartType} onValueChange={(value) => {
              trackEvent(ANALYTICS_EVENTS.TRENDS.CHART_TYPE(value));
              setChartType(value as 'line' | 'bar');
            }}>
              <TabsList className="grid w-fit grid-cols-2">
                <TabsTrigger value="line" className="px-4">
                  <LineChartIcon className="h-4 w-4 mr-2" />
                  Line
                </TabsTrigger>
                <TabsTrigger value="bar" className="px-4">
                  <BarChartIcon className="h-4 w-4 mr-2" />
                  Bar
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </div>

        <Card>
          <CardContent className="pt-6">
            <div className="h-96">
              <ResponsiveContainer width="100%" height="100%">
                {chartType === 'line' ? (
                  <LineChart data={filteredDataWithMA}>
                    <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
                    <XAxis
                      dataKey="Date"
                      angle={-45}
                      textAnchor="end"
                      height={60}
                      tickFormatter={formatDate}
                    />
                    {selectedMetrics.map((metricKey, index) => {
                      const metric = METRIC_CONFIGS[metricKey]
                      if (!metric) return null

                      return (
                        <React.Fragment key={metricKey}>
                          <YAxis
                            yAxisId={index}
                            orientation={index === 0 ? "left" : "right"}
                            tickFormatter={(value) => formatMetric(metricKey, value)}
                            label={{
                              value: METRIC_CONFIGS[metricKey].label,
                              angle: -90,
                              position: 'insideLeft',
                              style: { textAnchor: 'middle' }
                            }}
                          />
                          <Line
                            type="monotone"
                            dataKey={metricKey}
                            stroke={getMetricColor(index)}
                            yAxisId={index}
                            name={METRIC_CONFIGS[metricKey].label}
                            dot={false}
                            strokeWidth={2}
                            animationDuration={5}
                            strokeOpacity={showMovingAverage ? 0.5 : 1}
                          />
                          {showMovingAverage && (
                            <Line
                              type="monotone"
                              dataKey={`${metricKey}_MA`}
                              stroke={getMetricColor(index)}
                              strokeDasharray="5 5"
                              yAxisId={index}
                              name={`${METRIC_CONFIGS[metricKey].label} (${maWindow}d MA)`}
                              dot={false}
                              strokeWidth={3}
                              animationDuration={5}
                            />
                          )}
                        </React.Fragment>
                      )
                    })}
                    <Tooltip
                      formatter={(value: number, name: string) => {
                        const metricKey = selectedMetrics.find(key =>
                          METRIC_CONFIGS[key].label === name.replace(/ \(\d+d MA\)$/, '')
                        )
                        return [
                          metricKey ? formatMetric(metricKey, value) : value,
                          name
                        ]
                      }}
                      labelFormatter={(label: string) => new Date(label).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric'
                      })}
                    />
                    <Legend />
                  </LineChart>
                ) : (
                  <ComposedChart data={filteredDataWithMA} barSize={20}>
                    <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
                    <XAxis
                      dataKey="Date"
                      angle={-45}
                      textAnchor="end"
                      height={60}
                      tickFormatter={formatDate}
                    />
                    {selectedMetrics.map((metricKey, index) => {
                      const metric = METRIC_CONFIGS[metricKey]
                      if (!metric) return null

                      return (
                        <React.Fragment key={metricKey}>
                          <YAxis
                            yAxisId={index}
                            orientation={index === 0 ? "left" : "right"}
                            tickFormatter={(value) => formatMetric(metricKey, value)}
                            label={{
                              value: METRIC_CONFIGS[metricKey].label,
                              angle: -90,
                              position: 'insideLeft',
                              style: { textAnchor: 'middle' }
                            }}
                          />
                        </React.Fragment>
                      )
                    })}
                    {/* Render all bars first */}
                    {selectedMetrics.map((metricKey, index) => (
                      <Bar
                        key={`bar-${metricKey}`}
                        dataKey={metricKey}
                        fill={getMetricColor(index)}
                        yAxisId={index}
                        name={METRIC_CONFIGS[metricKey].label}
                        radius={[4, 4, 0, 0]}
                        animationDuration={5}
                        fillOpacity={showMovingAverage ? 0.5 : 1}
                      />
                    ))}
                    {/* Then render all lines on top */}
                    {showMovingAverage && selectedMetrics.map((metricKey, index) => (
                      <Line
                        key={`line-${metricKey}`}
                        type="monotone"
                        dataKey={`${metricKey}_MA`}
                        stroke={getMetricColor(index)}
                        strokeDasharray="5 5"
                        yAxisId={index}
                        name={`${METRIC_CONFIGS[metricKey].label} (${maWindow}d MA)`}
                        dot={false}
                        strokeWidth={3}
                        animationDuration={5}
                      />
                    ))}
                    <Tooltip
                      formatter={(value: number, name: string) => {
                        const metricKey = selectedMetrics.find(key =>
                          METRIC_CONFIGS[key].label === name.replace(/ \(\d+d MA\)$/, '')
                        )
                        return [
                          metricKey ? formatMetric(metricKey, value) : value,
                          name
                        ]
                      }}
                      labelFormatter={(label: string) => new Date(label).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric'
                      })}
                    />
                    <Legend />
                  </ComposedChart>
                )}
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* PMax Section */}
      <div className="space-y-2 mt-8">
        {isPmax && data?.pmax_perf && !showAllCampaigns ? (
          <PMaxSection
            pmaxData={pmaxRows}
            selectedCampaign={selectedCampaign}
            timeRange={timeRange}
            latestDate={latestDate}
            currency={accountCurrency}
          />
        ) : (
          <Card className="bg-muted/50">
            <CardContent className="flex flex-col items-center justify-center py-12 text-center">
              <AlertCircle className="h-12 w-12 text-muted-foreground mb-4" />
              <h3 className="text-lg font-medium mb-2">Performance Channel Analysis</h3>
              <p className="text-muted-foreground">
                {showAllCampaigns
                  ? "Channel analysis is not available when viewing all campaigns combined."
                  : "This analysis is only available for Performance Max campaigns. Select a PMax campaign to view detailed channel performance."}
              </p>
            </CardContent>
          </Card>
        )}
      </div>

      <Fredo
        pageData={{
          timeRange,
          selectedCampaign,
          filteredData,
          totals
        }}
      />
    </div >
  )
}