export interface PromptTemplate {
    id: string
    name: string
    description: string
    prompt: string
}

export const PROMPT_TEMPLATES: PromptTemplate[] = [
    {
        id: 'productTitles',
        name: 'Product Title Analysis',
        description: 'Analyzes product titles to identify patterns correlated with profitability',
        prompt: `
Analyze the provided product title data, which is presented in two columns: the product title and its 
assigned 'bucket'. Each product has had reasonable ad spend over the dates analysed & has been 
categorized into either 'profitable' or 'costly' based on its Return on Ad Spend (ROAS) relative to a 
predetermined threshold. Products falling under the ROAS threshold are labeled 'costly', while those 
surpassing it are deemed 'profitable'.

Your task is to scrutinize the titles of these products to identify any recurring patterns, attributes, 
or characteristics that might correlate with their profitability or costliness. Consider aspects such 
as the product type, specific keywords within the titles (e.g., brand names, material, color, size), 
or any other distinguishable features that appear frequently across titles within each bucket.

Key areas to focus on include, but are not limited to:

Commonalities in product titles that might suggest a trend or pattern correlating with their 
profitability or costliness.

Specific words or phrases that are prevalent within profitable or costly product titles. Ignore 
words/phrases that appear in all items (such as the store name).

Attributes such as color, size, or material that frequently occur in the titles of products within the 
same bucket.

Any other notable characteristics observed in the product titles that could influence their 
classification as 'profitable' or 'costly'.

This analysis aims to unearth insights that could help optimize product offerings and marketing 
strategies based on the patterns identified in product titles. Please present your findings in a clear, 
organized manner, highlighting key patterns and insights that emerge from the analysis.

In addition, at the end of your insights, add a section called 'follow up questions', in which you 
write 3-6 additional questions that the user could 'ask of the data' to help them dig deeper with 
their analysis.
    `.trim()
    },
    {
        id: 'landingPages',
        name: 'Landing Page Performance',
        description: 'Analyzes landing page data to identify top/bottom performers and optimization opportunities',
        prompt: `
Analyze the landing page performance data, focusing on the top landing pages (those with the highest 
cost during the period analyzed). Provide a brief overview of key insights from the data, including:

Identify the top-performing landing pages in terms of conversion rate (CVR) and return on ad spend 
(ROAS). Discuss what elements or characteristics of these pages might be contributing to their success.

Highlight the worst-performing landing pages based on CVR and ROAS. Suggest potential improvements or 
changes that could be made to these pages to enhance their performance.

Look for any interesting themes or patterns in the landing page data, such as specific product 
categories, types of offers, or page layouts that seem to be performing particularly well or poorly.

Provide recommendations for optimizing the landing page experience, such as improving page load speed, 
ensuring mobile responsiveness, or streamlining the user journey from ad click to conversion.

If applicable, mention any landing pages that are receiving a high volume of traffic but not 
converting well, as these may represent opportunities for significant improvement.

Present the information in a clear, actionable format that highlights key trends, insights, and 
recommendations. Keep in mind that the user may need to prioritize which landing pages to focus on 
based on the potential impact of improvements.

In addition, at the end of your insights, add a section called 'follow up questions', in which you 
write 3-6 additional questions that the user could 'ask of the data' to help them dig deeper with 
their analysis.
    `.trim()
    },
    {
        id: 'changeHistory',
        name: 'Account Change History',
        description: 'Reviews recent changes made to the Google Ads account',
        prompt: `
Analyze the provided change history data from my Google Ads account and tell me, at a high level, 
what's changed recently across campaigns. Highlight the number and types of changes (e.g., ad, budget, 
keyword), changes by user, and major changes by campaign. Use bullet points and clear summaries for 
easy understanding.
    `.trim()
    },
    {
        id: 'placement',
        name: 'Placement Analysis',
        description: 'Analyzes placement data to identify potential exclusions and performance patterns',
        prompt: `
Analyze the google ads placement data report - these are the top 100 (the ones with highest cost) 
placements spending >1c/day during the period analyzed. Briefly describe key insights from the data, 
Make suggestions about potential exclusions that could be added. Highlight any interesting themes you 
see in the data (eg types of sites such as kids, news etc). Mention which placement type was the 
worst performing and why. Bear in mind the user can't action too much (as these are performance max 
campaigns). But point out that they can use the google exclusion form Found here: 
https://support.google.com/google-ads/contact/pmax_implementation To request exclusions be added to 
the account. Present the information in a clear and actionable way, highlighting key trends and 
insights.
    `.trim()
    },
    {
        id: 'productMatrix',
        name: 'Product Performance Matrix',
        description: 'Analyzes product performance metrics including ROAS, POAS, and Profit',
        prompt: `
Analyze the provided product performance data, including number of products, total cost, conversions, 
conversion value, ROAS, POAS, and Profit. Note that POAS and Profit are calculated using the COGS% 
from the user's 'Advanced' settings.

Identify the top 3-5 most impactful insights, ranked by potential to improve overall performance. For 
each insight, provide a specific, immediately implementable action step. Highlight any surprising or 
counterintuitive findings, explaining their significance.

Compare and contrast the performance of different categories, focusing on ROAS, POAS, and Profit. For 
the top-performing and worst-performing categories, provide brief "if-then" scenarios to guide 
strategy adjustments.

Focus on ROAS, POAS, and Profit as key decision-making metrics. Be concise and direct, optimizing for 
quick understanding and immediate action.

Present your findings in clear, readable paragraphs with appropriate line breaks. Do not use markdown 
formatting.

Conclude with 3-5 follow-up questions, each tied to a potential action step or critical decision 
point, to guide deeper analysis.
    `.trim()
    },
    {
        id: 'tNgrams',
        name: 'Product Title nGram Analysis',
        description: 'Analyzes word/phrase patterns in product titles using nGram data',
        prompt: `
Analyze the nGram data extracted from product titles in the swimwear dataset, focusing on patterns and 
phrases that correlate with high-performing metrics like conversions and ROAS. Begin with a brief 
executive summary of overall category performance. Then, provide a prioritized list of the top 5 most 
significant insights based on the following rules:

1. High Cost, Low Conversion Rate: Cost > 90th percentile, Conversion Rate < 20th percentile
2. Low Cost, High Conversion Rate: Cost < 50th percentile, Conversion Rate > 80th percentile
3. High CTR, Low Conversion Rate: CTR > 80th percentile, Conversion Rate < 20th percentile
4. Low CTR, High Conversion Rate: CTR < 20th percentile, Conversion Rate > 80th percentile
5. High ROAS, Low Impression Volume: ROAS > 80th percentile, Impressions < 20th percentile
6. Low ROAS, High Impression Volume: ROAS < 20th percentile, Impressions > 80th percentile
7. Significant Performance Changes: Metrics change > 1.5 times standard deviation over time
8. Performance Outliers: Metrics > 2 times standard deviation from mean
9. High AOV, Low Conversion Rate: AOV > 90th percentile, Conversion Rate < 20th percentile
10. Consistent High Performers: ROAS, CTR, and Conversion Rate all > 70th percentile

For each insight, explain its implications and provide a specific, actionable recommendation.

Conclude with a ranked list of 3-5 follow-up questions an analyst should ask to uncover additional 
insights for optimizing product listings, marketing strategies, and overall business performance.
    `.trim()
    },
    {
        id: 'sNgrams',
        name: 'Search Term nGram Analysis',
        description: 'Analyzes word/phrase patterns in search terms using nGram data',
        prompt: `
Analyze the provided nGram data from search terms, focusing on clicks, conversions, and AOV. 
Identify the top 3-5 most impactful patterns or trends that could significantly affect business 
performance.

For each key insight:
1. Provide specific quantitative data to support your observation.
2. Explain the potential business impact.
3. Offer a concrete, actionable recommendation.

Prioritize your insights based on their potential to drive immediate improvements in performance. Be 
concise and direct - imagine you have only 2 minutes to present your findings to a busy executive.

Conclude with 2-3 follow-up questions that could lead to deeper, more valuable insights if explored 
further.

Use English (UK) and provide text output only, no charts. Do not use currency symbols or conversions, 
and do not round numbers.
    `.trim()
    },
    {
        id: 'asset',
        name: 'Asset Performance Analysis',
        description: 'Analyzes performance of different asset types (display, video, headline, description)',
        prompt: `
Please analyze the provided data for all asset types: display, video, headline, and description. 
Identify the most effective creatives based on the metrics you're given. Summarize key insights and 
suggest potential areas for improvement to optimize the performance of each asset type.

The data is provided with the following metrics:
- Impressions (impr)
- Clicks
- Views (for video)
- Cost
- Conversions (conv)
- Conversion Value (value)
- CTR (click-through rate)
- CVR (conversion rate)
- AOV (average order value)
- ROAS (return on ad spend)
- CPA (cost per acquisition)

Based on this data, perform the following tasks:

1. Identify Top-Performing Creatives:
   - Determine highest performance metrics for each asset type
   - Highlight creatives with best engagement and conversion metrics

2. Summarize Key Insights:
   - Provide summary of top performers and key metrics
   - Highlight trends across asset types

3. Suggest Areas for Improvement:
   - Identify underperforming creatives
   - Provide actionable recommendations for optimization

Add a 'follow up questions' section with 3-6 questions for deeper analysis.
    `.trim()
    }
] 