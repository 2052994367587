// src/pages/sim.tsx
import React, { useState, useRef, useEffect } from 'react'
import { useAccounts } from '@/contexts/account-context'
import { useUser } from '@clerk/clerk-react'
import { AlertTriangle, HelpCircle, Loader2, Rocket, Upload } from 'lucide-react'
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog"
import { Label } from '@/components/ui/label'
import { Switch } from "@/components/ui/switch"
import { useToast } from '@/components/ui/use-toast'
import { analyzeScreenshot, type SimulatorMetrics } from '@/lib/openai-service'
import { DEBUG } from '@/lib/env'

interface SimulationResults {
  currentSpend: number
  currentBudget: number
  currentConversions: number
  proposedChange: number
  recommendedBudget: number
  marginalCPA: number
  marginalROAS: number
  profitChange: number
  googleRecommendation: string
  isRecommendationGood: boolean
  confidence: 'high' | 'fairly high' | 'moderate' | 'low'
  optimizationType: 'conversion_value' | 'conversions'
  debugInfo?: {
    rawResponse: string
    usage: {
      costs: {
        total: number
      }
    }
    calculations?: {
      marginalMetrics?: {
        spend: number
        conversions: number
        convValue: number | 'N/A'
      }
      currentMetrics?: {
        spend: number
        budget: number
        conversions: number
        convValue: number | 'N/A'
        roas: number | 'N/A'
        cpa: number | 'N/A'
      }
      proposedMetrics?: {
        spend: number
        budget: number
        conversions: number
        convValue: number | 'N/A'
        roas: number | 'N/A'
        cpa: number | 'N/A'
        averageCPA?: number | 'N/A'
        averageROAS?: number | 'N/A'
      }
      profitCalculation?: {
        currentProfit: number
        proposedProfit: number
        profitChange: number
        settings?: {
          optimizationType: 'conversion_value' | 'conversions'
          breakEvenMetric: number
          optimizationMode: 'profit' | 'growth'
        }
      }
      confidence?: {
        score: 'high' | 'fairly high' | 'moderate' | 'low'
        reasons: string[]
      }
    }
  }
}

function calculateProfit(spend: number, conversions: number, type: 'conversion_value' | 'conversions', breakEvenMetric: number): number {
  if (type === 'conversion_value') {
    // For conversion value: profit = revenue - cost - (revenue * COGS%)
    const revenue = spend * conversions;
    return revenue - spend - (revenue * (breakEvenMetric / 100));
  } else {
    // For conversions: profit = (breakeven CPA * conversions) - spend
    return (breakEvenMetric * conversions) - spend;
  }
}

function calculateTargetMetric(results: SimulationResults, mode: 'profit' | 'growth', breakEvenMetric: number): string {
  if (mode === 'profit') {
    const currentProfit = calculateProfit(
      results.currentSpend,
      results.currentConversions,
      results.marginalROAS > 0 ? 'conversion_value' : 'conversions',
      breakEvenMetric
    );
    return `$${currentProfit.toFixed(2)} ±5%`;
  } else {
    // Growth mode - show break-even metric
    return results.marginalROAS > 0
      ? `ROAS ${(1 / (1 - breakEvenMetric / 100)).toFixed(1)}x`
      : `CPA $${breakEvenMetric}`;
  }
}

function ComingSoonPage({ onShowDev }: { onShowDev?: () => void }) {
  return (
    <div className="container mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            Budget Simulator Analysis
            <Rocket className="h-4 w-4 text-muted-foreground" />
          </CardTitle>
          <CardDescription>
            A smarter way to evaluate Google's budget recommendations
          </CardDescription>
        </CardHeader>
        <CardContent>
          {DEBUG && onShowDev && (
            <div className="mb-6 flex items-center space-x-2">
              <Switch
                checked={false}
                onCheckedChange={onShowDev}
              />
              <Label>Show development version</Label>
            </div>
          )}

          <Alert>
            <Rocket className="h-4 w-4" />
            <AlertTitle>Coming Soon!</AlertTitle>
            <AlertDescription>
              I'm excited to show you this soon - a tool that will help you make smarter budget decisions
              by analyzing Google's recommendations against your actual business metrics.
              <div className="mt-4 text-sm text-muted-foreground">
                This new tool will help you:
                <ul className="list-disc list-inside mt-2 space-y-1">
                  <li>Analyze budget simulator recommendations</li>
                  <li>Calculate true marginal performance</li>
                  <li>Make data-driven budget decisions</li>
                  <li>Optimize for your profitability & growth</li>
                </ul>
              </div>
            </AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    </div>
  )
}

interface ErrorDialogState {
  title: string
  message: string
  show: boolean
}

function DevSimPage({ onHideDev }: { onHideDev: () => void }) {
  const { activeAccount } = useAccounts()
  const { user } = useUser()
  const { toast } = useToast()
  const [simResults, setSimResults] = useState<SimulationResults | null>(null)
  const [isAnalyzing, setIsAnalyzing] = useState(false)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const dropZoneRef = useRef<HTMLDivElement>(null)
  const [rawMetrics, setRawMetrics] = useState<SimulatorMetrics | null>(null)
  const [optimizationType, setOptimizationType] = useState<'conversion_value' | 'conversions'>('conversions')
  const [optimizationMode, setOptimizationMode] = useState<'profit' | 'growth'>('profit')
  const [localBusinessMode, setLocalBusinessMode] = useState<'ecomm' | 'leadgen'>(
    activeAccount?.businessMode === 'leadgen' ? 'leadgen' : 'ecomm'
  )
  const [breakEvenMetric, setBreakEvenMetric] = useState(
    activeAccount?.businessMode === 'ecomm'
      ? activeAccount.cogsAmount ?? 50
      : activeAccount.breakEvenCpa ?? 25
  )
  const [apiUsage, setApiUsage] = useState<any>(null)
  const [errorDialog, setErrorDialog] = useState<ErrorDialogState>({
    title: '',
    message: '',
    show: false
  });

  // Add constants for public URLs
  const PUBLIC_URLS = {
    budgetSimIcon: '/budget-sim-icon.png',
    simImage: '/sim-image.png'
  }

  // Helper to determine confidence level
  const determineConfidence = (metrics: SimulatorMetrics): {
    score: 'high' | 'fairly high' | 'moderate' | 'low',
    reasons: string[]
  } => {
    const reasons: string[] = []
    let score: 'high' | 'fairly high' | 'moderate' | 'low' = 'high'

    // Check for conversion value presence
    if (!metrics.current.convValue && !metrics.proposed.convValue) {
      reasons.push("No conversion value data available")
      score = 'moderate'
    }

    // Check for significant changes
    const spendChange = Math.abs((metrics.proposed.spend - metrics.current.spend) / metrics.current.spend)
    if (spendChange > 0.5) {
      reasons.push("Large spend change (>50%) increases uncertainty")
      score = score === 'high' ? 'fairly high' : 'moderate'
    }

    // Check for conversion rate consistency
    const currentCvr = metrics.current.conversions / metrics.current.spend
    const proposedCvr = metrics.proposed.conversions / metrics.proposed.spend
    const cvrChange = Math.abs(proposedCvr - currentCvr) / currentCvr
    if (cvrChange > 0.2) {
      reasons.push("Significant conversion rate change in projection")
      score = 'low'
    }

    // Add positive confidence reasons too
    if (metrics.current.conversions > 100) {
      reasons.push("Strong baseline conversion volume")
    }
    if (cvrChange < 0.1) {
      reasons.push("Consistent conversion rates in projection")
    }

    return { score, reasons }
  }

  const updateResults = (metrics: SimulatorMetrics, usage: any) => {
    // Determine optimization type from metrics
    const hasConvValue = Boolean(metrics.current.convValue || metrics.proposed.convValue)
    const newOptimizationType = hasConvValue ? 'conversion_value' : 'conversions'
    setOptimizationType(newOptimizationType)

    // Calculate confidence
    const confidence = determineConfidence(metrics)

    // Calculate current profit for comparison
    const currentProfit = calculateProfit(
      metrics.current.spend,
      metrics.current.conversions,
      hasConvValue ? 'conversion_value' : 'conversions',
      breakEvenMetric
    )

    const results: SimulationResults = {
      currentSpend: metrics.current.spend,
      currentBudget: metrics.current.budget,
      currentConversions: metrics.current.conversions,
      proposedChange: metrics.proposed.spend - metrics.current.spend,
      recommendedBudget: metrics.proposed.budget,
      marginalCPA: (metrics.proposed.spend - metrics.current.spend) / (metrics.proposed.conversions - metrics.current.conversions),
      marginalROAS: metrics.proposed.convValue && metrics.current.convValue
        ? (metrics.proposed.convValue - metrics.current.convValue) / (metrics.proposed.spend - metrics.current.spend)
        : 0,
      profitChange: calculateProfit(
        metrics.proposed.spend,
        metrics.proposed.conversions,
        hasConvValue ? 'conversion_value' : 'conversions',
        breakEvenMetric
      ) - currentProfit,
      googleRecommendation: generateRecommendation(
        hasConvValue ? 'conversion_value' : 'conversions',
        optimizationMode,
        currentProfit,
        calculateProfit(
          metrics.proposed.spend,
          metrics.proposed.conversions,
          hasConvValue ? 'conversion_value' : 'conversions',
          breakEvenMetric
        ),
        (metrics.proposed.convValue - metrics.current.convValue) / (metrics.proposed.spend - metrics.current.spend),
        breakEvenMetric,
        metrics.currency,
        metrics
      ),
      isRecommendationGood: false,
      confidence: confidence.score,
      optimizationType: newOptimizationType,
      debugInfo: {
        rawResponse: JSON.stringify(metrics, null, 2),
        usage,
        calculations: {
          marginalMetrics: {
            spend: metrics.proposed.spend - metrics.current.spend,
            conversions: metrics.proposed.conversions - metrics.current.conversions,
            convValue: metrics.proposed.convValue && metrics.current.convValue
              ? metrics.proposed.convValue - metrics.current.convValue
              : 'N/A',
          },
          currentMetrics: {
            spend: metrics.current.spend,
            budget: metrics.current.budget,
            conversions: metrics.current.conversions,
            convValue: metrics.current.convValue || 'N/A',
            roas: metrics.current.roas || (metrics.current.convValue ? metrics.current.convValue / metrics.current.spend : 'N/A'),
            cpa: (metrics.current.conversions ? metrics.current.spend / metrics.current.conversions : 'N/A')
          },
          proposedMetrics: {
            spend: metrics.proposed.spend,
            budget: metrics.proposed.budget,
            conversions: metrics.proposed.conversions,
            convValue: metrics.proposed.convValue || 'N/A',
            roas: metrics.proposed.roas || (metrics.proposed.convValue ? metrics.proposed.convValue / metrics.proposed.spend : 'N/A'),
            cpa: (metrics.proposed.conversions ? metrics.proposed.spend / metrics.proposed.conversions : 'N/A')
          },
          profitCalculation: {
            currentProfit,
            proposedProfit: calculateProfit(
              metrics.proposed.spend,
              metrics.proposed.conversions,
              hasConvValue ? 'conversion_value' : 'conversions',
              breakEvenMetric
            ),
            profitChange: calculateProfit(
              metrics.proposed.spend,
              metrics.proposed.conversions,
              hasConvValue ? 'conversion_value' : 'conversions',
              breakEvenMetric
            ) - currentProfit,
            settings: {
              optimizationType: hasConvValue ? 'conversion_value' : 'conversions',
              breakEvenMetric,
              optimizationMode
            }
          },
          confidence
        }
      }
    }

    // Set recommendation goodness based on optimization mode
    results.isRecommendationGood = optimizationMode === 'profit'
      ? results.profitChange > -currentProfit * 0.05  // Within 5% of current profit
      : hasConvValue
        ? results.marginalROAS >= (1 / (1 - breakEvenMetric / 100))  // Exceeds break-even ROAS
        : (metrics.proposed.spend / metrics.proposed.conversions) <= breakEvenMetric  // Proposed average CPA below break-even

    // Add average metrics to debug info for transparency
    if (results.debugInfo?.calculations?.proposedMetrics) {
      results.debugInfo.calculations.proposedMetrics.averageCPA =
        metrics.proposed.conversions > 0 ? metrics.proposed.spend / metrics.proposed.conversions : 'N/A'
      results.debugInfo.calculations.proposedMetrics.averageROAS =
        metrics.proposed.spend > 0 && metrics.proposed.convValue ? metrics.proposed.convValue / metrics.proposed.spend : 'N/A'
    }

    setSimResults(results)
  }

  const showError = (title: string, message: string) => {
    setErrorDialog({
      title,
      message,
      show: true
    });
    // Clear preview and reset state
    setPreviewUrl(null);
    setRawMetrics(null);
    setSimResults(null);
  };

  const processImage = async (file: File) => {
    try {
      // Clear all previous analysis state
      setSimResults(null)
      setRawMetrics(null)
      setApiUsage(null)
      setPreviewUrl(null)
      setErrorDialog({ title: '', message: '', show: false })

      setIsAnalyzing(true)

      // Create preview
      const reader = new FileReader()
      reader.onload = () => {
        setPreviewUrl(reader.result as string)
      }
      reader.readAsDataURL(file)

      // Call OpenAI API for analysis
      const analysis = await analyzeScreenshot(file)
      const metrics = analysis.rawResponse as SimulatorMetrics

      // Simplified validation - just check for basic required fields
      const hasRequiredFields =
        metrics.current?.spend !== undefined &&
        metrics.proposed?.spend !== undefined;

      if (!hasRequiredFields) {
        showError(
          "Missing Required Data",
          "We couldn't find the basic cost data in the screenshot. Please ensure the simulator table is clearly visible."
        );
        return;
      }

      // Continue with analysis even if there are warnings
      const validation = validateSimulatorData(metrics);
      if (validation.warnings.length > 0) {
        // Show warnings as toast notifications instead of blocking
        validation.warnings.forEach(warning => {
          toast({
            title: "Analysis Note",
            description: warning,
            variant: "default"
          });
        });
      }

      // Continue with the rest of the analysis...
      setRawMetrics(metrics);
      setApiUsage(analysis.usage);
      updateResults(metrics, analysis.usage);

    } catch (err) {
      console.error('Failed to analyze image:', err);
      showError(
        "Analysis Failed",
        "Something went wrong while analyzing the screenshot. Please try again."
      );
    } finally {
      setIsAnalyzing(false);
    }
  }

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return

    if (!file.type.startsWith('image/')) {
      toast({
        variant: "destructive",
        title: "Invalid File",
        description: "Please upload an image file"
      })
      return
    }

    await processImage(file)
  }

  const handlePaste = async (e: ClipboardEvent): Promise<void> => {
    const items = e.clipboardData?.items
    if (!items) return

    for (const item of items) {
      if (item.type.startsWith('image/')) {
        const file = item.getAsFile()
        if (file) {
          await processImage(file)
          break
        }
      }
    }
  }

  const handleDrop = async (e: React.DragEvent): Promise<void> => {
    e.preventDefault()

    const file = e.dataTransfer?.files[0]
    if (!file || !file.type.startsWith('image/')) {
      toast({
        variant: "destructive",
        title: "Invalid File",
        description: "Please drop an image file"
      })
      return
    }

    await processImage(file)
  }

  const handleDragOver = (e: React.DragEvent): void => {
    e.preventDefault()
  }

  useEffect(() => {
    const handlePasteEvent = (e: ClipboardEvent) => handlePaste(e)
    const handleDropEvent = (e: DragEvent) => handleDrop(e as unknown as React.DragEvent)
    const handleDragOverEvent = (e: DragEvent) => handleDragOver(e as unknown as React.DragEvent)

    window.addEventListener('paste', handlePasteEvent)

    const dropZone = dropZoneRef.current
    if (dropZone) {
      dropZone.addEventListener('drop', handleDropEvent)
      dropZone.addEventListener('dragover', handleDragOverEvent)
    }

    return () => {
      window.removeEventListener('paste', handlePasteEvent)
      if (dropZone) {
        dropZone.removeEventListener('drop', handleDropEvent)
        dropZone.removeEventListener('dragover', handleDragOverEvent)
      }
    }
  }, [])

  // Add helper for recommendation text
  const generateRecommendation = (
    type: 'conversion_value' | 'conversions',
    goal: 'profit' | 'growth',
    currentProfit: number,
    proposedProfit: number,
    marginalRoas: number,
    metric: number,
    currency: string,
    metrics: SimulatorMetrics
  ): string => {
    const profitChange = proposedProfit - currentProfit
    const profitPercent = (profitChange / Math.abs(currentProfit)) * 100

    if (goal === 'profit') {
      if (profitChange > currentProfit * 0.05) {
        return `Strong yes - this will increase profit by ${currency}${profitChange.toFixed(2)} (${profitPercent.toFixed(1)}%)`
      } else if (profitChange > -currentProfit * 0.05) {
        return `Yes - profit impact is within acceptable range (${profitPercent.toFixed(1)}%)`
      } else {
        return `No - this will reduce profit by ${currency}${(-profitChange).toFixed(2)} (${-profitPercent.toFixed(1)}%)`
      }
    } else {
      // For growth mode, we look at average metrics, not marginal
      if (type === 'conversion_value') {
        const proposedRoas = metrics.proposed.convValue / metrics.proposed.spend
        const breakEvenRoas = 1 / (1 - metric / 100)
        if (proposedRoas >= breakEvenRoas) {
          return `Yes - projected ROAS (${proposedRoas.toFixed(1)}x) exceeds break-even (${breakEvenRoas.toFixed(1)}x)`
        } else {
          return `No - projected ROAS (${proposedRoas.toFixed(1)}x) below break-even (${breakEvenRoas.toFixed(1)}x)`
        }
      } else {
        const proposedCpa = metrics.proposed.spend / metrics.proposed.conversions
        if (proposedCpa <= metric) {
          return `Yes - projected CPA (${currency}${proposedCpa.toFixed(2)}) below break-even (${currency}${metric})`
        } else {
          return `No - projected CPA (${currency}${proposedCpa.toFixed(2)}) exceeds break-even (${currency}${metric})`
        }
      }
    }
  }

  const renderIncrementalMetrics = () => {
    if (!rawMetrics) return null;

    const deltaSpend = rawMetrics.proposed.spend - rawMetrics.current.spend;
    const deltaConv = rawMetrics.proposed.conversions - rawMetrics.current.conversions;
    const deltaValue = (rawMetrics.proposed.convValue || 0) - (rawMetrics.current.convValue || 0);
    const incrementalROAS = deltaValue / deltaSpend;

    return (
      <div className="mt-4 pt-4 border-t">
        <h4 className="text-base font-medium mb-2">Incremental Performance:</h4>
        <div className="grid grid-cols-2 gap-4">
          <div className="p-3 bg-background rounded-lg">
            <div className="text-sm text-muted-foreground">Additional Daily Cost</div>
            <div className="text-lg font-semibold">${deltaSpend.toFixed(2)}</div>
            {rawMetrics.analysis.budgetInconsistency && (
              <div className="text-xs text-yellow-600 mt-1">
                Note: Budget recommendation seems inconsistent with spend projections
              </div>
            )}
          </div>
          <div className="p-3 bg-background rounded-lg">
            <div className="text-sm text-muted-foreground">
              {rawMetrics.current.convValue ? 'Additional Daily Revenue' : 'Additional Daily Conversions'}
            </div>
            <div className="text-lg font-semibold">
              {rawMetrics.current.convValue
                ? `$${deltaValue.toFixed(2)}`
                : deltaConv.toFixed(1)}
            </div>
            <div className="text-xs text-muted-foreground mt-1">
              {rawMetrics.current.convValue
                ? `Incremental ROAS: ${incrementalROAS.toFixed(1)}x`
                : `Incremental CPA: $${(deltaSpend / deltaConv).toFixed(2)}`}
            </div>
          </div>
        </div>

        {/* Add warning for poor incremental performance */}
        {rawMetrics.current.convValue && incrementalROAS < 1 && (
          <Alert variant="warning" className="mt-4">
            <AlertTriangle className="h-4 w-4" />
            <AlertTitle>Low Incremental ROAS</AlertTitle>
            <AlertDescription>
              This change would generate only ${deltaValue.toFixed(2)} in additional revenue
              for ${deltaSpend.toFixed(2)} in extra spend (${incrementalROAS.toFixed(1)}x ROAS).
              This is significantly lower than your current ROAS of {rawMetrics.current.roas?.toFixed(1)}x.
            </AlertDescription>
          </Alert>
        )}
      </div>
    );
  };

  const validateBreakEvenMetrics = () => {
    if (!rawMetrics || !breakEvenMetric) return null;

    if (rawMetrics.current.convValue) {
      const avgOrderValue = rawMetrics.current.convValue / rawMetrics.current.conversions;
      const impliedBreakeven = (breakEvenMetric / 100) * avgOrderValue;

      if (Math.abs(impliedBreakeven - rawMetrics.current.spend / rawMetrics.current.conversions) > avgOrderValue * 0.5) {
        return (
          <Alert variant="warning" className="mt-4">
            <AlertTriangle className="h-4 w-4" />
            <AlertTitle>Break-even COGS Seems Unusual</AlertTitle>
            <AlertDescription>
              Your COGS of {breakEvenMetric}% implies a break-even CPA of ${impliedBreakeven.toFixed(2)},
              but your current CPA is ${(rawMetrics.current.spend / rawMetrics.current.conversions).toFixed(2)}.
              You may want to review your COGS percentage.
            </AlertDescription>
          </Alert>
        );
      }
    }

    return null;
  };

  const validateSimulatorData = (metrics: SimulatorMetrics): {
    isValid: boolean;
    warnings: string[];
  } => {
    const warnings = [];

    // Check for budget vs spend inconsistency but be more lenient
    const budgetDelta = metrics.proposed.budget - metrics.current.budget;
    const spendDelta = metrics.proposed.spend - metrics.current.spend;
    if (Math.abs(budgetDelta - spendDelta) > spendDelta * 0.5) { // More lenient threshold
      warnings.push('Note: Budget recommendations differ from projected spend changes');
      metrics.analysis.budgetInconsistency = true;
    }

    // Only warn about conversion rate changes if they're extremely different
    if (metrics.current.convValue && metrics.current.clicks && metrics.proposed.clicks) {
      const currentCvr = metrics.current.conversions / metrics.current.clicks;
      const proposedCvr = metrics.proposed.conversions / metrics.proposed.clicks;
      if (Math.abs(proposedCvr - currentCvr) > currentCvr * 0.5) { // More lenient threshold
        warnings.push('Note: Significant changes in conversion rates projected');
      }
    }

    // Always return valid=true, just use warnings to inform
    return {
      isValid: true,
      warnings
    };
  };

  // First, add a new function to handle re-running analysis
  const rerunAnalysis = () => {
    if (!rawMetrics || !apiUsage) return;
    updateResults(rawMetrics, apiUsage);
    toast({
      title: "Analysis Updated",
      description: "Results recalculated with new settings"
    });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            Budget Simulator Analysis
            <HelpCircle className="h-4 w-4 text-muted-foreground" />
          </CardTitle>
          <CardDescription>
            Upload or paste a screenshot of Google's simulator tool and we'll analyze if their recommendation makes sense for your business
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="mb-6 flex items-center space-x-2">
            <Switch
              checked={true}
              onCheckedChange={onHideDev}
            />
            <Label>Show development version</Label>
          </div>

          <div className="space-y-6">
            {/* Initial Settings and Upload Row */}
            <div className="grid grid-cols-2 gap-6">
              {/* Default Settings - Now First */}
              <div>
                <h3 className="text-lg font-semibold mb-4">1. Set Your Business Metrics</h3>
                <div className="p-6 bg-muted rounded-lg space-y-6">
                  <div>
                    <div className="flex items-center justify-between mb-2">
                      <Label className="text-base font-medium">
                        {localBusinessMode === 'ecomm' ? 'Cost of Goods Sold (COGS)' : 'Target Cost per Acquisition'}
                      </Label>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => {
                          const newMode = localBusinessMode === 'ecomm' ? 'leadgen' : 'ecomm'
                          setLocalBusinessMode(newMode)
                          setBreakEvenMetric(newMode === 'ecomm' ? 50 : 25)
                        }}
                        className="text-xs"
                      >
                        Switch to {localBusinessMode === 'ecomm' ? 'Lead Gen' : 'E-commerce'}
                      </Button>
                    </div>
                    <div className="mb-2">
                      <div className="flex items-center gap-2">
                        <span className="text-xl font-medium text-muted-foreground">
                          {localBusinessMode === 'ecomm' ? '%' : '$'}
                        </span>
                        <input
                          type="text"
                          inputMode="decimal"
                          className="text-3xl font-semibold w-32 rounded-md border p-2 bg-background"
                          value={breakEvenMetric}
                          onChange={(e) => {
                            const value = e.target.value.replace(/^0+/, '')
                            if (value === '') {
                              setBreakEvenMetric(0)
                            } else {
                              const num = parseFloat(value)
                              if (!isNaN(num)) {
                                setBreakEvenMetric(num)
                              }
                            }
                          }}
                          onFocus={(e) => e.target.select()}
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                      </div>
                    </div>
                    <p className="text-sm text-muted-foreground">
                      {localBusinessMode === 'ecomm' ? (
                        <>
                          Enter your COGS as a percentage of revenue. This helps calculate true profit by accounting for product costs.<br />
                          <span className="mt-1 block">Example: If your products cost 50% of their selling price, enter 50.</span>
                        </>
                      ) : (
                        <>
                          Enter your target cost per conversion. This is the maximum you're willing to pay for a lead/sale.<br />
                          <span className="mt-1 block">Example: If a lead is worth $50 to you, enter 50.</span>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </div>

              {/* Upload Section - Now Second */}
              <div>
                <h3 className="text-lg font-semibold mb-4">2. Upload Budget Simulator Screenshot</h3>
                <div
                  ref={dropZoneRef}
                  className={`flex flex-col items-center justify-center p-8 border-2 border-dashed rounded-lg bg-muted/50 relative ${simResults ? 'border-muted' : 'border-primary/20'} hover:border-primary/50 transition-colors`}
                >
                  {isAnalyzing ? (
                    <div className="flex flex-col items-center gap-4">
                      <Loader2 className="h-8 w-8 animate-spin" />
                      <p className="text-sm text-muted-foreground">Analyzing screenshot...</p>
                    </div>
                  ) : (
                    <>
                      <Button
                        variant="outline"
                        size="lg"
                        className="mb-4"
                        onClick={() => fileInputRef.current?.click()}
                        disabled={isAnalyzing}
                      >
                        <Upload className="h-4 w-4 mr-2" />
                        {simResults ? 'Upload New Screenshot' : 'Upload Screenshot'}
                        <input
                          ref={fileInputRef}
                          type="file"
                          className="hidden"
                          accept="image/*"
                          onChange={handleImageUpload}
                        />
                      </Button>
                      {!simResults && (
                        <div className="text-center">
                          <p className="text-sm text-muted-foreground mb-2">
                            Drop an image here or paste from clipboard
                          </p>
                          <p className="text-xs text-muted-foreground">
                            Take a screenshot of your Google Ads budget simulator
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {previewUrl && (
                  <div className="mt-4">
                    <h4 className="text-sm font-medium mb-2">Uploaded Screenshot:</h4>
                    <img
                      src={previewUrl}
                      alt="Uploaded Screenshot"
                      className="w-full rounded-lg border"
                    />
                  </div>
                )}
              </div>
            </div>

            {simResults && (
              <>
                {/* Results Section */}
                <div className="mt-6 border-t pt-4">
                  <h3 className="font-semibold mb-4">Analysis Results</h3>

                  {/* Main Explanation Card */}
                  <div className="mb-6 p-6 bg-muted rounded-lg space-y-4">
                    <div className="flex items-center justify-between">
                      <h4 className="text-xl font-semibold">What Google Is Suggesting</h4>
                      <div className={`text-sm px-3 py-1 rounded-full ${simResults.confidence === 'high' ? 'bg-green-100 text-green-800' :
                        simResults.confidence === 'fairly high' ? 'bg-blue-100 text-blue-800' :
                          simResults.confidence === 'moderate' ? 'bg-yellow-100 text-yellow-800' :
                            'bg-red-100 text-red-800'}`}>
                        {simResults.confidence} confidence
                      </div>
                    </div>

                    {/* Simple Explanation */}
                    <div className="text-lg space-y-2">
                      <p>
                        Increasing daily cost to ${rawMetrics?.proposed.spend.toFixed(2)} would result in:
                      </p>
                      <ul className="list-disc list-inside ml-2 space-y-1">
                        <li>Additional daily spend: ${simResults.proposedChange.toFixed(2)}</li>
                        <li>
                          {rawMetrics?.current.convValue ? (
                            <>
                              Revenue increase: ${((rawMetrics.proposed.convValue || 0) - (rawMetrics.current.convValue || 0)).toFixed(2)} per day
                              {rawMetrics.analysis.hasValidRoas && (
                                <span className="text-muted-foreground"> (ROAS: {rawMetrics.proposed.roas?.toFixed(1)}x)</span>
                              )}
                            </>
                          ) : (
                            <>
                              Additional conversions: {(rawMetrics?.proposed.conversions || 0) - (rawMetrics?.current.conversions || 0)} per day
                              <span className="text-muted-foreground"> (CPA: ${simResults.marginalCPA.toFixed(2)})</span>
                            </>
                          )}
                        </li>
                      </ul>
                    </div>

                    {/* Profit Impact - if we have COGS/CPA set */}
                    {breakEvenMetric > 0 && (
                      <div className="mt-4 pt-4 border-t">
                        <p className="text-base mb-2">
                          Based on your {localBusinessMode === 'ecomm' ? `COGS of ${breakEvenMetric}%` : `target CPA of $${breakEvenMetric}`}:
                        </p>
                        <div className="flex items-center gap-2">
                          <div className="flex-1 p-3 bg-background rounded-lg">
                            <div className="text-sm text-muted-foreground">Current Daily Profit</div>
                            <div className="text-lg font-semibold">
                              ${simResults.debugInfo?.calculations?.profitCalculation?.currentProfit.toFixed(2)}
                            </div>
                          </div>
                          <div className="text-xl">→</div>
                          <div className="flex-1 p-3 bg-background rounded-lg">
                            <div className="text-sm text-muted-foreground">Projected Daily Profit</div>
                            <div className="text-lg font-semibold">
                              ${simResults.debugInfo?.calculations?.profitCalculation?.proposedProfit.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Confidence Reasons */}
                    {simResults.debugInfo?.calculations?.confidence?.reasons && (
                      <div className="mt-4 pt-4 border-t text-sm text-muted-foreground">
                        <p className="font-medium mb-2">Analysis Notes:</p>
                        <ul className="list-disc list-inside space-y-1">
                          {simResults.debugInfo.calculations.confidence.reasons.map((reason, i) => (
                            <li key={i}>{reason}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  <p className="text-sm text-muted-foreground">
                    Adjust the values below to see how different targets would affect the recommendation.
                    The analysis will update automatically based on your business metrics.
                  </p>
                </div>

                {/* Analysis Controls */}
                <div className="mt-8 border-t pt-4">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="font-semibold">Adjust Analysis</h3>
                    <p className="text-sm text-muted-foreground">
                      Adjust settings below and click "Re-run Analysis" to update results
                    </p>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div>
                      <Label>1. Optimizing For</Label>
                      <select
                        className="w-full mt-2 rounded-md border p-2"
                        value={optimizationType}
                        onChange={(e) => setOptimizationType(e.target.value as 'conversion_value' | 'conversions')}
                      >
                        <option value="conversion_value">Conversion Value</option>
                        <option value="conversions">Conversions</option>
                      </select>
                      <p className="text-xs text-muted-foreground mt-1">
                        Choose what to optimize for
                      </p>
                    </div>

                    <div>
                      <Label>2. {optimizationType === 'conversion_value' ? 'COGS %' : 'Break-even CPA'}</Label>
                      <div className="mt-2 flex items-center gap-2">
                        <span className="text-sm text-muted-foreground">
                          {optimizationType === 'conversion_value' ? '%' : (rawMetrics?.currency || '$')}
                        </span>
                        <input
                          type="text"
                          inputMode="decimal"
                          className="w-full rounded-md border p-2"
                          value={breakEvenMetric}
                          onChange={(e) => {
                            const value = e.target.value.replace(/^0+/, '')
                            if (value === '') {
                              setBreakEvenMetric(0)
                            } else {
                              const num = parseFloat(value)
                              if (!isNaN(num)) {
                                setBreakEvenMetric(num)
                              }
                            }
                          }}
                          onFocus={(e) => e.target.select()}
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                      </div>
                      <p className="text-xs text-muted-foreground mt-1">
                        {optimizationType === 'conversion_value'
                          ? 'Cost of goods sold as percentage'
                          : 'Target cost per conversion'
                        }
                      </p>
                    </div>

                    <div>
                      <Label>3. Optimization Goal</Label>
                      <select
                        className="w-full mt-2 rounded-md border p-2"
                        value={optimizationMode}
                        onChange={(e) => setOptimizationMode(e.target.value as 'profit' | 'growth')}
                      >
                        <option value="profit">Maximum Profit (±5%)</option>
                        <option value="growth">Growth (Break-even)</option>
                      </select>
                      <p className="text-xs text-muted-foreground mt-1">
                        Choose your optimization strategy
                      </p>
                    </div>
                  </div>

                  {/* Move buttons below the inputs */}
                  <div className="mt-6 flex justify-end items-center gap-4">
                    <Button
                      variant="default"
                      onClick={rerunAnalysis}
                      className="px-8"
                    >
                      Re-run Analysis
                    </Button>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => fileInputRef.current?.click()}
                      className="text-xs text-muted-foreground"
                    >
                      analyze new image →
                    </Button>
                  </div>
                </div>

                {/* Debug Section */}
                {DEBUG && simResults.debugInfo && (
                  <div className="mt-8 border-t pt-4">
                    <details className="text-sm">
                      <summary className="font-semibold cursor-pointer flex items-center gap-2">
                        <span>Debug Information</span>
                        <span className="text-xs text-muted-foreground">
                          (Cost: ${simResults.debugInfo.usage.costs.total.toFixed(6)})
                        </span>
                      </summary>
                      <div className="mt-4 grid grid-cols-2 gap-4">
                        {/* Left Column */}
                        <div className="space-y-4">
                          <div className="bg-muted rounded-lg p-3">
                            <h4 className="font-medium mb-2">Campaign Type Detection</h4>
                            <div className="text-xs space-y-1">
                              <div>Type: {rawMetrics?.analysis.simulatorType}</div>
                              <div>Has Conv Value: {rawMetrics?.current.convValue ? 'Yes' : 'No'}</div>
                              <div>Mode: {localBusinessMode}</div>
                            </div>
                          </div>

                          <div className="bg-muted rounded-lg p-3">
                            <h4 className="font-medium mb-2">Current vs Proposed</h4>
                            <div className="grid grid-cols-2 gap-2 text-xs">
                              <div>
                                <div className="font-medium mb-1">Current:</div>
                                <div>Spend: {rawMetrics?.current.spend}</div>
                                <div>Budget: {rawMetrics?.current.budget}</div>
                                <div>Conv: {rawMetrics?.current.conversions}</div>
                                <div>Value: {rawMetrics?.current.convValue || 'N/A'}</div>
                                <div>ROAS: {rawMetrics?.current.roas || 'N/A'}</div>
                              </div>
                              <div>
                                <div className="font-medium mb-1">Proposed:</div>
                                <div>Spend: {rawMetrics?.proposed.spend}</div>
                                <div>Budget: {rawMetrics?.proposed.budget}</div>
                                <div>Conv: {rawMetrics?.proposed.conversions}</div>
                                <div>Value: {rawMetrics?.proposed.convValue || 'N/A'}</div>
                                <div>ROAS: {rawMetrics?.proposed.roas || 'N/A'}</div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Right Column */}
                        <div className="space-y-4">
                          <div className="bg-muted rounded-lg p-3">
                            <h4 className="font-medium mb-2">Marginal Analysis</h4>
                            <div className="text-xs space-y-1">
                              <div>Δ Spend: {(rawMetrics ? rawMetrics.proposed.spend - rawMetrics.current.spend : 0).toFixed(2)}</div>
                              <div>Δ Conv: {(rawMetrics ? rawMetrics.proposed.conversions - rawMetrics.current.conversions : 0).toFixed(2)}</div>
                              <div>Marginal CPA: ${simResults.marginalCPA.toFixed(2)}</div>
                              {rawMetrics?.current.convValue && (
                                <>
                                  <div>Δ Value: {((rawMetrics.proposed.convValue || 0) - (rawMetrics.current.convValue || 0)).toFixed(2)}</div>
                                  <div>Marginal ROAS: {simResults.marginalROAS.toFixed(1)}x</div>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="bg-muted rounded-lg p-3">
                            <h4 className="font-medium mb-2">Profit Impact</h4>
                            <div className="text-xs space-y-1">
                              <div>Current Profit: ${simResults.debugInfo.calculations?.profitCalculation?.currentProfit.toFixed(2)}</div>
                              <div>Proposed Profit: ${simResults.debugInfo.calculations?.profitCalculation?.proposedProfit.toFixed(2)}</div>
                              <div>Change: ${simResults.debugInfo.calculations?.profitCalculation?.profitChange.toFixed(2)}</div>
                              <div className="mt-2 font-medium">Settings:</div>
                              <div>Break-even: {breakEvenMetric}{localBusinessMode === 'ecomm' ? '%' : '$'}</div>
                              <div>Mode: {optimizationMode}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Raw Response - Collapsed by Default */}
                      <details className="mt-4">
                        <summary className="cursor-pointer text-xs text-muted-foreground">Raw Response</summary>
                        <pre className="mt-2 text-xs bg-muted p-2 rounded-lg overflow-auto max-h-48">
                          {JSON.stringify(simResults.debugInfo.rawResponse, null, 2)}
                        </pre>
                      </details>
                    </details>
                  </div>
                )}
              </>
            )}
          </div>
        </CardContent>
      </Card>

      <Dialog open={errorDialog.show} onOpenChange={(open) => setErrorDialog(prev => ({ ...prev, show: open }))}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <AlertTriangle className="h-5 w-5 text-destructive" />
              {errorDialog.title}
            </DialogTitle>
          </DialogHeader>
          <div
            className="text-sm space-y-4"
            dangerouslySetInnerHTML={{
              __html: errorDialog.message.split('\n').map(line =>
                line.trim().startsWith('•') ?
                  `<li class="ml-4">${line.substring(1)}</li>` :
                  `<p>${line}</p>`
              ).join('')
            }}
          />
          <DialogFooter>
            <Button
              variant="default"
              onClick={() => setErrorDialog(prev => ({ ...prev, show: false }))}
            >
              Try Again
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default function SimPage() {
  const [showDevVersion, setShowDevVersion] = useState(true);

  if (!DEBUG) {
    return <ComingSoonPage />;
  }

  if (!showDevVersion) {
    return <ComingSoonPage onShowDev={() => setShowDevVersion(true)} />;
  }

  return <DevSimPage onHideDev={() => setShowDevVersion(false)} />;
} 