interface Screen {
    id: string;
    title: string;
    content: string;
    stage: string;
}

export const screens: Screen[] = [
    {
        id: 'check',
        title: "Check Your Data",
        content: "The foundation of any good script is the ability to check and validate your data. This ensures you're working with accurate information before making any decisions.",
        stage: 'check'
    },
    {
        id: 'chart',
        title: "Chart Your Insights",
        content: "Visualizing your data helps identify patterns and opportunities that might not be obvious in raw numbers.",
        stage: 'chart'
    },
    {
        id: 'change',
        title: "Change Your Account",
        content: "Once you understand your data, you can make automated changes to optimize performance.",
        stage: 'change'
    },
    {
        id: 'cognition',
        title: "Add Cognition",
        content: "The final step is adding AI-powered decision making to your scripts, allowing them to think and adapt.",
        stage: 'cognition'
    },
    {
        id: 'check-examples',
        title: "Check Examples",
        content: "See real-world examples of scripts that validate campaign data, budget allocation, and performance metrics.",
        stage: 'check-examples'
    },
    {
        id: 'chart-examples',
        title: "Chart Examples",
        content: "Explore different ways to visualize campaign performance, audience insights, and competitive analysis.",
        stage: 'chart-examples'
    },
    {
        id: 'change-examples',
        title: "Change Examples",
        content: "Learn how to automate bid adjustments, budget allocation, and campaign optimizations.",
        stage: 'change-examples'
    },
    {
        id: 'cognition-examples',
        title: "Cognition Examples",
        content: "Discover how AI can help make smart decisions about campaign management and optimization.",
        stage: 'cognition-examples'
    },
    {
        id: 'integration',
        title: "Putting It All Together",
        content: "See how all four Cs work together to create powerful, automated campaign management systems.",
        stage: 'integration'
    },
    {
        id: 'future',
        title: "The Future of Campaign Management",
        content: "Look ahead to what's possible when combining scripts with AI for campaign management.",
        stage: 'future'
    }
]; 