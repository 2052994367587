import { useState, useEffect } from 'react'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'

interface ChatButtonProps {
    onClick: () => void
    isOpen: boolean
}

const ANIMATION_STORAGE_KEY = 'fredo-animation-shown'
const CHAT_USED_KEY = 'fredo-chat-used'

export function ChatButton({ onClick, isOpen }: ChatButtonProps) {
    const [shouldAnimate, setShouldAnimate] = useState(false)
    const [showGreeting, setShowGreeting] = useState(false)

    useEffect(() => {
        // Check if animation has been shown before
        const hasAnimated = localStorage.getItem(ANIMATION_STORAGE_KEY)
        const hasChatBeenUsed = localStorage.getItem(CHAT_USED_KEY)

        if (!hasAnimated && !hasChatBeenUsed) {
            setShouldAnimate(true)
            localStorage.setItem(ANIMATION_STORAGE_KEY, 'true')

            // Remove animation after 2 seconds
            const timer = setTimeout(() => {
                setShouldAnimate(false)
            }, 2000)

            return () => clearTimeout(timer)
        }
    }, [])

    useEffect(() => {
        // Show greeting after 2 minutes if chat hasn't been used
        const hasChatBeenUsed = localStorage.getItem(CHAT_USED_KEY)

        if (!hasChatBeenUsed && !isOpen) {
            const greetingTimer = setTimeout(() => {
                setShowGreeting(true)
            }, 120000) // 2 minutes

            return () => clearTimeout(greetingTimer)
        }
    }, [isOpen])

    const handleClick = () => {
        localStorage.setItem(CHAT_USED_KEY, 'true')
        setShowGreeting(false)
        onClick()
    }

    return (
        <div className="fixed bottom-4 right-4">
            {showGreeting && !isOpen && (
                <div className="absolute bottom-16 right-0 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-3 mb-2 text-sm animate-fade-in">
                    Ciao, can I help?
                </div>
            )}
            <Button
                onClick={handleClick}
                className={cn(
                    "w-14 h-14 rounded-full p-0 shadow-lg",
                    "transition-all duration-200 hover:scale-110",
                    shouldAnimate && "animate-shake",
                    isOpen && "scale-0 opacity-0"
                )}
                aria-label="Open AI Assistant"
            >
                <img
                    src="/images/fredo.png"
                    alt="Fredo AI Assistant"
                    className="w-full h-full rounded-full object-cover"
                />
            </Button>
        </div>
    )
} 