// src/components/ai-test/prompt-input.tsx
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'

interface PromptInputProps {
    prompt: string
    setPrompt: (value: string) => void
}

export function PromptInput({ prompt, setPrompt }: PromptInputProps) {
    return (
        <div className="space-y-2">
            <Label>Prompt</Label>
            <Input
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Enter your prompt here"
            />
        </div>
    )
} 