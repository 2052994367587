import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { useUser } from "@clerk/clerk-react";
import {
  Video, Star, Brain, Calendar,
  BookOpen, CheckCircle2
} from 'lucide-react';

export default function MasteryWorkshop() {
  const { user } = useUser();

  return (
    <div className="min-h-screen">
      <div className="relative bg-gradient-to-r from-purple-600 to-blue-700 text-white py-8">
        <div className="container mx-auto px-4 max-w-5xl relative z-10">
          <div className="text-center mb-8">
            <Badge variant="secondary" className="mb-3 bg-white/10 backdrop-blur-sm">2024 Workshop Recording</Badge>
            <h1 className="text-5xl font-bold mb-4">Scripts & Sheets Mastery 2024</h1>
            <p className="text-2xl mb-6 text-white/90">Master Google Ads Scripts & Sheets at Your Own Pace</p>

            <div className="flex justify-center gap-6 flex-wrap mb-6">
              <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full">
                <Video className="h-5 w-5" />
                <span className="font-semibold">Complete Workshop Recordings</span>
              </div>
              <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full">
                <Star className="h-5 w-5" />
                <span className="font-semibold">Includes Many Sample Scripts</span>
              </div>
            </div>

            <a
              href="https://mikerhodes.circle.so/checkout/2024-scripts-and-sheets-workshop"
              className="inline-block"
            >
              <Button size="lg" className="bg-white text-purple-600 hover:bg-white/90">
                Get Instant Access for just $299 USD
              </Button>
            </a>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-16 max-w-5xl">
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Brain className="h-6 w-6 text-purple-600" />
                The QTS System™
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <p className="text-lg text-muted-foreground">Learn the three essential components of script creation with my proven system (even if you've never written code before):</p>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <span className="text-purple-600 font-bold">Q</span>
                  <div>
                    <h4 className="font-semibold">Query</h4>
                    <p className="text-muted-foreground">Learn to extract the right data</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="text-purple-600 font-bold">T</span>
                  <div>
                    <h4 className="font-semibold">Template</h4>
                    <p className="text-muted-foreground">Use proven templates to build scripts fast</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="text-purple-600 font-bold">S</span>
                  <div>
                    <h4 className="font-semibold">Sheet</h4>
                    <p className="text-muted-foreground">Master advanced sheet techniques</p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Calendar className="h-6 w-6 text-purple-600" />
                Workshop Content
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <span className="bg-purple-100 dark:bg-purple-900 w-8 h-8 flex items-center justify-center rounded-full text-purple-600 font-bold flex-shrink-0">1</span>
                  <div>
                    <h4 className="font-semibold">Script Fundamentals</h4>
                    <p className="text-muted-foreground">Install, edit & write your first scripts</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="bg-purple-100 dark:bg-purple-900 w-8 h-8 flex items-center justify-center rounded-full text-purple-600 font-bold flex-shrink-0">2</span>
                  <div>
                    <h4 className="font-semibold">Google Sheets Mastery</h4>
                    <p className="text-muted-foreground">Advanced formulae & charting</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="bg-purple-100 dark:bg-purple-900 w-8 h-8 flex items-center justify-center rounded-full text-purple-600 font-bold flex-shrink-0">3</span>
                  <div>
                    <h4 className="font-semibold">Using AI to help write scripts</h4>
                    <p className="text-muted-foreground">Write scripts with AI assistance</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="bg-purple-100 dark:bg-purple-900 w-8 h-8 flex items-center justify-center rounded-full text-purple-600 font-bold flex-shrink-0">4</span>
                  <div>
                    <h4 className="font-semibold">Advanced Scripts</h4>
                    <p className="text-muted-foreground">Advanced Automation and API Integration</p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        <Card className="mb-16">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <BookOpen className="h-6 w-6 text-purple-600" />
              What's Included 
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <div>
                  <h3 className="font-semibold text-lg mb-4">Workshop Content</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Complete Workshop Recordings</strong>
                        <p className="text-muted-foreground">All 4 sessions with timestamps</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Workshop Materials</strong>
                        <p className="text-muted-foreground">All slides and resources</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Practice Exercises</strong>
                        <p className="text-muted-foreground">Real-world examples to work through</p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div>
                  <h3 className="font-semibold text-lg mb-4">Premium Scripts</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Multiple Sample Scripts</strong>
                        <p className="text-muted-foreground">Template scripts to speed your journey</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>5+ Additional Scripts</strong>
                        <p className="text-muted-foreground">Collection of proven automation scripts</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="space-y-6">
                <div>
                  <h3 className="font-semibold text-lg mb-4">Resources & Tools</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>10+ Script Templates</strong>
                        <p className="text-muted-foreground">Ready-to-use code for common tasks</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>AI Prompt Collection</strong>
                        <p className="text-muted-foreground">My proven prompts for script writing</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Debugging Toolkit</strong>
                        <p className="text-muted-foreground">Troubleshoot scripts like a pro</p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div>
                  <h3 className="font-semibold text-lg mb-4">Bonuses</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Private Community Access</strong>
                        <p className="text-muted-foreground">Connect with fellow students</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Lifetime Updates</strong>
                        <p className="text-muted-foreground">Access to all future content & improvements</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="mb-16">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <CheckCircle2 className="h-6 w-6 text-purple-600" />
              Requirements
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="space-y-4">
              <h3 className="font-semibold text-lg">Required</h3>
              <ul className="space-y-2">
                <li className="flex items-center gap-2">
                  <span className="text-purple-500">✓</span>
                  Basic Google Sheets knowledge
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-purple-500">✓</span>
                  Google Ads account access
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-purple-500">✓</span>
                  Willingness to learn & curiosity
                </li>
              </ul>
            </div>
          </CardContent>
        </Card>

        <div className="text-center space-y-6 py-8">
          <h2 className="text-3xl font-bold">Start Learning Today</h2>
          <p className="text-xl text-muted-foreground">Get instant access to the complete workshop recordings</p>
          <div className="flex flex-col items-center gap-4">
            <div className="text-4xl font-bold text-purple-600">$299</div>
            <a
              href="https://mikerhodes.circle.so/checkout/2024-scripts-and-sheets-workshop"
              className="inline-block"
            >
              <Button size="lg" className="bg-purple-600 hover:bg-purple-700">
                Get Instant Access
              </Button>
            </a>
            <p className="text-sm text-muted-foreground">One-time payment • Price in USD • Lifetime access</p>
          </div>
        </div>
      </div>
    </div>
  );
}