// src/components/ai-test/response-display.tsx
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { Model } from '@/types/ai'

interface ResponseDisplayProps {
    output: string
    error: string
    selectedModel?: Model
}

export function ResponseDisplay({ output, error, selectedModel }: ResponseDisplayProps) {
    return (
        <div className="space-y-4">
            {error && (
                <Alert variant="destructive">
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription className="font-mono text-xs">{error}</AlertDescription>
                </Alert>
            )}

            {output && (
                <div className="space-y-2">
                    <div className="text-sm font-medium">
                        Response using {selectedModel?.name}:
                    </div>
                    <div className="whitespace-pre-wrap rounded-md bg-muted p-4">
                        {output}
                    </div>
                </div>
            )}
        </div>
    )
} 