// src/pages/buy.tsx
import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Fredo } from "@/components/fredo-chat"

interface Script {
  name: string;
  description?: string;
  price: number;
  link: string;
  extras?: string[];
  highlight?: boolean;
}

const NEW_2025_SCRIPTS: Script[] = [
  {
    name: "Build the Agent™",
    price: 799,
    link: "/build",
    description: "Starting February 2025 - Transform Your Career with AI",
    extras: [
      "Small Group Live Training (via Zoom)",
      "Custom AI Agent included ($999 value)",
      "Learn how to build just by talking",
      "I'll share how to build tools like 8020agent",
      "Scripts & Sheets Mastery included ($299 value)",
      "Bonus scripts, templates & prompts included",
      "4 Additional 1on1 sessions with Mike (VIP only)",
      "Pre-course resources & setup guides",
      "Workshop GPT access for ongoing help",
      "Private community access",
      "Follow-up Q&A sessions",
      "Future updates & improvements",
      "Implementation guides included",
      "Includes a gift certificate of $499 towards Own the Agent™"
    ],
    highlight: true
  },
  {
    name: "Live Scripts Workshop",
    price: 699,
    link: "/amsterdam",
    description: "Amsterdam March 17th 2025",
    extras: [
      "One day workshop",
      "In conjunction with PPC Hub",
      "Everything you need to know about scripts",
      "How to use Automation & AI",
      "Includes PMax + many bonus scripts",
      "VIP option includes dinner Mike & Bob",
      "Bonus Q&A session for early registrants"
    ],
    highlight: true
  }
];

const PAID_SCRIPTS: Script[] = [
  {
    name: "MCC PMax Script",
    price: 399,
    link: "https://mikerhodes.circle.so/checkout/latest-mcc-script",
    description: "Use on unlimited accounts",
    extras: [
      "Create PMax sheet for all accounts",
      "Save hours of work",
      "Setup walkthrough video",
      "All documentation included",
      "Personal support from Mike included",
      "Includes all future updates",
      "Include neg keyword script!",
    ]
  },
  {
    name: "Single Account PMax Script",
    price: 199,
    link: "https://mikerhodes.circle.so/checkout/latest-script",
    description: "Used by 2000+ agencies",
    extras: [
      "Create PMax Insights in seconds",
      "Multiple reports included",
      "40 min video walkthrough",
      "Detailed documentation",
      "Free support & updates"
    ]
  },
  {
    name: "Scripts & Sheets Mastery 2024",
    price: 299,
    link: "https://mikerhodes.circle.so/checkout/2024-scripts-and-sheets-workshop",
    description: "Learn how to create your own",
    extras: [
      "Includes my QTS system for scripts",
      "Comprehensive training",
      "Learn in demand skills",
      "Bonus scripts & templatesincluded",
      "Lifetime access to updates"
    ]
  },
  {
    name: "Negative Keyword Script",
    price: 109,
    link: "https://mikerhodes.circle.so/checkout/negative-keyword-script",
    description: "Includes MCC version",
    extras: [
      "Works with Search campaigns",
      "Visualise your data in new ways",
      "Find redundant & conflicting negs",
      "Use AI to build lists, fast",
      "Free support & updates"
    ]
  }
];

const MEGA_BUNDLE: Script = {
  name: "MASSIVE VALUE PACKAGE 🤪",
  price: 499,
  link: "https://mikerhodes.circle.so/checkout/massive-bundle",
  description: "Get all my 2024 scripts and courses for a crazy discount",
  extras: [
    "Includes MCC and PMax scripts",
    "Includes Scripts & Sheets Mastery",
    "Includes Negative Keyword Script",
    "Doesn't include Build the Agent™",
    "Get 9 bonus courses",
    "Priority support",
    "Save over $400",
    "Lifetime access to future updates",
    "Early access to new features"
  ],
  highlight: true
};

const ScriptCard: React.FC<{ script: Script }> = ({ script }) => {
  return (
    <Card className={cn(
      "w-full transition-all duration-200 hover:shadow-lg",
      script.highlight && "border-2 border-blue-500 dark:border-blue-400"
    )}>
      <CardHeader>
        <CardTitle className="flex justify-between items-center">
          <span className={cn(
            "text-xl",
            script.highlight && "text-blue-600 dark:text-blue-400"
          )}>
            {script.name}
          </span>
          <span className={cn(
            "text-2xl font-bold",
            script.highlight && "text-blue-600 dark:text-blue-400"
          )}>
            ${script.price}
          </span>
        </CardTitle>
      </CardHeader>
      <CardContent>
        {script.description && (
          <p className="mb-4 text-muted-foreground">{script.description}</p>
        )}
        {script.extras && (
          <ul className="list-none space-y-2 mb-6">
            {script.extras.map((extra, i) => (
              <li key={i} className="flex items-center">
                <span className="text-green-500 mr-2">✓</span>
                {extra}
              </li>
            ))}
          </ul>
        )}
        <a
          href={script.link}
          target="_blank"
          rel="noopener noreferrer"
          className={cn(
            "inline-block w-full text-center px-4 py-3 rounded transition-colors",
            script.highlight
              ? "bg-blue-600 hover:bg-blue-700 text-white"
              : "bg-blue-600 hover:bg-blue-700 text-white"
          )}
        >
          Get Access
        </a>
      </CardContent>
    </Card>
  );
};

export default function BuyPage() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Gain rare & valuable skills. Advance your career.</h1>

      <h2 className="text-2xl font-bold mb-6 text-orange-600 dark:text-orange-400">All New for 2025</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
        {/* Build the Agent - Hero Card */}
        <Card className={cn(
          "md:col-span-2 bg-orange-50 dark:bg-orange-950/20 border-orange-100 dark:border-orange-900",
          "border-2 border-orange-500",
          "flex flex-col"
        )}>
          <CardHeader>
            <CardTitle className="flex justify-between items-center">
              <span>{NEW_2025_SCRIPTS[0].name}</span>
              <Badge variant="secondary">Limited Spots</Badge>
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-4 flex-1 flex flex-col">
            <div className="text-2xl font-bold text-orange-600">
              ${NEW_2025_SCRIPTS[0].price}
            </div>
            <p className="text-muted-foreground">{NEW_2025_SCRIPTS[0].description}</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 flex-1">
              {NEW_2025_SCRIPTS[0].extras.map((extra, i) => (
                <div key={i} className="flex items-center gap-2">
                  <span className="text-orange-500">✓</span>
                  {extra}
                </div>
              ))}
            </div>
            <a
              href={NEW_2025_SCRIPTS[0].link}
              target="_blank"
              rel="noopener noreferrer"
              className={cn(
                "inline-block w-full text-center px-4 py-3 rounded transition-colors mt-auto",
                "bg-orange-600 hover:bg-orange-700 text-white"
              )}
            >
              Hurry - Limited Spots
            </a>
          </CardContent>
        </Card>

        {/* Amsterdam Workshop Card */}
        <Card className={cn(
          "bg-purple-50 dark:bg-purple-950/20 border-purple-100 dark:border-purple-900",
          "border-2 border-purple-500",
          "flex flex-col"
        )}>
          <CardHeader>
            <CardTitle className="flex justify-between items-center">
              <span>{NEW_2025_SCRIPTS[1].name}</span>
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-4 flex-1 flex flex-col">
            <div className="text-2xl font-bold text-purple-600">
              €{NEW_2025_SCRIPTS[1].price}
            </div>
            <p className="text-muted-foreground">{NEW_2025_SCRIPTS[1].description}</p>
            <ul className="space-y-2 flex-1">
              {NEW_2025_SCRIPTS[1].extras.map((extra, i) => (
                <li key={i} className="flex items-center gap-2">
                  <span className="text-purple-500">✓</span>
                  {extra}
                </li>
              ))}
            </ul>
            <a
              href={NEW_2025_SCRIPTS[1].link}
              target="_blank"
              rel="noopener noreferrer"
              className={cn(
                "inline-block w-full text-center px-4 py-3 rounded transition-colors mt-auto",
                "bg-purple-600 hover:bg-purple-700 text-white"
              )}
            >
              Get Access
            </a>
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
        {/* Row 2: MCC Script and Mega Bundle */}
        <ScriptCard script={PAID_SCRIPTS[0]} />
        <div className="col-span-2">
          <Card className="border-2 border-blue-500 h-full">
            <CardHeader>
              <CardTitle className="flex justify-between items-center">
                <span>{MEGA_BUNDLE.name}</span>
                <Badge variant="secondary">Best Value</Badge>
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="text-3xl font-bold text-blue-600">${MEGA_BUNDLE.price}</div>
              <p className="text-muted-foreground">{MEGA_BUNDLE.description}</p>
              <div className="grid grid-cols-2 gap-4">
                {MEGA_BUNDLE.extras.map((extra, i) => (
                  <div key={i} className="flex items-center gap-2">
                    <span className="text-blue-500">✓</span>
                    {extra}
                  </div>
                ))}
              </div>
              <a
                href={MEGA_BUNDLE.link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block w-full text-center px-4 py-3 rounded transition-colors bg-blue-600 hover:bg-blue-700 text-white"
              >
                Get Everything
              </a>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Row 3: Other scripts */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
        <ScriptCard script={PAID_SCRIPTS[1]} />
        <ScriptCard script={PAID_SCRIPTS[2]} />
        <ScriptCard script={PAID_SCRIPTS[3]} />
      </div>

      <section className="mt-12 bg-orange-100/80 dark:bg-orange-900/20 text-foreground p-8 rounded-lg border border-orange-200 dark:border-orange-800">
        <h2 className="text-2xl font-bold mb-4">Simple 'Pay Once' Pricing</h2>
        <ul className="space-y-3">
          <li className="flex items-center">
            <span className="mr-2">✓</span>
            No subscriptions or recurring fees
          </li>
          <li className="flex items-center">
            <span className="mr-2">✓</span>
            Unlimited, free updates for life
          </li>
          <li className="flex items-center">
            <span className="mr-2">✓</span>
            Free support included (paid scripts)
          </li>
          <li className="flex items-center">
            <span className="mr-2">✓</span>
            All prices in USD (except Amsterdam workshop)
          </li>
        </ul>
      </section>

      {/* Add Fredo */}
      <Fredo
        pageData={{
          scripts: [...NEW_2025_SCRIPTS, ...PAID_SCRIPTS],
          megaBundle: MEGA_BUNDLE
        }}
      />
    </div>
  );
} 