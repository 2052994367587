// src/pages/build.tsx
import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import {
    Loader2, CalendarDays, Users, Star, Video, Bot, Calendar,
    BookOpen, CheckCircle2, Zap, BarChart, DollarSign, LineChart,
    Brain, Code, Shield, Sparkles, Rocket
} from 'lucide-react';
import { toast } from '@/components/ui/use-toast';
import { testimonials } from '@/lib/testimonials';


const StatsCard = ({ icon: Icon, stat, description }: { icon: any, stat: string, description: string }) => (
    <Card className="border-none shadow-lg">
        <CardContent className="flex flex-col items-center p-6">
            <Icon className="h-8 w-8 text-purple-600 mb-2" />
            <div className="text-2xl font-bold mb-1">{stat}</div>
            <p className="text-sm text-center text-muted-foreground">{description}</p>
        </CardContent>
    </Card>
);

const PricingTier = ({ title, price, features, isVIP = false }: {
    title: string,
    price: string,
    features: string[],
    isVIP?: boolean
}) => (
    <Card className={cn("relative", isVIP && "border-purple-600")}>
        {isVIP && <Badge className="absolute top-4 right-4">VIP</Badge>}
        <CardHeader>
            <CardTitle>{title}</CardTitle>
            <div className="text-3xl font-bold mt-2">${price}</div>
        </CardHeader>
        <CardContent>
            <ul className="space-y-3">
                {features.map((feature, i) => (
                    <li key={i} className="flex items-start gap-2">
                        <span className="text-purple-500 mt-1">✓</span>
                        <span>{feature}</span>
                    </li>
                ))}
            </ul>
        </CardContent>
    </Card>
);

const Testimonial = ({ quote, author, role, image }: {
    quote: string,
    author: string,
    role: string,
    image: string
}) => (
    <Card className="border-none shadow-lg">
        <CardContent className="p-6">
            <div className="flex items-start gap-4">
                <img src={image} alt={author} className="w-16 h-14 rounded-full object-cover" />
                <div>
                    <p className="text-lg mb-4" dangerouslySetInnerHTML={{
                        __html: quote.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                    }} />
                    <p className="font-semibold">{author}</p>
                    <p className="text-sm text-muted-foreground">{role}</p>
                </div>
            </div>
        </CardContent>
    </Card>
);

const BenefitCard = ({ icon: Icon, title, description }: {
    icon: any,
    title: string,
    description: string
}) => (
    <Card className="border-none shadow-lg">
        <CardContent className="p-6">
            <Icon className="h-8 w-8 text-purple-600 mb-4" />
            <h3 className="text-xl font-bold mb-2">{title}</h3>
            <p className="text-muted-foreground">{description}</p>
        </CardContent>
    </Card>
);

export default function BuildThePage() {
    return (
        <div className="min-h-screen">
            {/* Hero Section - Reduced height */}
            <div className="relative bg-gradient-to-r from-purple-800 to-indigo-500 text-white py-12">
                <div className="container mx-auto px-4 max-w-6xl">
                    <div className="text-center mb-8">
                        <Badge variant="secondary" className="mb-4 bg-white/50 text-lg">Starting February 19, 2025</Badge>
                        <h1 className="text-6xl font-bold mb-6">From Smart Scripts to Intelligent Agents: The Evolution of PPC Professionals</h1>
                        <p className="text-2xl mb-4 text-white/90">We'll start with Google Ads scripts so you get value for your day job immediately, then</p>
                        <p className="text-2xl mb-4 text-white/90">I'll build all the code for your first agent & give it to you (value $999)</p>
                        <p className="text-2xl mb-4 text-white/90">Then teach you how to build more just by talking to your computer</p>
                        <p className="text-xl mb-8 text-white/70">Gain the most in-demand skills, transform your career & jump on the biggest business trend of 2025</p>

                        {/* Purchase Buttons */}
                        <div className="flex flex-col sm:flex-row gap-6 justify-center items-center mb-8">
                            <a
                                href="https://mikerhodes.circle.so/checkout/build"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block transform hover:scale-105 transition-transform"
                            >
                                <Button
                                    className="bg-white text-purple-600 hover:bg-white/90 text-lg px-8 py-6 h-auto font-bold shadow-lg"
                                    size="lg"
                                >
                                    Secure your Spot Now!
                                </Button>
                            </a>
                            <a
                                href="https://mikerhodes.circle.so/checkout/build-vip"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block transform hover:scale-105 transition-transform"
                            >
                                <Button
                                    className="bg-orange-600 hover:bg-orange-700 text-white text-lg px-8 py-6 h-auto font-bold shadow-lg"
                                    size="lg"
                                >
                                    Go VIP & Get 1on1 Training
                                </Button>
                            </a>
                        </div>

                        <div className="mt-4">
                            <a
                                href="https://mikerhodes.circle.so/checkout/build-payment-plan"
                                className="text-sm text-white/80 hover:text-white underline"
                            >
                                Or choose the early-bird payment plan: $499 now + 2 × $200/month
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {/* Industry Leader Quote & Video Section */}
            <div className="bg-slate-50 dark:bg-slate-900 py-8">
                <div className="container mx-auto px-4 max-w-6xl">
                    <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">Industry Leaders on AI Agents</h2>
                    <p className="text-3xl font-bold text-center mb-12">The Future is Here: AI Agents are the Next Big Thing</p>
                    <div className="grid md:grid-cols-2 gap-12 items-center">
                        <div className="space-y-8">
                            <p className="text-xl">The most inside-AI of all AI people believe that AI Agents will transform work as we know it.</p>
                            <div className="relative">
                                <div className="absolute -left-4 top-0 text-6xl text-purple-300 opacity-50">"</div>
                                <blockquote className="relative z-10 pl-6 text-xl">
                                    <p className="mb-4">AI agents are AI systems that can do work for you independently. You give them a task and they go off and do it.</p>
                                    <p>We think this is going to be a big trend in AI and really impact the work people can do, how productive they can be, how creative they can be, what they can accomplish.</p>
                                    <footer className="mt-4 text-sm text-muted-foreground">
                                        <cite>Sam Altman, CEO of OpenAI - Jan 2025</cite>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                        <div className="space-y-4">
                            <div className="aspect-video">
                                <iframe
                                    className="w-full h-full rounded-lg shadow-lg"
                                    src="https://www.youtube.com/embed/snkOMOjiVOk?start=941"
                                    title="AI Agents Explained by Anthropic CEO"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                            <p className="text-sm text-muted-foreground text-center">Watch Dario Amodei, CEO of Anthropic, explain how AI agents are transforming business operations</p>
                        </div>
                    </div>
                    <div className="mt-12 text-center max-w-3xl mx-auto">
                        <p className="text-2xl font-bold">The AI revolution is accelerating, and agents are at the forefront.
                            <br />
                            But how do you actually build one? Where do you start?</p>
                    </div>
                </div>
            </div>

            {/* 4Cs Framework Section */}
            <div className="container mx-auto px-4 py-12 max-w-6xl">
                <p className="text-3xl font-bold text-center mb-12">Building AI Agents Doesn't Have to Be Complicated</p>

                <div className="prose prose-lg dark:prose-invert max-w-none mb-12">
                    <p className="text-lg text-muted-foreground">
                        For the past year and a half, I've been coding almost every day - building scripts, experimenting with AI, and pushing the boundaries of what's possible. Through countless hours of trial and error, I've learned not just what works, but more importantly, what doesn't.
                    </p>

                    <p className="text-lg text-muted-foreground">
                        I've already been offered a six-figure sum for 8020agent, but I turned it down. So why am I sharing exactly how to build these tools for less than a thousand dollars?
                    </p>

                    <p className="text-lg text-muted-foreground">
                        Because this is THE skill that agencies need to master. It's too important to keep to myself. The purpose of this course is to save you months of trial and error, days of frustration, and thousands of dollars in wasted time.
                    </p>
                </div>

                <div className="grid md:grid-cols-3 gap-8 items-start">
                    {/* Left side text - takes up 2 columns */}
                    <div className="md:col-span-2 space-y-8">
                        <div className="prose prose-lg dark:prose-invert max-w-none">
                            <p className="text-lg text-muted-foreground mb-8">
                                Scripts & Sheets Mastery was all about creating scripts that could <strong>CHECK</strong> and <strong>CHART</strong> your data,
                                Now we're going to take your skills to the next level.
                                Learn to build Google Ads scripts that can not only make <strong>CHANGES</strong> to your account
                                they can use <strong>COGNITION</strong> to help you automate your work, save time & gain rare & valuable skills!
                                <br />
                                All following my 'as is', 'adjust', 'author' framework. Simple to follow. Wonderfully powerful.
                            </p>

                            <div className="grid grid-cols-2 gap-8">
                                <div>
                                    <h3 className="font-bold text-xl mb-4">Basic Scripts</h3>
                                    <div className="space-y-4">
                                        <div>
                                            <h4 className="font-semibold text-lg mb-2">Check (alerts)</h4>
                                            <p className="text-muted-foreground">Monitor your accounts and get notified of important changes or issues.</p>
                                        </div>
                                        <div>
                                            <h4 className="font-semibold text-lg mb-2">Chart (info)</h4>
                                            <p className="text-muted-foreground">Visualize your data to uncover insights and patterns in your campaigns.</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-bold text-xl mb-4">Advanced Scripts</h3>
                                    <div className="space-y-4">
                                        <div>
                                            <h4 className="font-semibold text-lg mb-2">Change (action)</h4>
                                            <p className="text-muted-foreground">Automatically make optimizations and adjustments to your campaigns.</p>
                                        </div>
                                        <div>
                                            <h4 className="font-semibold text-lg mb-2">Cognition (think)</h4>
                                            <p className="text-muted-foreground">Use AI to make intelligent decisions and strategic recommendations.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right side image - with added top padding on mobile */}
                    <div className="relative pt-[100px] md:pt-[20px]">
                        <img
                            src="/images/cognition.png"
                            alt="4Cs Framework: Check, Chart, Change, Cognition"
                            className="w-full rounded-lg shadow-lg"
                        />
                    </div>
                </div>
                <div className="mt-12 text-center max-w-3xl mx-auto">
                    <p className="text-2xl font-bold">Now that you understand the framework, let's look at how this will transform your business...</p>
                </div>
            </div>

            {/* Benefits/Outcomes Section */}
            <div className="container mx-auto px-4 py-12 max-w-6xl">
                <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">The Impact</h2>
                <p className="text-3xl font-bold text-center mb-12">Your Competitive Advantage in 2025 and Beyond</p>
                <div className="grid md:grid-cols-3 gap-8 mb-16">
                    <BenefitCard
                        icon={Zap}
                        title="Automate Specific Tasks"
                        description="Create AI agents that handle targeted tasks like campaign optimization, reporting, and client management"
                    />
                    <BenefitCard
                        icon={DollarSign}
                        title="Expand Your Services"
                        description="Offer high-value AI services beyond chat - from custom tools to complete automation solutions"
                    />
                    <BenefitCard
                        icon={BarChart}
                        title="Stay Ahead"
                        description="Master the skills that will define business success in 2025 and beyond"
                    />
                </div>
                <div className="bg-purple-50 dark:bg-purple-900/20 rounded-2xl p-8 mb-1">
                    <h3 className="text-2xl font-bold mb-6">Real Results from AI Agents:</h3>
                    <div className="grid md:grid-cols-2 gap-6">
                        {[
                            "Save 5-20 hours per week on campaign management and reporting",
                            "Automate 80% of routine Google Ads tasks & make your team happier",
                            "Generate thousands per month in new service revenue",
                            "Reduce campaign management time by 60%",
                            "Create custom tools worth $500 to $2,000 each (or more)",
                            "Deploy agents that work 24/7 monitoring accounts"
                        ].map((benefit, i) => (
                            <div key={i} className="flex items-start gap-3">
                                <CheckCircle2 className="h-5 w-5 text-purple-600 mt-1" />
                                <span>{benefit}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Schedule Section */}
            <div className="container mx-auto px-4 py-1 max-w-6xl">
                <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">Ready to master these skills? Here's exactly how we'll get you there...</h2>
                <p className="text-3xl font-bold text-center mb-12">From Basics to Building Your Own AI Agents in 8 Weeks</p>
                <div className="relative">
                    {/* Vertical Path line */}
                    <div className="absolute left-1/2 top-0 bottom-0 w-1 bg-purple-200 dark:bg-purple-800 -translate-x-1/2 z-0" />

                    <div className="relative z-10 space-y-6">
                        {[
                            {
                                week: "Week 0",
                                date: "Before Feb 19",
                                title: "Pre-Course Resources",
                                description: "Get a head start with exclusive pre-course materials and setup guides",
                                extras: [
                                    "Access to all my premium scripts ($500 value)",
                                    "Get my 2024 Workshop included",
                                    "Get tons of value before we even start!"
                                ],
                                icon: BookOpen,
                                side: "left"
                            },
                            {
                                week: "Week 1",
                                date: "Feb 19",
                                title: "Building Smarter Scripts",
                                description: "Transform your monitoring scripts into action-takers",
                                extras: [
                                    "Get plug-and-play code for account changes",
                                    "Start saving hours on repetitive tasks"
                                ],
                                icon: Bot,
                                side: "right"
                            },
                            {
                                week: "Week 2",
                                date: "Feb 26",
                                title: "Adding Intelligence",
                                description: "Turn scripts into thinking tools",
                                extras: [
                                    "Template code for AI integration",
                                    "Build your first cognitive script"
                                ],
                                icon: Brain,
                                side: "left"
                            },
                            {
                                week: "Week 3",
                                date: "Mar 5",
                                title: "Your First AI Agent",
                                description: "Blueprint for agent creation",
                                extras: [
                                    "I'll give you 95% of the code",
                                    "Deploy your own agent "
                                ],
                                icon: Bot,
                                side: "right"
                            },
                            {
                                week: "Break",
                                date: "Mar 12 & 19",
                                title: "2 Weeks for Experimentation",
                                description: "Test your agent's capabilities",
                                extras: [
                                    "Community sharing & feedback",
                                    "Time to catch up & plan next steps"
                                ],
                                icon: Calendar,
                                side: "left"
                            },
                            {
                                week: "Week 4",
                                date: "Mar 26",
                                title: "No-Code Development",
                                description: "Master Replit & Cursor",
                                extras: [
                                    "My personal prompt library",
                                    "Pro developer environment setup"
                                ],
                                icon: Code,
                                side: "right"
                            },
                            {
                                week: "Week 5",
                                date: "Apr 2",
                                title: "Bulletproof Your Code",
                                description: "Essential debugging techniques",
                                extras: [
                                    "Debugging & fixing code with AI",
                                    "Recovery & version control strategies"
                                ],
                                icon: Shield,
                                side: "left"
                            },
                            {
                                week: "Week 6",
                                date: "Apr 9",
                                title: "Creative Integration",
                                description: "We'll build an interactive game",
                                extras: [
                                    "Combine all techniques so far",
                                    "Push boundaries & have some fun"
                                ],
                                icon: Sparkles,
                                side: "right"
                            },
                            {
                                week: "Break",
                                date: "Apr 16 (my daughter's birthday)",
                                title: "Implementation Week",
                                description: "Apply to your projects",
                                extras: [
                                    "Time to experiment & plan",
                                    "Review your progress so far"
                                ],
                                icon: Calendar,
                                side: "left"
                            },
                            {
                                week: "Week 7",
                                date: "Apr 23",
                                title: "Expert Perspectives",
                                description: "Community showcase masterclass",
                                extras: [
                                    "Two special guest presenters",
                                    "Real-world applications"
                                ],
                                icon: Users,
                                side: "right"
                            },
                            {
                                week: "Week 8",
                                date: "Apr 30",
                                title: "Future-Proofing your Ideas",
                                description: "Product ideation workshop",
                                extras: [
                                    "Service offering strategies",
                                    "Industry trends & opportunities"
                                ],
                                icon: Rocket,
                                side: "left"
                            }
                        ].map((week, i) => (
                            <div key={i} className={cn(
                                "grid grid-cols-1 md:grid-cols-2 items-center gap-8",
                                week.side === "right" && "md:direction-rtl"
                            )}>
                                {/* Content - Full width on mobile */}
                                <div className={cn(
                                    "bg-white dark:bg-slate-800 rounded-lg p-6 shadow-lg border border-purple-100 dark:border-purple-900",
                                    "col-span-1",
                                    week.side === "right" && "md:col-start-2"
                                )}>
                                    <div className="flex items-start gap-4">
                                        <week.icon className="h-6 w-6 text-purple-600 mt-1" />
                                        <div>
                                            <div className="font-semibold text-purple-600 mb-1">{week.week}</div>
                                            <div className="text-sm text-muted-foreground mb-2">{week.date}</div>
                                            <h3 className="text-xl font-bold mb-2">{week.title}</h3>
                                            <p className="text-muted-foreground mb-2">{week.description}</p>
                                            {week.extras && (
                                                <ul className="space-y-1 text-sm text-muted-foreground">
                                                    {week.extras.map((extra, i) => (
                                                        <li key={i} className="flex items-center gap-2">
                                                            <span className="text-purple-500">•</span>
                                                            {extra}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* Pin/marker - Hidden on mobile */}
                                <div className="hidden md:block absolute left-1/2 -translate-x-1/2">
                                    <div className="w-4 h-4 rounded-full bg-purple-600 ring-4 ring-purple-200 dark:ring-purple-800" />
                                </div>

                                {/* Empty column - Hidden on mobile */}
                                <div className="hidden md:block">
                                    {/* Removed col-start text */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="mt-12 text-center max-w-3xl mx-auto">
                    <p className="text-2xl font-bold">Choose the path that's right for you...</p>
                </div>
            </div>

            {/* Pricing Section */}
            <div className="bg-slate-50 dark:bg-slate-900 py-12">
                <div className="container mx-auto px-4 max-w-6xl">
                    <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">Investment Options</h2>
                    <p className="text-3xl font-bold text-center mb-12">Lock In Your Early Bird Price Now</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <Card className="relative">
                            <Badge className="absolute top-4 right-4 text-xs">Early Bird</Badge>
                            <CardHeader>
                                <CardTitle>Standard Program</CardTitle>
                                <div className="space-y-2">
                                    <div className="text-3xl font-bold">$799 USD</div>
                                    <div className="text-sm text-muted-foreground">
                                        Early bird rate - Limited time only
                                    </div>
                                </div>
                            </CardHeader>
                            <CardContent className="space-y-6">
                                <ul className="space-y-3">
                                    {[
                                        "Custom AI Agent (valued at $999)",
                                        "8-Week intensive program",
                                        "Scripts & Sheets Mastery ($399 value)",
                                        "Expert masterclass with Geert",
                                        "Extensive prompt library",
                                        "Ready-to-use code templates",
                                        "Implementation guides",
                                        "Community support"
                                    ].map((feature, i) => (
                                        <li key={i} className="flex items-start gap-2">
                                            <span className="text-purple-500 mt-1">✓</span>
                                            <span>{feature}</span>
                                        </li>
                                    ))}
                                </ul>

                                <div className="space-y-4 pt-4">
                                    <a
                                        href="https://mikerhodes.circle.so/checkout/build"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block w-full"
                                    >
                                        <Button
                                            className="w-full bg-purple-600 hover:bg-purple-700 text-white"
                                            size="lg"
                                        >
                                            Pay in Full (Early Bird Price $799)
                                        </Button>
                                    </a>

                                    <div className="relative">
                                        <div className="absolute inset-0 flex items-center">
                                            <span className="w-full border-t" />
                                        </div>
                                        <div className="relative flex justify-center text-xs uppercase">
                                            <span className="bg-slate-50 dark:bg-slate-900 px-2 text-muted-foreground">
                                                Or
                                            </span>
                                        </div>
                                    </div>

                                    <a
                                        href="https://mikerhodes.circle.so/checkout/build-payment-plan"
                                        className="block w-full"
                                    >
                                        <Button
                                            variant="outline"
                                            className="w-full border-purple-600 text-purple-600 hover:bg-purple-50"
                                            size="lg"
                                        >
                                            Payment Plan ($499 + 2 × $200)
                                        </Button>
                                    </a>
                                </div>
                            </CardContent>
                        </Card>

                        <Card className="relative border-purple-600">
                            <Badge className="absolute top-4 right-4">VIP</Badge>
                            <CardHeader>
                                <CardTitle>VIP Track</CardTitle>
                                <div className="space-y-2">
                                    <div className="text-3xl font-bold">$1,999 USD</div>
                                    <div className="text-sm text-muted-foreground">
                                        Early access rate - Very limited spots
                                    </div>
                                </div>
                            </CardHeader>
                            <CardContent className="space-y-6">
                                <ul className="space-y-3">
                                    {[
                                        "Everything in Standard Program",
                                        "4 private 1-on-1 consulting calls",
                                        "3 months implementation support",
                                        "Direct project assistance",
                                        "Priority access to new tools",
                                        "Advanced implementation guides",
                                        "VIP community access",
                                        "Early access to future updates"
                                    ].map((feature, i) => (
                                        <li key={i} className="flex items-start gap-2">
                                            <span className="text-purple-500 mt-1">✓</span>
                                            <span>{feature}</span>
                                        </li>
                                    ))}
                                </ul>

                                <a
                                    href="https://mikerhodes.circle.so/checkout/build-vip"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block w-full"
                                >
                                    <Button
                                        className="w-full bg-purple-600 hover:bg-purple-700 text-white"
                                        size="lg"
                                    >
                                        Join VIP Track ($1,999)
                                    </Button>
                                </a>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>

            {/* Testimonials */}
            <div className="bg-slate-50 dark:bg-slate-900 py-12">
                <div className="container mx-auto px-4 max-w-6xl">
                    <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">Success Stories</h2>
                    <p className="text-3xl font-bold text-center mb-12">Join Our Community of AI Pioneers</p>
                    <div className="grid md:grid-cols-2 gap-8">
                        {testimonials.map((testimonial, i) => (
                            <Testimonial key={i} {...testimonial} />
                        ))}
                    </div>
                </div>
            </div>

            {/* Value Stack Section */}
            <div className="container mx-auto px-4 py-12 max-w-6xl">
                <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">The Full Package</h2>
                <p className="text-3xl font-bold text-center mb-12">Everything You Need to Succeed</p>
                <div className="max-w-2xl mx-auto">
                    <Card className="border-2 border-purple-200 dark:border-purple-800">
                        <CardContent className="p-8">
                            <ul className="space-y-4">
                                {[
                                    { name: "Custom AI Agent", value: 999 },
                                    { name: "Training Program", value: 2000 },
                                    { name: "Templates & Frameworks", value: 499 },
                                    { name: "Scripts & Sheets Mastery", value: 299 },
                                    { name: "Community Access", value: 199 },
                                    { name: "Early Access to Updates", value: 100 },
                                    { name: "'Own the Agent™' Gift Certificate", value: 499 }
                                ].map((item, i) => (
                                    <li key={i} className="flex justify-between items-center">
                                        <span>{item.name}</span>
                                        <span className="font-bold">${item.value}</span>
                                    </li>
                                ))}
                                <li className="pt-4 border-t">
                                    <div className="flex justify-between items-center text-lg font-bold">
                                        <span>Total Value</span>
                                        <span>$4,595</span>
                                    </div>
                                </li>
                                <li className="pt-4 border-t">
                                    <div className="flex justify-between items-center text-2xl font-bold text-purple-600">
                                        <span>Your Investment</span>
                                        <span>$799</span>
                                    </div>
                                </li>
                            </ul>
                        </CardContent>
                    </Card>
                </div>
            </div>

            {/* About Me Section */}
            <div className="container mx-auto px-4 py-12 max-w-6xl">
                <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">Your Guide</h2>
                <p className="text-3xl font-bold text-center mb-12">Learn from Someone Who's Been There</p>
                <div className="grid md:grid-cols-2 gap-12 items-center">
                    <div>
                        <img src="/images/people/mike.png" alt="Profile" className="rounded-lg shadow-lg" />
                    </div>
                    <div>
                        <div className="space-y-4">
                            <p>Hi I'm Mike, husband to Gabbi & dad to two gorgeous girls</p>
                            <p>Built & sold 4 businesses over the past 20 years</p>
                            <p>The last one was WebSavvy, named by Google as a Global Top 18 Agency</p>
                            <p>Trained 20,000+ professionals in Google Ads</p>
                            <p>Co-authored the world's best-selling book on Google Ads</p>
                            <p>Top 20 Most Influential PPC Expert since 2015</p>
                            <p>Teaching AI implementation since 2017</p>
                            <p>Spoken at 100+ events worldwide</p>
                            <p>Creator of 8020agent.com</p>
                            <p>Huge fan of AI agents and excited to help you build your first one!</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Why Now Section */}
            <div className="bg-slate-50 dark:bg-slate-900 py-12">
                <div className="container mx-auto px-4 max-w-6xl">
                    <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">Perfect Timing</h2>
                    <p className="text-3xl font-bold text-center mb-12">Why AI Agents Are the Next Big Opportunity</p>
                    <div className="grid md:grid-cols-2 gap-12 items-center mb-12">
                        <div>
                            <div className="space-y-6">
                                <div>
                                    <h3 className="text-xl font-bold mb-2">Market Growth</h3>
                                    <p className="text-muted-foreground">AI agent adoption is forecast to grow almost 10x in the next 5 years</p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold mb-2">Early Advantage</h3>
                                    <p className="text-muted-foreground">Only 8% of businesses currently using AI agents - massive opportunity for early adopters</p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold mb-2">Revenue Potential</h3>
                                    <p className="text-muted-foreground">AI services commanding 2-3x higher rates than traditional services</p>
                                </div>
                            </div>
                        </div>
                        <div className="relative">
                            <img
                                src="/images/agents.webp"
                                alt="AI Agents Visualization"
                                className="rounded-lg shadow-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Enhanced Final CTA */}
            <div className="bg-gradient-to-r from-purple-600 to-indigo-700 text-white py-12">
                <div className="container mx-auto px-4 text-center max-w-3xl">
                    <h2 className="text-4xl font-bold mb-6">The AI Revolution Is Here</h2>
                    <p className="text-xl mb-8">Join the pioneers who are already using AI agents to transform their businesses. Get your custom agent and learn to build more - starting February 19.</p>
                    <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 mb-8">
                        <div className="text-2xl font-bold mb-2">Early Bird Offer - Limited Time Only</div>
                        <div className="text-lg mb-2">Custom AI Agent ($999 value) + Full Program</div>
                        <p className="text-sm mb-6">Lock in the current rate before prices increase</p>
                        <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
                            <a
                                href="https://mikerhodes.circle.so/checkout/build"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block"
                            >
                                <Button
                                    className="bg-white text-purple-600 hover:bg-white/90"
                                    size="lg"
                                >
                                    Join Standard Track ($799)
                                </Button>
                            </a>
                            <a
                                href="https://mikerhodes.circle.so/checkout/build-vip"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block"
                            >
                                <Button
                                    className="bg-purple-800 hover:bg-purple-900 text-white"
                                    size="lg"
                                >
                                    Join VIP Track ($1,999)
                                </Button>
                            </a>
                        </div>
                        <div className="mt-4">
                            <a
                                href="https://mikerhodes.circle.so/checkout/build-payment-plan"
                                className="text-sm text-white/80 hover:text-white underline"
                            >
                                Or choose the payment plan: $499 now + 2 × $200/month
                            </a>
                        </div>
                    </div>
                    <p className="text-sm">Only a small group will be accepted for this cohort - secure your spot while early bird pricing is still available</p>
                </div>
            </div>
        </div>
    );
} 