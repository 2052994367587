// src/pages/data.tsx
import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

const WEB_APP_URL = 'https://script.google.com/macros/s/AKfycbx3vg7BOwlz9V60NL7T8ujjgA26kj3wzoCW8HYvuDesSWUki_kyK3DpOkLKJV8ArSd-/exec'

interface TabInfo {
    name: string
    headers: string[]
    recordCount: number
    firstRow: any
}

export default function DataPage() {
    const [tabName, setTabName] = useState('')
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [tabsInfo, setTabsInfo] = useState<TabInfo[]>([])
    const [activeTab, setActiveTab] = useState<'inspector' | 'overview'>('inspector')

    const fetchData = async () => {
        if (!tabName) return

        setLoading(true)
        setError('')

        try {
            const url = WEB_APP_URL + '?tab=' + tabName.toLowerCase()
            const response = await fetch(url)
            if (!response.ok) {
                throw new Error('HTTP error! status: ' + response.status)
            }
            const result = await response.json()
            if (!Array.isArray(result)) {
                throw new Error('Invalid data format')
            }
            setData(result)

            // Update tabs info if this is a new tab
            if (!tabsInfo.find(t => t.name === tabName)) {
                setTabsInfo(prev => [...prev, {
                    name: tabName,
                    headers: result.length > 0 ? Object.keys(result[0]) : [],
                    recordCount: result.length,
                    firstRow: result[0] || {}
                }])
            }
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to fetch data')
            setData([])
        } finally {
            setLoading(false)
        }
    }

    const getHeaders = () => {
        if (!data.length) return []
        return Object.keys(data[0])
    }

    return (
        <div className="container mx-auto py-6 space-y-6">
            <Tabs value={activeTab} onValueChange={(v) => setActiveTab(v as 'inspector' | 'overview')}>
                <TabsList>
                    <TabsTrigger value="inspector">Data Inspector</TabsTrigger>
                    <TabsTrigger value="overview">Tabs Overview</TabsTrigger>
                </TabsList>

                <TabsContent value="inspector">
                    <Card>
                        <CardHeader>
                            <CardTitle>Data Inspector</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="flex gap-4 mb-6">
                                <Input
                                    placeholder="Enter tab name"
                                    value={tabName}
                                    onChange={(e) => setTabName(e.target.value)}
                                    className="max-w-xs"
                                />
                                <Button
                                    onClick={fetchData}
                                    disabled={!tabName || loading}
                                >
                                    {loading ? 'Loading...' : 'Fetch Data'}
                                </Button>
                            </div>

                            {error && (
                                <div className="text-red-500 mb-4">
                                    Error: {error}
                                </div>
                            )}

                            {data.length > 0 && (
                                <div className="space-y-4">
                                    <div className="text-sm text-muted-foreground">
                                        Records found: {data.length}
                                    </div>

                                    <div className="overflow-x-auto">
                                        <div className="font-bold mb-2">Headers:</div>
                                        <div className="flex gap-2 flex-wrap">
                                            {getHeaders().map((header) => (
                                                <span key={header} className="px-2 py-1 bg-muted rounded text-sm">
                                                    {header}
                                                </span>
                                            ))}
                                        </div>
                                    </div>

                                    {data.length > 0 && (
                                        <div>
                                            <div className="font-bold mb-2">First Row:</div>
                                            <pre className="bg-muted p-4 rounded overflow-x-auto">
                                                {JSON.stringify(data[0], null, 2)}
                                            </pre>
                                        </div>
                                    )}
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </TabsContent>

                <TabsContent value="overview">
                    <Card>
                        <CardHeader>
                            <CardTitle>Tabs Overview</CardTitle>
                        </CardHeader>
                        <CardContent>
                            {tabsInfo.length === 0 ? (
                                <div className="text-muted-foreground">
                                    No tabs inspected yet. Use the Data Inspector to fetch tab data.
                                </div>
                            ) : (
                                <div className="space-y-4">
                                    <div className="font-medium mb-2">Compact Format:</div>
                                    <pre className="bg-muted p-4 rounded overflow-x-auto text-sm whitespace-pre-wrap">
                                        {JSON.stringify(tabsInfo.reduce((acc, tab) => ({
                                            ...acc,
                                            [tab.name]: tab.headers
                                        }), {}), null, 2)}
                                    </pre>

                                    <div className="font-medium mb-2">One-line Format (for copying):</div>
                                    <pre className="bg-muted p-4 rounded overflow-x-auto text-sm">
                                        {JSON.stringify(tabsInfo.reduce((acc, tab) => ({
                                            ...acc,
                                            [tab.name]: tab.headers
                                        }), {}))}
                                    </pre>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </TabsContent>
            </Tabs>
        </div>
    )
}
