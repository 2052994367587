// src/components/layouts/root-layout.tsx
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Navbar } from '@/components/navigation/navbar';
import { MobileNavbar } from '@/components/navigation/mobile-navbar';
import { DevNavbar } from '@/components/navigation/dev-navbar';
import { Footer } from '@/components/navigation/footer';
import { useUser } from '@clerk/clerk-react';
import { SampleDataBanner } from '@/components/sample-data-banner';
import ErrorBoundary from '@/components/error-boundary';
import { Toaster } from '@/components/ui/toaster';
import { useCampaignData } from '@/contexts/campaign-data';
import { StickyFeedback } from '@/components/sticky-feedback';
import { useEffect } from 'react';

const DEBUG = process.env.NODE_ENV === 'development'

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// Path configurations
const MOBILE_ALLOWED_PATHS = ['/mobile', '/build', '/amsterdam', '/buy'];
const NO_BANNER_PATHS = ['/4cs', '/cpd', '/scary', '/test', '/prompt', '/brief', '/data', '/mastery'];

export default function RootLayout() {
  const { user, isLoaded } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { useSampleData } = useCampaignData();
  
  const isMobile = isMobileDevice();
  const isOnMobilePage = MOBILE_ALLOWED_PATHS.some(path => location.pathname.startsWith(path));
  const isOnNoBannerPage = NO_BANNER_PATHS.some(path => location.pathname.startsWith(path));

  useEffect(() => {
    if (isMobile && !isOnMobilePage) {
      navigate('/mobile', { replace: true });
    }
  }, [location.pathname, navigate, isMobile, isOnMobilePage]);

  const shouldShowBanner = useSampleData && !isOnMobilePage && !isOnNoBannerPage;

  return (
    <ErrorBoundary>
      <div className="relative min-h-screen bg-background font-sans antialiased">
        <div className="relative flex min-h-screen flex-col">
          {isOnMobilePage ? (
            <MobileNavbar />
          ) : (
            <>
              <Navbar />
              {DEBUG && <DevNavbar />}
            </>
          )}
          
          <main className="flex-1 container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-8">
            {shouldShowBanner && <SampleDataBanner />}
            <Outlet />
          </main>
          
          <Footer />
        </div>
        <StickyFeedback />
        <Toaster />
      </div>
    </ErrorBoundary>
  );
}