import { useState, useCallback } from 'react'
import { Loader2 } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { useUser } from '@clerk/clerk-react'
import { useCampaignData } from '@/contexts/campaign-data'
import { SHEET_TABS } from '@/lib/constants'
import { generateAIResponse } from '@/services/ai-api'
import { useAIUsage } from '@/hooks/use-ai-usage'
import type { Model } from '@/types/ai'
import ReactMarkdown from 'react-markdown'

// Constants for the Gemini model
const MODEL: Model = {
    id: 'gemini-2.0-flash-exp',
    name: 'Gemini 2.0 Flash',
    inputPrice: 0,
    outputPrice: 0
}

const MAX_TOKENS = 1000

interface BriefingSection {
    title: string
    content: string
    loading: boolean
    error: string
    status: 'not_requested' | 'working' | 'done'
    cost: number
}

export default function Brief() {
    const { user } = useUser()
    const { data: campaignData } = useCampaignData()
    const { stats, updateStats } = useAIUsage()
    const [loading, setLoading] = useState(false)
    const [briefings, setBriefings] = useState<BriefingSection[]>([
        {
            title: 'Hourly Performance Analysis',
            content: '',
            loading: false,
            error: '',
            status: 'not_requested',
            cost: 0
        },
        {
            title: 'Change History Summary',
            content: '',
            loading: false,
            error: '',
            status: 'not_requested',
            cost: 0
        },
        {
            title: 'Placeholder Analysis 1',
            content: 'Coming soon...',
            loading: false,
            error: '',
            status: 'not_requested',
            cost: 0
        },
        {
            title: 'Placeholder Analysis 2',
            content: 'Coming soon...',
            loading: false,
            error: '',
            status: 'not_requested',
            cost: 0
        },
        {
            title: 'Placeholder Analysis 3',
            content: 'Coming soon...',
            loading: false,
            error: '',
            status: 'not_requested',
            cost: 0
        }
    ])

    const generateHourlyAnalysis = useCallback(async () => {
        const hourlyData = campaignData?.[SHEET_TABS.HOURLY_YEST]
        if (!hourlyData?.length) return 'No hourly data available'

        const prompt = `Analyze this hourly performance data from yesterday and identify any significant anomalies or patterns:
    ${JSON.stringify(hourlyData, null, 2)}`

        try {
            const { text } = await generateAIResponse(
                'gemini',
                MODEL,
                prompt,
                MAX_TOKENS
            )
            return text
        } catch (err) {
            throw new Error('Failed to analyze hourly data')
        }
    }, [campaignData])

    const generateChangeHistory = useCallback(async () => {
        const dailyData = campaignData?.[SHEET_TABS.DAILY]
        if (!dailyData?.length) return 'No daily data available'

        const prompt = `Review this daily performance data from yesterday and provide a clear summary of significant changes:
    ${JSON.stringify(dailyData, null, 2)}`

        try {
            const { text } = await generateAIResponse(
                'gemini',
                MODEL,
                prompt,
                MAX_TOKENS
            )
            return text
        } catch (err) {
            throw new Error('Failed to analyze daily data')
        }
    }, [campaignData])

    const generateBriefing = async () => {
        setLoading(true)

        // Update first section - Hourly Analysis
        setBriefings(prev => prev.map((section, idx) =>
            idx === 0 ? { ...section, loading: true, error: '', content: '', status: 'working', cost: 0 } : section
        ))
        try {
            const { text, usage } = await generateAIResponse(
                'gemini',
                MODEL,
                await generateHourlyAnalysis(),
                MAX_TOKENS
            )
            setBriefings(prev => prev.map((section, idx) =>
                idx === 0 ? {
                    ...section,
                    loading: false,
                    content: text,
                    status: 'done',
                    cost: usage.costs.total
                } : section
            ))
        } catch (err) {
            setBriefings(prev => prev.map((section, idx) =>
                idx === 0 ? {
                    ...section,
                    loading: false,
                    error: err instanceof Error ? err.message : 'Analysis failed',
                    status: 'not_requested'
                } : section
            ))
        }

        // Update second section - Change History
        setBriefings(prev => prev.map((section, idx) =>
            idx === 1 ? { ...section, loading: true, error: '', content: '', status: 'working', cost: 0 } : section
        ))
        try {
            const { text, usage } = await generateAIResponse(
                'gemini',
                MODEL,
                await generateChangeHistory(),
                MAX_TOKENS
            )
            setBriefings(prev => prev.map((section, idx) =>
                idx === 1 ? {
                    ...section,
                    loading: false,
                    content: text,
                    status: 'done',
                    cost: usage.costs.total
                } : section
            ))
        } catch (err) {
            setBriefings(prev => prev.map((section, idx) =>
                idx === 1 ? {
                    ...section,
                    loading: false,
                    error: err instanceof Error ? err.message : 'Analysis failed',
                    status: 'not_requested'
                } : section
            ))
        }

        setLoading(false)
    }

    const getStatusBadge = (section: BriefingSection) => {
        const baseClass = "ml-2 px-2 py-0.5 text-xs rounded-full"
        switch (section.status) {
            case 'not_requested':
                return <span className={`${baseClass} bg-gray-100 text-gray-600 dark:bg-gray-800 dark:text-gray-400`}>Not Started</span>
            case 'working':
                return <span className={`${baseClass} bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-300`}>Working</span>
            case 'done':
                return <span className={`${baseClass} bg-green-100 text-green-600 dark:bg-green-900 dark:text-green-300`}>Done</span>
        }
    }

    return (
        <div className="container mx-auto py-8">
            <Card className="mb-8">
                <CardHeader>
                    <CardTitle className="flex justify-between items-center">
                        <span>Daily Account Briefing</span>
                        <Button
                            onClick={generateBriefing}
                            disabled={loading}
                        >
                            {loading ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Generating Briefing...
                                </>
                            ) : (
                                'Get Daily Briefing'
                            )}
                        </Button>
                    </CardTitle>
                </CardHeader>
                <CardContent className="space-y-6">
                    <Accordion type="single" collapsible className="w-full">
                        {briefings.map((section, idx) => (
                            <AccordionItem key={idx} value={`item-${idx}`}>
                                <AccordionTrigger className="flex items-center gap-2">
                                    <div className="flex items-center flex-1">
                                        <span>{section.title}</span>
                                        {getStatusBadge(section)}
                                        {section.cost > 0 && (
                                            <span className="ml-2 text-xs text-muted-foreground">
                                                (${section.cost.toFixed(4)})
                                            </span>
                                        )}
                                    </div>
                                    {section.loading && (
                                        <Loader2 className="h-4 w-4 animate-spin ml-2" />
                                    )}
                                </AccordionTrigger>
                                <AccordionContent>
                                    {section.error ? (
                                        <Alert variant="destructive">
                                            <AlertTitle>Error</AlertTitle>
                                            <AlertDescription>{section.error}</AlertDescription>
                                        </Alert>
                                    ) : (
                                        <div className="prose prose-sm max-w-none dark:prose-invert">
                                            <ReactMarkdown>
                                                {section.content || 'Waiting for analysis...'}
                                            </ReactMarkdown>
                                        </div>
                                    )}
                                </AccordionContent>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </CardContent>
            </Card>
        </div>
    )
}
