// src/components/navigation/mobile-navbar.tsx
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Logo } from "@/components/logo";
import { cn } from "@/lib/utils";

export function MobileNavbar() {
    const location = useLocation();

    const mobileNav = [
        { href: "/build", title: "Build the Agent™" },
        { href: "/amsterdam", title: "Amsterdam Workshop" },
        { href: "/buy", title: "Buy Scripts" }
    ];

    return (
        <header className="sticky top-0 z-40 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <div className="container flex h-14 items-center">
                <div className="flex items-center space-x-4 w-full">
                    {/* Logo */}
                    <Link
                        to="/mobile"
                        className="mr-0 flex items-center space-x-2 transition-all duration-200 hover:opacity-80"
                    >
                        <Logo />
                        <span className="font-bold"> </span>
                    </Link>

                    {/* Navigation items */}
                    <nav className="flex items-center space-x-4 ml-auto text-sm font-medium">
                        {mobileNav.map((item) => (
                            <Link
                                key={item.title}
                                to={item.href}
                                className={cn(
                                    "transition-colors hover:text-foreground",
                                    location.pathname === item.href
                                        ? "text-foreground"
                                        : "text-foreground/60"
                                )}
                            >
                                {item.title}
                            </Link>
                        ))}
                    </nav>
                </div>
            </div>
        </header>
    );
} 