//src/components/ai-test/prompt-controls.tsx
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { PROMPT_TEMPLATES } from '@/lib/ai-prompts'
import { DataSource, RecordLimit, DATA_SOURCES } from '@/config/ai-models'
import { SHEET_TABS } from '@/lib/constants'
import { Pencil, RotateCcw } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Textarea } from '@/components/ui/textarea'
import { useState, useEffect } from 'react'
import { getSavedPrompt, savePrompt, resetPrompt } from '@/lib/storage-utils'
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '@/components/ui/tooltip'

interface PromptControlsProps {
    selectedPromptId: string | null
    setSelectedPromptId: (value: string | null) => void
    dataSource: DataSource
    setDataSource: (value: DataSource) => void
    recordLimit: RecordLimit
    setRecordLimit: (value: RecordLimit) => void
    setPrompt: (value: string) => void
    campaignData: any
}

const DATA_SOURCE_MAP: Record<string, DataSource> = {
    'productTitles': 'products',
    'landingPages': 'daily',
    'changeHistory': 'changes',
    'placement': 'placement',
    'productMatrix': 'products',
    'tNgrams': 'tNgrams',
    'sNgrams': 'sNgrams',
    'asset': 'assets'
}

export function PromptControls({
    selectedPromptId,
    setSelectedPromptId,
    dataSource,
    setDataSource,
    recordLimit,
    setRecordLimit,
    setPrompt,
    campaignData
}: PromptControlsProps) {
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [editingPrompt, setEditingPrompt] = useState('')
    const [hasCustomPrompt, setHasCustomPrompt] = useState(false)

    useEffect(() => {
        if (selectedPromptId && selectedPromptId !== 'custom') {
            const savedPrompt = getSavedPrompt(selectedPromptId)
            setHasCustomPrompt(!!savedPrompt)
        } else {
            setHasCustomPrompt(false)
        }
    }, [selectedPromptId])

    const handlePromptChange = (promptId: string) => {
        setSelectedPromptId(promptId)
        if (promptId === 'custom') {
            setPrompt('')
        } else {
            const savedPrompt = getSavedPrompt(promptId)
            const template = PROMPT_TEMPLATES.find(t => t.id === promptId)
            if (template) {
                setPrompt(savedPrompt || template.prompt)
                // Set corresponding data source
                const mappedSource = DATA_SOURCE_MAP[promptId]
                if (mappedSource) {
                    setDataSource(mappedSource)
                }
            }
        }
    }

    const handleEditClick = () => {
        if (!selectedPromptId || selectedPromptId === 'custom') return

        const template = PROMPT_TEMPLATES.find(t => t.id === selectedPromptId)
        if (!template) return

        const savedPrompt = getSavedPrompt(selectedPromptId)
        setEditingPrompt(savedPrompt || template.prompt)
        setShowEditDialog(true)
    }

    const handleSaveEdit = () => {
        if (!selectedPromptId || selectedPromptId === 'custom') return

        savePrompt(selectedPromptId, editingPrompt)
        setPrompt(editingPrompt)
        setShowEditDialog(false)
        setHasCustomPrompt(true)
    }

    const handleResetPrompt = () => {
        if (!selectedPromptId || selectedPromptId === 'custom') return

        const template = PROMPT_TEMPLATES.find(t => t.id === selectedPromptId)
        if (!template) return

        resetPrompt(selectedPromptId)
        setPrompt(template.prompt)
        setHasCustomPrompt(false)
    }

    // Get total record count for the selected source
    const getTotalRecords = () => {
        if (!campaignData || !dataSource) return 0
        return campaignData[SHEET_TABS[dataSource.toUpperCase()]]?.length || 0
    }

    return (
        <TooltipProvider>
            <div className="grid grid-cols-12 gap-4 items-end">
                <div className="col-span-6 space-y-2">
                    <div className="flex items-center justify-between">
                        <Label>Analysis Type</Label>
                        {selectedPromptId && selectedPromptId !== 'custom' && (
                            <div className="flex gap-2">
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={handleEditClick}
                                            className="h-6 w-6"
                                        >
                                            <Pencil className="h-4 w-4" />
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>Edit prompt template</p>
                                    </TooltipContent>
                                </Tooltip>
                                {hasCustomPrompt && (
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={handleResetPrompt}
                                                className="h-6 w-6"
                                            >
                                                <RotateCcw className="h-4 w-4" />
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            <p>Reset to default template</p>
                                        </TooltipContent>
                                    </Tooltip>
                                )}
                            </div>
                        )}
                    </div>
                    <Select
                        value={selectedPromptId || 'custom'}
                        onValueChange={handlePromptChange}
                    >
                        <SelectTrigger>
                            <SelectValue>
                                {selectedPromptId ?
                                    PROMPT_TEMPLATES.find(t => t.id === selectedPromptId)?.name :
                                    'Custom Prompt'}
                                {hasCustomPrompt && ' (Modified)'}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {PROMPT_TEMPLATES.map(template => (
                                <SelectItem
                                    key={template.id}
                                    value={template.id}
                                >
                                    <div>
                                        <div>
                                            {template.name}
                                            {getSavedPrompt(template.id) && ' (Modified)'}
                                        </div>
                                        <div className="text-xs text-muted-foreground">
                                            {template.description}
                                        </div>
                                    </div>
                                </SelectItem>
                            ))}
                            <SelectItem value="custom">Custom Prompt</SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                <div className="col-span-4 space-y-2">
                    <Label>Data Source</Label>
                    <Select
                        value={dataSource}
                        onValueChange={(value: DataSource) => setDataSource(value)}
                    >
                        <SelectTrigger>
                            <SelectValue>
                                <div className="flex justify-between items-center">
                                    <span>{DATA_SOURCES[dataSource].label}</span>
                                    <span className="text-xs text-muted-foreground">
                                        ({campaignData?.[dataSource]?.length || 0} records)
                                    </span>
                                </div>
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {Object.entries(DATA_SOURCES).map(([key, config]) => (
                                <Tooltip key={key}>
                                    <TooltipTrigger asChild>
                                        <SelectItem value={key}>
                                            <div>
                                                <div>{config.label}</div>
                                                <div className="text-xs text-muted-foreground flex justify-between">
                                                    <span>{config.description}</span>
                                                    {campaignData?.[key]?.length > 0 && (
                                                        <span className="ml-2">
                                                            ({campaignData[key]?.length} records)
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </SelectItem>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>{config.description}</p>
                                        <p className="text-xs text-muted-foreground mt-1">
                                            Sorted by: {config.defaultSort.column} ({config.defaultSort.direction})
                                        </p>
                                    </TooltipContent>
                                </Tooltip>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                <div className="col-span-2 space-y-2">
                    <div className="flex items-center justify-between">
                        <Label>Rows to Use</Label>
                        <span className="text-xs text-muted-foreground">
                            (max {getTotalRecords()})
                        </span>
                    </div>
                    <Select
                        value={String(recordLimit)}
                        onValueChange={(value) => setRecordLimit(value as RecordLimit)}
                    >
                        <SelectTrigger>
                            <SelectValue>{recordLimit}</SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="10">10</SelectItem>
                            <SelectItem value="50">50</SelectItem>
                            <SelectItem value="100">100</SelectItem>
                            <SelectItem value="250">250</SelectItem>
                            <SelectItem value="500">500</SelectItem>
                            <SelectItem value="1000">1000</SelectItem>
                            <SelectItem value="all">All</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </div>

            <Dialog open={showEditDialog} onOpenChange={setShowEditDialog}>
                <DialogContent className="max-w-[800px] w-[90vw]">
                    <DialogHeader>
                        <DialogTitle>Edit Prompt Template</DialogTitle>
                    </DialogHeader>
                    <Textarea
                        value={editingPrompt}
                        onChange={(e) => setEditingPrompt(e.target.value)}
                        className="min-h-[60vh] font-mono text-sm"
                    />
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setShowEditDialog(false)}>
                            Cancel
                        </Button>
                        <Button onClick={handleSaveEdit}>
                            Save Changes
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </TooltipProvider>
    )
} 