// src/main.tsx
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { ClerkProvider } from "@clerk/clerk-react"
import { ThemeProvider } from "@/components/theme-provider"
import { StickyFeedback } from "@/components/sticky-feedback"
import { router } from './Router'
import './index.css'
import { RouterProvider } from 'react-router-dom'
import { AccountProvider } from '@/contexts/account-context'
import { CampaignDataProvider } from '@/contexts/campaign-data'
import { db } from '@/services/db'

// Environment detection
const isDev = import.meta.env.MODE === 'development' ||
  window.location.hostname.includes('replit.dev');

// Get the correct key based on environment
const CLERK_KEY = isDev
  ? import.meta.env.VITE_CLERK_DEV_KEY
  : import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!CLERK_KEY) {
  throw new Error(`Missing Clerk Key for ${isDev ? 'development' : 'production'}`)
}

// Initialize app data
async function initializeAppData() {
  try {
    // Ensure sample account exists
    await db.ensureSampleAccount()
    
    // Load sample data in background
    const { loadSampleData } = await import('@/lib/sample-data-utils')
    loadSampleData().catch(console.error)
    
  } catch (err) {
    console.error('Failed to initialize app data:', err)
  }
}

function AppProviders({ children }: { children: React.ReactNode }) {
  // Initialize on mount
  useEffect(() => {
    initializeAppData()
  }, [])

  return (
    <ClerkProvider
      publishableKey={CLERK_KEY}
      appearance={{
        elements: {
          rootBox: "mx-auto w-full max-w-[440px] mt-[-200px]",
          card: "shadow-none",
          formButtonPrimary: "bg-primary hover:bg-primary/90",
          footerAction: "font-normal",
          formField: "gap-1",
          form: "gap-4",
          formFieldRow: "gap-3"
        },
        layout: {
          socialButtonsPlacement: "bottom",
          socialButtonsVariant: "iconButton",
          privacyPageUrl: "/privacy",
          termsPageUrl: "/terms"
        }
      }}
    >
      <ThemeProvider defaultTheme="light">
        <AccountProvider>
          <CampaignDataProvider>
            {children}
          </CampaignDataProvider>
        </AccountProvider>
      </ThemeProvider>
    </ClerkProvider>
  )
}

const root = document.getElementById('root')

if (!root) {
  throw new Error('Root element not found!')
}

const reactRoot = ReactDOM.createRoot(root)

try {
  reactRoot.render(
    <React.StrictMode>
      <AppProviders>
        <RouterProvider router={router} />
      </AppProviders>
    </React.StrictMode>
  )
} catch (err) {
  console.error('Main: Fatal error during render:', err)
}