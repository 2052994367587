// src/pages/settings.tsx
'use client'
// React and routing
import { useNavigate } from "react-router-dom"
import { useState, useEffect, useMemo, useCallback, memo } from "react"
import { useUser } from '@clerk/clerk-react'
import { Link } from "react-router-dom"

// Icons
import { Plus, Trash2, RefreshCw, Loader2, Pencil } from "lucide-react"

// Utils and constants
import { getTempWebAppUrlWithTimestamp, clearTempWebAppUrl } from '@/lib/storage-utils'
import { cn } from "@/lib/utils"
import { SHEET_TABS, CURRENCIES, SAMPLE_ACCOUNT_SCRIPT_VERSION } from '@/lib/constants'
import { debugDataLoading } from '@/lib/debug-data-loading'
import { version } from '../../package.json'
import { trackEvent, ANALYTICS_EVENTS } from '@/lib/analytics'

// Data and services
import { useAccounts } from "@/contexts/account-context"
import { useCampaignData } from "@/contexts/campaign-data"
import { Account, StorageData, BusinessMode } from "@/types/metrics"
import { db } from '@/services/db'
import { fetchAllData } from '@/lib/data-utils'

// UI Components - Core
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"
import { useToast } from "@/components/ui/use-toast"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion"

const DEBUG = process.env.NODE_ENV === 'development'

interface AccountCardProps {
    account: Account
    localInputs: Record<string, { cogsAmount?: number, breakEvenCpa?: number }>
    localBusinessMode: Record<string, 'ecomm' | 'leadgen'>
    localCurrency: Record<string, string>
    onInputChange: (dexieId: string, field: 'cogsAmount' | 'breakEvenCpa', value: string) => void
    onInputBlur: (account: Account, field: 'cogsAmount' | 'breakEvenCpa') => void
    onBusinessModeChange: (account: Account, checked: boolean) => void
    onCurrencyChange: (account: Account, value: string) => void
}

interface AccountsListProps {
    accounts: Account[]
    editingAccount: string | null
    newName: string
    accountData: Record<string, StorageData>
    refreshingAccounts: Record<string, boolean>
    localInputs: Record<string, { cogsAmount?: number, breakEvenCpa?: number }>
    localBusinessMode: Record<string, 'ecomm' | 'leadgen'>
    localCurrency: Record<string, string>
    setNewName: (name: string) => void
    setEditingAccount: (id: string | null) => void
    handleEditName: (dexieId: string, name: string) => void
    handleRefresh: (dexieId: string) => void
    getAccountCID: (dexieId: string) => string
    onInputChange: AccountCardProps['onInputChange']
    onInputBlur: AccountCardProps['onInputBlur']
    onBusinessModeChange: AccountCardProps['onBusinessModeChange']
    onCurrencyChange: AccountCardProps['onCurrencyChange']
    deleteAccount: (dexieId: string) => Promise<void>
}

const AccountCard = memo((props: AccountCardProps) => {
    const {
        account,
        localInputs,
        localBusinessMode,
        localCurrency,
        onInputChange,
        onInputBlur,
        onBusinessModeChange,
        onCurrencyChange
    } = props

    // If account is loading, show placeholder state
    if (account.isLoading) {
        return (
            <div className="grid grid-cols-3 items-center mb-2">
                <div className="flex items-center gap-2">
                    <div className="h-4 w-32 bg-muted animate-pulse rounded" />
                </div>
                <div className="flex items-center justify-center">
                    <div className="h-4 w-24 bg-muted animate-pulse rounded" />
                </div>
                <div className="flex items-center justify-end gap-2">
                    <div className="h-4 w-28 bg-muted animate-pulse rounded" />
                </div>
            </div>
        )
    }

    // Memoize handlers at this level to prevent re-renders
    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const field = localBusinessMode[account.dexieId] === 'ecomm' ? 'cogsAmount' : 'breakEvenCpa'
        onInputChange(account.dexieId, field, e.target.value)
    }, [account.dexieId, localBusinessMode, onInputChange])

    const handleInputBlur = useCallback(() => {
        const field = localBusinessMode[account.dexieId] === 'ecomm' ? 'cogsAmount' : 'breakEvenCpa'
        onInputBlur(account, field)
    }, [account, localBusinessMode, onInputBlur])

    const handleBusinessModeChange = useCallback((checked: boolean) => {
        onBusinessModeChange(account, checked)
    }, [account, onBusinessModeChange])

    const handleCurrencyChange = useCallback((value: string) => {
        onCurrencyChange(account, value)
    }, [account, onCurrencyChange])

    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.currentTarget.blur()
        }
    }, [])

    return (
        <div className="grid grid-cols-3 items-center mb-2">
            <div className="flex items-center gap-2">
                <Label className="whitespace-nowrap">Business Mode:</Label>
                <div className="flex items-center gap-2">
                    <span className="text-sm">Lead Gen</span>
                    <Switch
                        checked={localBusinessMode[account.dexieId] === 'ecomm'}
                        onCheckedChange={handleBusinessModeChange}
                    />
                    <span className="text-sm">E-commerce</span>
                </div>
            </div>

            <div className="flex items-center justify-center">
                <Label>Currency:</Label>
                <Select
                    value={localCurrency[account.dexieId]}
                    onValueChange={handleCurrencyChange}
                >
                    <SelectTrigger className="w-[120px] ml-2">
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                        {CURRENCIES.map((c) => (
                            <SelectItem key={c.symbol} value={c.symbol}>
                                {c.label} ({c.symbol})
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>

            <div className="flex items-center justify-end gap-2">
                <Label className="whitespace-nowrap">
                    {localBusinessMode[account.dexieId] === 'ecomm' ? 'COGS %:' : 'Break-even CPA:'}
                </Label>
                <Input
                    type="number"
                    className="w-[120px] text-center"
                    value={localInputs[account.dexieId]?.[localBusinessMode[account.dexieId] === 'ecomm' ? 'cogsAmount' : 'breakEvenCpa'] ?? ''}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDown}
                />
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    const dexieId = prevProps.account.dexieId
    // Only compare the values for this specific account
    return (
        dexieId === nextProps.account.dexieId &&
        prevProps.account.name === nextProps.account.name &&
        prevProps.localInputs[dexieId]?.cogsAmount === nextProps.localInputs[dexieId]?.cogsAmount &&
        prevProps.localInputs[dexieId]?.breakEvenCpa === nextProps.localInputs[dexieId]?.breakEvenCpa &&
        prevProps.localBusinessMode[dexieId] === nextProps.localBusinessMode[dexieId] &&
        prevProps.localCurrency[dexieId] === nextProps.localCurrency[dexieId] &&
        prevProps.onInputChange === nextProps.onInputChange &&
        prevProps.onInputBlur === nextProps.onInputBlur &&
        prevProps.onBusinessModeChange === nextProps.onBusinessModeChange &&
        prevProps.onCurrencyChange === nextProps.onCurrencyChange
    )
})

const AccountsList = memo((props: AccountsListProps) => {
    const {
        accounts,
        editingAccount,
        newName,
        accountData,
        refreshingAccounts,
        localInputs,
        localBusinessMode,
        localCurrency,
        setNewName,
        setEditingAccount,
        handleEditName,
        handleRefresh,
        getAccountCID,
        onInputChange,
        onInputBlur,
        onBusinessModeChange,
        onCurrencyChange,
        deleteAccount
    } = props

    return accounts.map(account => (
        <Card key={account.dexieId}>
            <CardHeader className="flex flex-row items-center justify-between">
                <div className="flex items-center gap-4">
                    <div>
                        <div className="flex items-center gap-2">
                            {account.isLoading ? (
                                <>
                                    <div className="h-6 w-48 bg-muted animate-pulse rounded" />
                                    <div className="h-4 w-32 bg-muted animate-pulse rounded ml-4" />
                                </>
                            ) : (
                                <>
                                    <CardTitle>{account.name}</CardTitle>
                                    <Dialog
                                        open={editingAccount === account.dexieId}
                                        onOpenChange={(open) => {
                                            if (open) {
                                                setNewName(account.name)
                                                setEditingAccount(account.dexieId)
                                            } else {
                                                setEditingAccount(null)
                                            }
                                        }}
                                    >
                                        <DialogTrigger asChild>
                                            <Button variant="ghost" size="icon" className="h-8 w-8">
                                                <Pencil className="h-4 w-4" />
                                            </Button>
                                        </DialogTrigger>
                                        <DialogContent>
                                            <DialogHeader>
                                                <DialogTitle>Edit Account Name</DialogTitle>
                                            </DialogHeader>
                                            <div className="space-y-4 py-4">
                                                <div className="space-y-2">
                                                    <Label htmlFor="name">Account Name</Label>
                                                    <Input
                                                        id="name"
                                                        value={newName}
                                                        onChange={(e) => setNewName(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' && newName.trim() && newName.trim() !== account.name) {
                                                                handleEditName(account.dexieId, newName)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <Button
                                                    onClick={() => handleEditName(account.dexieId, newName)}
                                                    disabled={!newName.trim() || newName.trim() === account.name}
                                                >
                                                    Save Changes
                                                </Button>
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                    <div className="flex items-center gap-2">
                                        {!account.isSampleAccount && (
                                            <span className="text-sm text-muted-foreground ml-4">
                                                CID: {getAccountCID(account.dexieId)}
                                            </span>
                                        )}
                                        <span className="text-sm text-muted-foreground ml-2">
                                            • Script v{account.isSampleAccount ? SAMPLE_ACCOUNT_SCRIPT_VERSION : (account.scriptVersion || '0.0.0')}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                        {!account.isLoading && accountData[account.dexieId] && (
                            <div className="text-sm text-muted-foreground mt-1">
                                Last updated: {new Date(accountData[account.dexieId].timestamp).toLocaleString()}
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex gap-2">
                    {!account.isLoading && (
                        <>
                            <Button
                                variant="outline"
                                size="sm"
                                onClick={() => handleRefresh(account.dexieId)}
                                className="gap-2"
                                disabled={refreshingAccounts[account.dexieId] || account.isSampleAccount}
                            >
                                {refreshingAccounts[account.dexieId] ? (
                                    <Loader2 className="h-4 w-4 animate-spin" />
                                ) : (
                                    <RefreshCw className="h-4 w-4" />
                                )}
                                Refresh
                            </Button>
                            {!account.isSampleAccount && (
                                <AlertDialog>
                                    <AlertDialogTrigger asChild>
                                        <Button variant="ghost" size="sm" className="gap-2">
                                            <Trash2 className="h-4 w-4" />
                                            Delete
                                        </Button>
                                    </AlertDialogTrigger>
                                    <AlertDialogContent>
                                        <AlertDialogHeader>
                                            <AlertDialogTitle>Delete Account</AlertDialogTitle>
                                            <AlertDialogDescription>
                                                Are you sure you want to delete this account? This action cannot be undone.
                                            </AlertDialogDescription>
                                        </AlertDialogHeader>
                                        <AlertDialogFooter>
                                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                                            <AlertDialogAction onClick={() => {
                                                trackEvent(ANALYTICS_EVENTS.SETTINGS.DELETE_ACCOUNT());
                                                deleteAccount(account.dexieId);
                                            }}>
                                                Delete
                                            </AlertDialogAction>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialog>
                            )}
                        </>
                    )}
                </div>
            </CardHeader>
            <CardContent>
                <AccountCard
                    account={account}
                    localInputs={localInputs}
                    localBusinessMode={localBusinessMode}
                    localCurrency={localCurrency}
                    onInputChange={onInputChange}
                    onInputBlur={onInputBlur}
                    onBusinessModeChange={onBusinessModeChange}
                    onCurrencyChange={onCurrencyChange}
                />
                {!account.isLoading && (
                    <Accordion type="single" collapsible className="mt-4">
                        <AccordionItem value="data-sources">
                            <AccordionTrigger>
                                <div className="flex items-center gap-2">
                                    <span>Data Sources</span>
                                    <span className="text-xs text-muted-foreground">
                                        {accountData[account.dexieId]?.timestamp
                                            ? `Last updated: ${new Date(accountData[account.dexieId].timestamp).toLocaleString()}`
                                            : account.isSampleAccount
                                                ? 'Sample data'
                                                : 'No data available'}
                                    </span>
                                </div>
                            </AccordionTrigger>
                            <AccordionContent>
                                <AccountDataStatus
                                    dexieId={account.dexieId}
                                    isRefreshing={refreshingAccounts[account.dexieId]}
                                />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                )}
            </CardContent>
        </Card>
    ))
}, (prevProps, nextProps) => {
    // Compare only what's needed for rendering
    const accountsEqual = prevProps.accounts.length === nextProps.accounts.length &&
        prevProps.accounts.every((account, i) => {
            const nextAccount = nextProps.accounts[i]
            // Only compare fields that affect visual rendering
            return account.dexieId === nextAccount.dexieId &&
                account.name === nextAccount.name &&
                account.isSampleAccount === nextAccount.isSampleAccount &&
                account.webAppUrl === nextAccount.webAppUrl
        })

    // Compare local state objects by value
    const inputsEqual = Object.keys(prevProps.localInputs).length === Object.keys(nextProps.localInputs).length &&
        Object.keys(prevProps.localInputs).every(key => {
            const prev = prevProps.localInputs[key]
            const next = nextProps.localInputs[key]
            return prev?.cogsAmount === next?.cogsAmount &&
                prev?.breakEvenCpa === next?.breakEvenCpa
        })

    // Compare mode and currency by value
    const modesEqual = Object.keys(prevProps.localBusinessMode).length === Object.keys(nextProps.localBusinessMode).length &&
        Object.keys(prevProps.localBusinessMode).every(key =>
            prevProps.localBusinessMode[key] === nextProps.localBusinessMode[key]
        )

    const currencyEqual = Object.keys(prevProps.localCurrency).length === Object.keys(nextProps.localCurrency).length &&
        Object.keys(prevProps.localCurrency).every(key =>
            prevProps.localCurrency[key] === nextProps.localCurrency[key]
        )

    // Compare refreshing state by value
    const refreshEqual = Object.keys(prevProps.refreshingAccounts).length === Object.keys(nextProps.refreshingAccounts).length &&
        Object.keys(prevProps.refreshingAccounts).every(key =>
            prevProps.refreshingAccounts[key] === nextProps.refreshingAccounts[key]
        )

    // Compare simple props by reference (functions and primitives)
    const simplePropsEqual =
        prevProps.editingAccount === nextProps.editingAccount &&
        prevProps.newName === nextProps.newName &&
        prevProps.onInputChange === nextProps.onInputChange &&
        prevProps.onInputBlur === nextProps.onInputBlur &&
        prevProps.onBusinessModeChange === nextProps.onBusinessModeChange &&
        prevProps.onCurrencyChange === nextProps.onCurrencyChange

    return accountsEqual &&
        inputsEqual &&
        modesEqual &&
        currencyEqual &&
        refreshEqual &&
        simplePropsEqual
})

const AccountDataStatus = memo(({ dexieId, isRefreshing }: { dexieId: string, isRefreshing: boolean }) => {
    const { accountData } = useCampaignData()
    const data = accountData[dexieId]

    if (!data) {
        return (
            <div className="text-center py-4 text-muted-foreground">
                No data available. Click the refresh button above to fetch data.
            </div>
        )
    }

    // Group data sources into 3 columns
    const sources = Object.entries(SHEET_TABS)
    const columnSize = Math.ceil(sources.length / 3)
    const columns = [
        sources.slice(0, columnSize),
        sources.slice(columnSize, columnSize * 2),
        sources.slice(columnSize * 2)
    ]

    return (
        <div>
            <div className="grid grid-cols-3 gap-8 mt-4">
                {columns.map((column, colIndex) => (
                    <div key={colIndex} className="space-y-4">
                        {column.map(([key, value]) => (
                            <div key={key} className="flex justify-between items-center">
                                <span className="text-sm font-medium">{value}</span>
                                <span className="text-sm tabular-nums">{data?.[value]?.length || 0} records</span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
})

export default function Settings() {
    const navigate = useNavigate()
    const { accounts, deleteAccount, updateAccount, addAccount, debugSyncStatus } = useAccounts()
    const { refreshData, data, accountData, loadingStatus } = useCampaignData()
    const { toast } = useToast()
    const { isSignedIn, user } = useUser()

    const [editingAccount, setEditingAccount] = useState<string | null>(null)
    const [newName, setNewName] = useState("")
    const [localInputs, setLocalInputs] = useState<Record<string, { cogsAmount?: number, breakEvenCpa?: number }>>({})
    const [localBusinessMode, setLocalBusinessMode] = useState<Record<string, 'ecomm' | 'leadgen'>>({})
    const [localCurrency, setLocalCurrency] = useState<Record<string, string>>({})
    const [refreshingAccounts, setRefreshingAccounts] = useState<Record<string, boolean>>({})
    const [loadingDialog, setLoadingDialog] = useState<{
        title: string
        description: string
        open: boolean
    } | null>(null)

    // Memoize initial state to prevent unnecessary recalculations
    const initialState = useMemo(() => ({
        inputs: accounts.reduce((acc, account) => ({
            ...acc,
            [account.dexieId]: {
                cogsAmount: account.cogsAmount,
                breakEvenCpa: account.breakEvenCpa
            }
        }), {}),
        businessModes: accounts.reduce((acc, account) => ({
            ...acc,
            [account.dexieId]: account.businessMode
        }), {}),
        currencies: accounts.reduce((acc, account) => ({
            ...acc,
            [account.dexieId]: account.currency
        }), {})
    }), [accounts])

    // Update local state only when accounts change
    useEffect(() => {
        setLocalInputs(prev => {
            const next = { ...initialState.inputs }
            // Preserve any in-progress edits
            Object.keys(prev).forEach(id => {
                if (next[id] && prev[id]) {
                    next[id] = { ...next[id], ...prev[id] }
                }
            })
            return next
        })
        setLocalBusinessMode(prev => {
            const next = { ...initialState.businessModes }
            // Preserve any in-progress edits
            Object.keys(prev).forEach(id => {
                if (next[id] && prev[id]) {
                    next[id] = prev[id]
                }
            })
            return next
        })
        setLocalCurrency(prev => {
            const next = { ...initialState.currencies }
            // Preserve any in-progress edits
            Object.keys(prev).forEach(id => {
                if (next[id] && prev[id]) {
                    next[id] = prev[id]
                }
            })
            return next
        })
    }, [initialState])

    // Memoize handlers
    const handleInputChange = useCallback((dexieId: string, field: 'cogsAmount' | 'breakEvenCpa', value: string) => {
        const numValue = Number(value)
        if (isNaN(numValue)) return

        setLocalInputs(prev => ({
            ...prev,
            [dexieId]: {
                ...prev[dexieId],
                [field]: numValue
            }
        }))
    }, [])

    const getAccountCID = useCallback((dexieId: string): string => {
        const account = accounts.find(a => a.dexieId === dexieId)
        return account?.accountCID || 'Not available'
    }, [accounts])

    const handleRefresh = useCallback(async (dexieId: string) => {
        try {
            const account = accounts.find(a => a.dexieId === dexieId)
            if (!account) return

            if (account.isSampleAccount) {
                toast({ title: "Sample Account", description: "Sample data doesn't need to be refreshed" })
                return
            }

            if (!account.webAppUrl) {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: "No Web App URL found for this account"
                })
                return
            }

            setRefreshingAccounts(prev => ({ ...prev, [dexieId]: true }))
            trackEvent(ANALYTICS_EVENTS.SETTINGS.REFRESH_DATA());
            await refreshData(dexieId)
            toast({ title: "Data Refreshed", description: "Account data has been updated" })
        } catch (err) {
            console.error('Refresh error:', err)
            toast({ variant: "destructive", title: "Error", description: "Failed to refresh data" })
        } finally {
            setRefreshingAccounts(prev => ({ ...prev, [dexieId]: false }))
        }
    }, [accounts, refreshData, toast])

    const handleEditName = useCallback(async (dexieId: string, name: string) => {
        const account = accounts.find(a => a.dexieId === dexieId)
        if (!account) return

        const previousName = account.name
        const trimmedName = name.trim()

        // Optimistically update UI by updating accounts context
        try {
            trackEvent(ANALYTICS_EVENTS.SETTINGS.ACCOUNT_EDIT('rename'));
            await updateAccount({ ...account, name: trimmedName })
            toast({ title: "Account Updated", description: "Account name has been updated" })
            setEditingAccount(null)
        } catch (err) {
            // On error, the accounts context will automatically revert
            toast({ variant: "destructive", title: "Error", description: "Failed to update account name" })
        }
    }, [accounts, updateAccount, toast])

    const handleInputBlur = useCallback(async (account: Account, field: 'cogsAmount' | 'breakEvenCpa') => {
        const localValue = localInputs[account.dexieId]?.[field]
        if (localValue === undefined || localValue === account[field]) return

        try {
            // 1. Update local account first (optimistic)
            const updatedAccount = {
                ...account,
                [field]: localValue
            }
            await updateAccount(updatedAccount)

            // 2. Silently update Clerk metadata in the background
            // No need to wait for this or handle errors - local state is source of truth
            if (user) {
                const updatedAccounts = accounts.map(a =>
                    a.dexieId === account.dexieId ? updatedAccount : a
                )
                user.update({
                    unsafeMetadata: {
                        ...user.unsafeMetadata,
                        accounts: updatedAccounts
                    }
                }).catch(err => {
                    console.error('Failed to sync with Clerk:', err)
                    // Silently handle error - local state is still good
                })
            }
        } catch (err) {
            // Only revert local state if the local update failed
            setLocalInputs(prev => ({
                ...prev,
                [account.dexieId]: {
                    ...prev[account.dexieId],
                    [field]: account[field]
                }
            }))
            console.error('Failed to update account:', err)
            toast({
                variant: "destructive",
                title: "Update Failed",
                description: "Failed to save changes"
            })
        }
    }, [localInputs, updateAccount, accounts, user, toast])

    const handleBusinessModeChange = useCallback(async (account: Account, checked: boolean) => {
        const newMode: BusinessMode = checked ? 'ecomm' : 'leadgen'
        if (newMode === account.businessMode) return

        // Update local state first (optimistic)
        setLocalBusinessMode(prev => ({
            ...prev,
            [account.dexieId]: newMode
        }))

        try {
            trackEvent(ANALYTICS_EVENTS.SETTINGS.BUSINESS_MODE(newMode));
            // 1. Update local account
            const updatedAccount = {
                ...account,
                businessMode: newMode
            }
            await updateAccount(updatedAccount)

            // 2. Silently update Clerk metadata in the background
            if (user) {
                user.update({
                    unsafeMetadata: {
                        ...user.unsafeMetadata,
                        accounts: accounts.map(a =>
                            a.dexieId === account.dexieId
                                ? updatedAccount
                                : a
                        )
                    }
                }).catch(err => {
                    console.error('Failed to sync with Clerk:', err)
                    // Silently handle error - local state is still good
                })
            }
        } catch (err) {
            // Only revert local state if the local update failed
            setLocalBusinessMode(prev => ({
                ...prev,
                [account.dexieId]: account.businessMode
            }))
            console.error('Failed to update account:', err)
            toast({
                variant: "destructive",
                title: "Update Failed",
                description: "Failed to save changes"
            })
        }
    }, [updateAccount, accounts, user, toast])

    const handleCurrencyChange = useCallback(async (account: Account, value: string) => {
        if (value === account.currency) return

        // Update local state first (optimistic)
        setLocalCurrency(prev => ({
            ...prev,
            [account.dexieId]: value
        }))

        try {
            trackEvent(ANALYTICS_EVENTS.SETTINGS.CURRENCY_CHANGE(value));
            // 1. Update local account with optimistic flag
            const updatedAccount = {
                ...account,
                currency: value
            }
            await updateAccount(updatedAccount, true)

            // 2. Silently update Clerk metadata in the background
            // No need to wait for this or handle errors - local state is source of truth
            if (user) {
                const updatedAccounts = accounts.map(a =>
                    a.dexieId === account.dexieId ? updatedAccount : a
                )
                user.update({
                    unsafeMetadata: {
                        ...user.unsafeMetadata,
                        accounts: updatedAccounts
                    }
                }).catch(err => {
                    console.error('Failed to sync with Clerk:', err)
                    // Silently handle error - local state is still good
                })
            }
        } catch (err) {
            // Only revert local state if the local update failed
            setLocalCurrency(prev => ({
                ...prev,
                [account.dexieId]: account.currency
            }))
            console.error('Failed to update account currency:', err)
            toast({
                variant: "destructive",
                title: "Update Failed",
                description: "Failed to save changes"
            })
        }
    }, [updateAccount, accounts, user, toast])

    // Memoize the debug card
    const debugCard = useMemo(() => DEBUG ? (
        <Card className="mt-8">
            <CardHeader>
                <CardTitle>Debug Info</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="space-y-4">
                    <div className="flex gap-2">
                        <Button
                            variant="outline"
                            onClick={async () => {
                                if (user) {
                                    try {
                                        await user.update({
                                            unsafeMetadata: {
                                                ...user.unsafeMetadata,
                                                highScore: 0
                                            }
                                        });
                                        toast({
                                            title: "High Score Reset",
                                            description: "High score has been reset to 0"
                                        });
                                    } catch (err) {
                                        console.error('Failed to reset high score:', err);
                                        toast({
                                            variant: "destructive",
                                            title: "Error",
                                            description: "Failed to reset high score"
                                        });
                                    }
                                }
                            }}
                            className="w-full"
                        >
                            Reset High Score
                        </Button>
                    </div>
                    <pre className="text-xs">
                        {JSON.stringify(
                            {
                                clerk: {
                                    userId: user?.id,
                                    email: user?.primaryEmailAddress?.emailAddress,
                                    unsafeMetadata: user?.unsafeMetadata
                                },
                                loadingStatus,
                            },
                            null,
                            2
                        )}
                    </pre>
                </div>
            </CardContent>
        </Card>
    ) : null, [accounts, loadingStatus, user, debugSyncStatus, toast])

    // Memoize the accounts list to prevent unnecessary re-renders
    const memoizedAccounts = useMemo(() => accounts, [accounts])

    useEffect(() => {
        // Only check for stored URL if user is signed in
        if (!isSignedIn) return

        const storedData = getTempWebAppUrlWithTimestamp()
        if (!storedData) return

        // Only use URLs stored in the last 5 minutes
        const fiveMinutesAgo = new Date(Date.now() - 5 * 60 * 1000)
        const storedTime = new Date(storedData.timestamp)

        if (storedTime > fiveMinutesAgo) {
            // URL is fresh enough, try to fetch data
            const setupAccount = async () => {
                try {
                    // 1. Fetch account info
                    const response = await fetch(`${storedData.url}?tab=account`)
                    if (!response.ok) throw new Error(`Failed to fetch account info: ${response.statusText}`)

                    const accountData = await response.json()
                    if (!Array.isArray(accountData) || !accountData[0]) {
                        throw new Error('Invalid account data format')
                    }

                    const accountInfo = accountData[0]
                    if (!accountInfo.accountCID || !accountInfo.accountName) {
                        throw new Error('Invalid account data - missing required fields')
                    }

                    const newAccount: Account = {
                        id: accountInfo.accountCID,
                        dexieId: accountInfo.accountCID,
                        accountCID: accountInfo.accountCID,
                        name: accountInfo.accountName,
                        webAppUrl: storedData.url,
                        businessMode: 'ecomm',
                        currency: '$',
                        cogsAmount: 50,
                        breakEvenCpa: 25,
                        isSampleAccount: false,
                        scriptVersion: accountInfo.scriptVersion || '0.0.0'
                    }

                    // 2. Add account
                    await addAccount(newAccount)

                    // 3. Trigger refresh immediately
                    await handleRefresh(accountInfo.accountCID)

                    toast({
                        title: 'Success',
                        description: `${newAccount.name} has been connected successfully`
                    })
                } catch (err) {
                    console.error('Setup failed:', err)
                    toast({
                        variant: 'destructive',
                        title: 'Setup Failed',
                        description: err instanceof Error ? err.message : 'Failed to set up account'
                    })
                } finally {
                    clearTempWebAppUrl()
                }
            }

            setupAccount()
        } else {
            // URL is too old, just clear it
            clearTempWebAppUrl()
        }
    }, [isSignedIn, addAccount, handleRefresh, toast])

    // Early return for loading state
    if (loadingStatus === 'initial' || loadingStatus === 'refresh') {
        return (
            <div className="container mx-auto py-6">
                <div className="flex flex-col items-center justify-center h-96 space-y-4">
                    <Loader2 className="h-8 w-8 animate-spin" />
                    <div className="text-lg">
                        {loadingStatus === 'initial'
                            ? "Connecting your Google Ads account..."
                            : "Refreshing your data..."}
                    </div>
                    <p className="text-sm text-muted-foreground">
                        This may take a minute or two
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className="container mx-auto py-6 space-y-6">
            <div className="flex items-center justify-between">
                <div className="space-y-1">
                    <h1 className="text-2xl font-semibold">Accounts</h1>
                    <p className="text-sm text-muted-foreground">
                        Manage your Google Ads accounts
                        {accounts.length >= 5 && (
                            <span className="ml-1 text-yellow-600">
                                (Maximum 5 accounts allowed)
                            </span>
                        )}
                    </p>
                </div>
                <Button
                    onClick={() => navigate('/setup')}
                    className="gap-2"
                    disabled={accounts.length >= 5}
                >
                    <Plus className="h-4 w-4" />
                    Add Account
                </Button>
            </div>

            {accounts.length >= 5 && (
                <div className="bg-muted rounded-lg p-4 text-sm text-muted-foreground">
                    You've reached the maximum limit of 5 accounts. To add a new account, please delete an existing one first.
                </div>
            )}

            <AccountsList
                accounts={memoizedAccounts}
                editingAccount={editingAccount}
                newName={newName}
                accountData={accountData}
                refreshingAccounts={refreshingAccounts}
                localInputs={localInputs}
                localBusinessMode={localBusinessMode}
                localCurrency={localCurrency}
                setNewName={setNewName}
                setEditingAccount={setEditingAccount}
                handleEditName={handleEditName}
                handleRefresh={handleRefresh}
                getAccountCID={getAccountCID}
                onInputChange={handleInputChange}
                onInputBlur={handleInputBlur}
                onBusinessModeChange={handleBusinessModeChange}
                onCurrencyChange={handleCurrencyChange}
                deleteAccount={deleteAccount}
            />

            {debugCard}

            {/* Loading Dialog */}
            <Dialog open={loadingDialog?.open} onOpenChange={() => { }}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>{loadingDialog?.title}</DialogTitle>
                    </DialogHeader>
                    <div className="flex items-center justify-center gap-4 py-8">
                        <Loader2 className="h-6 w-6 animate-spin" />
                        <p>{loadingDialog?.description}</p>
                    </div>
                </DialogContent>
            </Dialog>

            {/* Version number and links */}
            <div className="text-[10px] text-muted-foreground text-center pt-8 flex items-center justify-center gap-3">
                <span>v{version}</span>
                <span>•</span>
                <Link to="/privacy" className="hover:underline">Privacy</Link>
                <span>•</span>
                <Link to="/terms" className="hover:underline">Terms</Link>
            </div>
        </div>
    )
}
