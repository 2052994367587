// Environment variables and configuration
export const OPENAI_API_KEY = import.meta.env.VITE_OPENAI_API_KEY || '';
export const OPENAI_VISION_MODEL = import.meta.env.VITE_OPENAI_VISION_MODEL || 'gpt-4-vision-preview';
export const OPENAI_TEXT_MODEL = import.meta.env.VITE_OPENAI_TEXT_MODEL || 'gpt-4o';
export const DEBUG = import.meta.env.DEV;

// OpenAI API configuration
export const openaiConfig = {
  apiKey: OPENAI_API_KEY,
  models: {
    vision: OPENAI_VISION_MODEL,
    text: OPENAI_TEXT_MODEL
  },
  maxTokens: 1000,
  temperature: 0.7,
  // Cost tracking (in USD)
  costs: {
    vision: {
      inputTokenRate: 2.50 / 1000000,  // $2.50 per 1M tokens
      outputTokenRate: 10.00 / 1000000, // $10.00 per 1M tokens
      imageAnalysis: 0.002,          // Cost per image analysis
    },
    text: {
      inputTokenRate: 0.03 / 1000,   // $0.03 per 1K tokens
      outputTokenRate: 0.06 / 1000,  // $0.06 per 1K tokens
    }
  }
};

// Image processing configuration
export const imageConfig = {
  maxDimension: 1500,  // Maximum dimension for image resizing
  quality: 0.8,        // JPEG quality for resized images
  format: 'image/jpeg' // Output format
}; 