// src/components/ai-test/model-controls.tsx
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Provider } from '@/types/ai'
import { MODELS } from '@/config/ai-models'

interface ModelControlsProps {
    provider: Provider
    setProvider: (value: Provider) => void
    modelId: string
    setModelId: (value: string) => void
    maxTokens: number
    setMaxTokens: (value: number) => void
}

export function ModelControls({
    provider,
    setProvider,
    modelId,
    setModelId,
    maxTokens,
    setMaxTokens
}: ModelControlsProps) {
    const selectedModel = MODELS[provider].find(m => m.id === modelId)

    return (
        <div className="space-y-4">
            <div className="grid gap-4 grid-cols-1 md:grid-cols-3 items-end">
                <div className="space-y-2">
                    <Label>Provider</Label>
                    <Select value={provider} onValueChange={(value: Provider) => {
                        setProvider(value)
                        setModelId(MODELS[value][0].id)
                    }}>
                        <SelectTrigger>
                            <SelectValue>
                                {provider.charAt(0).toUpperCase() + provider.slice(1)} ({MODELS[provider][0].name})
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="gemini">Gemini ({MODELS.gemini[0].name})</SelectItem>
                            <SelectItem value="openai">OpenAI ({MODELS.openai[0].name})</SelectItem>
                            <SelectItem value="anthropic">Anthropic ({MODELS.anthropic[0].name})</SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                <div className="space-y-2">
                    <Label>Model</Label>
                    <Select value={modelId} onValueChange={setModelId}>
                        <SelectTrigger>
                            <SelectValue>{MODELS[provider].find(m => m.id === modelId)?.name}</SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {MODELS[provider].map(model => (
                                <SelectItem key={model.id} value={model.id}>
                                    {model.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                <div className="space-y-2">
                    <Label>Max Output Tokens</Label>
                    <Input
                        type="number"
                        value={maxTokens}
                        onChange={(e) => setMaxTokens(Number(e.target.value))}
                        min={1}
                        max={8192}
                    />
                </div>
            </div>

            {selectedModel && (
                <div className="text-sm text-muted-foreground">
                    Pricing: ${selectedModel.inputPrice}/1M tokens input,
                    ${selectedModel.outputPrice}/1M tokens output
                </div>
            )}
        </div>
    )
} 